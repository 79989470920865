@charset "utf-8";

/* COULEURS DE THEME */


@mixin useFont($name: roboto, $weight: normal, $style: normal) {
	font: {
		family: #{map-get($fonts-families, $name)};
		weight: $weight;
		style: $style;	
	}
}
@charset "utf-8";
.conteneur-vbel-zone-aide {
    margin: 0;
    position: relative;
    &.noImageFondMobile {
        @include media-breakpoint-down(sm) {
            background-size: 0;
        }
    }
    &.noImageFondMobileCover {
        background-size: 0;
        @include media-breakpoint-up(md) {
            background-size: cover;
        }
    }
    &.radius {
        border-radius: (20rem / 16);
        @include media-breakpoint-up(md) {
            border-radius: (40rem / 16);
        }
    }
    &.noMobileRadius {
        @include media-breakpoint-down(sm) {
            border-radius: 0;
        }
    }
    //Si le côté droit est arrondi
    &.radius-right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        @include media-breakpoint-up(md) {
            padding-left: (80rem / 16);
            padding-right: (40rem /16);
            margin-right: (40rem / 16);
        }
        @include media-breakpoint-up(lg) {
            padding-left: (235rem / 16);
            padding-right: (139rem /16);
            margin-right: (96rem / 16);
        }
    }
    //Si le côté gauche est arrondi
    &.radius-left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @include media-breakpoint-up(md) {
            padding-right: (80rem / 16);
            padding-left: (40rem /16);
            margin-left: (40rem / 16);
        }
        @include media-breakpoint-up(lg) {
            padding-right: (235rem / 16);
            padding-left: (139rem /16);
            margin-left: (96rem / 16);
        }
    }
    &.bg-cover {
        background-size: cover;
    }
    .media-text .image-title {
        display: none;
        /* pour pallier un bug de media-texte*/
    }
    /*gestion spécifique pour rangée incluse dans conteneur-arrondis */
    .rangee {
        padding-bottom: 1em;
        padding-left: 0;
        padding-right: 0;
    }
	.media-text .subtitle {
        text-align: center;
    }
}
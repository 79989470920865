.conteneur-onglets {
    .nav {
        border-bottom: 2px solid #000;
        flex-wrap: nowrap;
        .nav-item {
            font: normal normal medium Gordita;
            color: #141B4D;
            font-size: (12rem / 16);
            border: none;
            padding-bottom: 0;
            margin-bottom: unset;
            @include media-breakpoint-up(md) {
                font-size: (18rem / 16);
                margin-bottom: unset;
            }
            @include media-breakpoint-up(lg) {
                font-size: (30rem / 16);
            }
            &:nth-child(1).active::after {
                content: "";
                display: block;
                /* This will put the pseudo element on its own line. */
                margin: 0 auto;
                /* This will center the border. */
                width: (100rem /16);
                /* Change this to whatever width you want. */
                padding-top: (5rem / 16);
                /* This creates some space between the element and the border. */
                border-bottom: 4px solid #000;
                margin-bottom: -1px;
                @include media-breakpoint-up(xs) {
                    border-bottom: 5px solid #000;
                    width: (120rem /16);
                    margin-bottom: -1px;
                    padding-top: (15rem / 16);
                }
                @include media-breakpoint-up(sm) {
                    width: (130rem /16);
                }
                @include media-breakpoint-up(md) {
                    width: (190rem /16);
                }
                @include media-breakpoint-up(lg) {
                    border-bottom: 10px solid #000;
                    width: (300rem /16);
                    margin-bottom: unset;
                }
                @include media-breakpoint-up(xl) {
                    width: (300rem /16);
                }
            }
            &:nth-child(2).active::after {
                content: "";
                display: block;
                /* This will put the pseudo element on its own line. */
                margin: 0 auto;
                /* This will center the border. */
                width: (130rem /16);
                /* Change this to whatever width you want. */
                padding-top: (5rem / 16);
                /* This creates some space between the element and the border. */
                border-bottom: 4px solid #000;
                margin-bottom: -1px;
                @include media-breakpoint-up(xs) {
                    border-bottom: 5px solid #000;
                    width: (160rem /16);
                    margin-bottom: -1px;
                    padding-top: (14rem / 16);
                }
                @include media-breakpoint-up(sm) {
                    width: (230rem /16);
                }
                @include media-breakpoint-up(md) {
                    width: (340rem /16);
                }
                @include media-breakpoint-up(lg) {
                    border-bottom: 10px solid #000;
                    width: (540rem /16);
                    margin-bottom: unset;
                }
                @include media-breakpoint-up(xl) {
                    width: (570rem /16);
                }
            }
        }
        &.nav-tabs {
            .nav-link.active,
            .nav-item.show .nav-link {
                background: transparent;
            }
        }
    }
    #menu-ongle-btn {
        display: block;
        @include media-breakpoint-up(sm) {
            display: none;
        }
    }
    /*
    .tab-pane:before {
        display: block;
        content: "Promotions";
        margin-top: (-40rem / 16); // Set the Appropriate Height
        height: (60rem / 16); // Set the Appropriate Height
        @include media-breakpoint-up(md) {
            display: none;
            visibility: hidden;
        }
    }
    */
    #btn-pluspromo,
    #btn-plusgagnants {
        a {
            background-color: #3a5dae;
            color: #ffffff;
        }
        a:hover {
            background-color: #ffffff;
            color: #000;
        }
    }
}


.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.quebec-49 .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit {
    left: 0
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.quebec-49 .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
    left: 1%;
    top: 3%;
    height: 95%;
}

.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral.quebec-49 .numeros {
    margin-bottom: 3.4453rem;
    margin-top: 4.3053125rem;
}
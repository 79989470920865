#lqCatfish,
#lqCatfishMobile {
    display: none;
    bottom: -1px;
    left: 0;
    margin: 0;
    position: fixed;
    border-top: 1px dotted #000;
    width: 100%;
    z-index: 90;
    overflow: hidden;
    box-shadow: 0px -2px 5px 0px rgb(255 255 255 / 39%);

    #catfishUrl,
    #catfishMobileUrl {
        display: block;
    }

    #catfishClose,
    #catfishMobileClose {
        background: white;
        color: black;
        cursor: pointer;
        border: 1px solid #000;
        border-top: none;
        display: block;
        font-size: 18px;
        line-height: 1.2;
        padding: 0 5px 2px;
        position: absolute;
        right: 0;
        top: 0;
    }
}

#catfishImage {
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 1200px;
}

#catfishMobileImage {
    margin: 0 auto;
    display: block;
    width: 100%;
    min-width: 320px;
    max-width: 768px;
}

#lqCatfish.noCookie {
    display: none;
    @include media-breakpoint-up(sm) {
        display: block;
    }
}

#lqCatfishMobile.noCookie {
    @include media-breakpoint-up(sm) {
        display: none;
    }
}

#lqCatfishMobile.noCookie {
    display: none;
    @include media-breakpoint-down(xs) {
        display: block;
    }
}
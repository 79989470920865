.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-max .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
    height: 105%;
    left: 10%;
    top: 0;
}

.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral.lotto-max>.numeros {
    margin: 3.875rem auto;
}

.resultats-recents.resultat-sommaires {
    .produit-223.contenantResultat {
        .avis-groslots a .tirage div.groslot-approx,
        .avis-groslots a:hover .tirage div.groslot-approx {
            display: flex;
            font-size: (11rem / 16);
            padding-top: 1rem;
            @include media-breakpoint-only(xxs) {
                margin-bottom: (10rem / 16);
            }
            @include media-breakpoint-only(xs) {
                font-size: (10rem / 16);
            }
            @include media-breakpoint-up(md) {
                font-size: (14rem / 16);
                margin-right: 80px;
                padding-top: 0.5rem;
            }
            @include media-breakpoint-only(lg) {
                margin-right: 15px;
                padding-right: 0;
            }
        }
    }
}
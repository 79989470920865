@charset "utf-8";

.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.gagnant-a-vie {
                a.lienResultatsComplets {
                    margin : auto;
                    padding: 0;

                    @include media-breakpoint-between(all, xs) {
                        margin-top   : (30rem / 16);
                        margin-bottom: (10rem / 16);
                    }
                }
            }
        }
    }
}
.conteneur-video {

    .row {
        padding: 1rem (35rem /16);
    }

    &.radius{
        .row {
            @include media-breakpoint-up(md) {
                padding: 1rem 0;
            }
        }
        
        .media-video {
            .content-inner {
                &.format9by16 {
    
    
                    @include media-breakpoint-up(md) {
                        margin-top: -3rem;
                    }
    
    
                    @include media-breakpoint-up(xl) {
                        margin-top: -8rem;
                        margin-bottom: -6rem;
                    }
                }
            }
        } 

    }

    &.background-full {
        padding:2vw 2vw;
        @include media-breakpoint-up(md) {
            padding:2vw 1vw;
        }

        .text-right, .text-left {
            .col-md-4{
                @include media-breakpoint-up(md) {
                    margin-top: (80rem / 16);
                } 
            }              
        }
    }
    
    

    &.bg-cover {
        background-size: cover;
    }

    &.background-bordershadow {

        @include media-breakpoint-down(sm) {
            box-shadow:none !important;
        }
            
        @include media-breakpoint-up(md) {
            border-radius: (40rem / 16);
            /*background-color: white;*/
            box-shadow: 0 0 (15.7rem / 16) (2.3rem / 16) rgba(20, 27, 77, 0.4);
            padding-top: (80rem / 16);
            padding-bottom:(80rem / 16);
        }
        
    }

    //Si le côté droit est arrondi
    &.radius-right {
        @include media-breakpoint-up(md) {
            margin-right: (48rem / 16);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding-right:11vw;
        }
        @include media-breakpoint-up(xl) {
            margin-right: (107rem / 16);
            padding-right: (177rem / 16); /*192 -15*/
            padding-left: (157rem / 16); /*172 -15*/
        }
    }
    //Si le côté gauche est arrondi
    &.radius-left {
        @include media-breakpoint-up(md) {
            margin-left: (48rem / 16);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-left:11vw;
        }
        @include media-breakpoint-up(xl) {
            margin-left: (123rem / 16);
            padding-left: (167rem / 16);
            padding-right: (152rem / 16);
        }          
    }

    .col-12 {
        @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
        }

        &.full-large{
            @include media-breakpoint-up(md) {
                padding: 1rem (92rem /16); /*107 -15 */
            }
        }
    }


    h2 {
        line-height: 1.2;
        @include media-breakpoint-only(md) {
            font-size: 1.4rem;
        }

        .emphasis {
            display: block;
        }
        .underline {
            display: none;
            margin-top: (22rem / 16);
            @include media-breakpoint-up(lg) {
                display: block;
                width: (98rem / 16);
                height: (8rem / 16);
                background-color: $body-color;
            }
        }
    }

    &.background-full h2 {
        text-align: center;

        .underline {
            margin: auto;
        }



    }

    .media-text {
        /* Discussion tech à avoir sur marges et padding natifs de media-texte*/
        /* Redéfinition des marges et padding*/
        margin :1vw 0;
        padding : 0;
    }

    
    
      
        

    
}

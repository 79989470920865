@charset "UTF-8";
.resultat-sommaires {
    height: 100%;
    padding: (16rem / 16);
    @include media-breakpoint-only(xxs) {
        padding-right: (7rem / 16);
        padding-left: (7rem / 16);
    }
    .dropdown-item {
        &:hover {
            background-color: #f27a6f;
        }
    }
    .lqZoneMessageNonDisponibilite {
        @include media-breakpoint-up(md) {
            font-size: (22rem /16);
            font-weight: 500;
            padding-top: 12%;
        }
    }
    .selecteurProduits {
        font-family: Roboto;
        margin-top: (16rem / 16);
        padding-left: (28rem / 16);
        @include media-breakpoint-only(xxs) {
            padding-left: (18rem / 16);
            margin-top: 0;
        }
        padding-right: (16rem / 16);
        height: (53rem / 16);
        width: 100%;
        border-radius: (10rem / 16);
        border: solid (3rem / 16) #000000;
        background-color: white;
        font-weight: 700;
        text-align: left;
        color: #000000;
        display: inline-block;
        position: inherit;
        cursor: pointer;
        z-index: 5;
        font-size: (21rem / 16);
        @include media-breakpoint-only(xxs) {
            font-size: (14.5rem / 16);
        }
        @include media-breakpoint-only(xs) {
            font-size: (17rem / 16);
        }
         :focus {
            border: none;
        }
    }
    .separateur-texte {
        display: inline-block;
        font-family: Roboto;
        font-size: (16rem / 16);
        font-weight: 600;
        margin: (10rem / 16) 0;
        text-transform: uppercase;
        letter-spacing: (0.6rem / 16);
        color: #000000;
    }
    .menu-deroulant {
        overflow-x: hidden;
        max-height: (616rem / 16);
        margin-left: (28rem / 16);
        @include media-breakpoint-only(xxs) {
            margin-left: (18rem / 16);
        }
        margin-top: (10rem / 16);
    }
    .contenantResultat {
        display: flex;
        flex-flow: column;
        @include media-breakpoint-up(sm) {
            min-height: (422rem / 16);
        }
        @include media-breakpoint-up(md) {
            min-height: (477rem / 16);
        }
        @include media-breakpoint-up(lg) {
            min-height: (640rem / 16);
        }
        .lqConteneurGeneral {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            flex-grow: 1;
            font-family: Gordita;
            .logo {
                .blanc {
                    display: none;
                }
            }
            .lqPlaceHolderConteneurGeneral {
                display: none;
            }
            .lqConteneurEnteteProduit {
                .lqEnteteZoneProduit {
                    @include media-breakpoint-up(lg) {
                        height: (240rem / 16);
                    }
                    .logo {
                        color: black;
                        @include media-breakpoint-up(lg) {
                            padding-bottom: (16rem / 16);
                            height: (224rem / 16);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    .lqEnteteZoneDroiteProduit {
                        display: flex;
                        justify-content: center;
                        flex-direction: row-reverse;
                        align-items: center;
                        padding: 0 (16rem / 16);
                        height: (40rem / 16);
                        width: 100%;
                        background-color: #e6e6e6;
                        margin-top: (7rem / 16);
                        .date {
                            font-size: (19rem / 16);
                            @include media-breakpoint-only(xxs) {
                                font-size: (11.5rem / 16);
                                line-height: 18.25px;
                                background-size: 18px 18px; //Il faut préciser pour que ca fonctionne avec ie
                            }
                            @include media-breakpoint-only(xs) {
                                font-size: (12rem / 16);
                                background-size: 18px 18px; //Il faut préciser pour que ca fonctionne avec ie
                            }
                            @include media-breakpoint-up(sm) {
                                background-size: 20x 20px; //Il faut préciser pour que ca fonctionne avec ie
                                padding-left: (28rem/16);
                            }
                            text-transform: uppercase;
                            font-weight: 600;
                            text-align: center;
                            color: #474747;
                            padding-left: (24rem / 16);
                            background-image: url('../images/svg/ic_temps.svg');
                            background-repeat: no-repeat;
                            background-position-y: center;
                            white-space: nowrap;
                            margin-left: (2rem / 16);
                        }
                        .lqZoneBoni,
                        .lqZoneSuperTirage {
                            font-size: (19rem / 16);
                            @include media-breakpoint-only(xxs) {
                                font-size: (11.5rem / 16);
                            }
                            @include media-breakpoint-only(xs) {
                                font-size: (12rem / 16);
                            }
                            @include media-breakpoint-up(lg) {
                                padding-left: (32rem / 16);
                            }
                            text-align: right;
                            color: #d10000;
                            margin-left: (28rem / 16);
                            width: inherit;
                            .labelRougeItalique {
                                font-style: italic;
                            }
                            .labelRougeGras {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
            //Numeros du produit principal
            .numeros {
                margin-top: (10rem / 16);
                .num-sep {
                    margin-left: 2px;
                    margin-right: 2px;
                }
                .num,
                .num-sep {
                    font-size: (23rem / 16);
                    @include media-breakpoint-between(all,
                    xs) {
                        font-size: (21rem / 16);
                    }
                    @include media-breakpoint-only(md) {
                        font-size: (28rem / 16);
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: (36rem / 16);
                    }
                    font-weight: bold;
                    line-height: 1.2;
                    text-align: center;
                    color: #111111;
                }
                .parentheses {
                    display: none;
                }
                .complementaire {
                    width: (45rem / 16);
                    height: (45rem / 16);
                    font-size: (23rem / 16);
                    @include media-breakpoint-only(xxs) {
                        width: (35rem / 16);
                        height: (35rem / 16);
                        font-size: (21rem / 16);
                    }
                    @include media-breakpoint-only(xs) {
                        width: (40rem / 16);
                        height: (40rem / 16);
                        font-size: (21rem / 16);
                    }
                    @include media-breakpoint-only(md) {
                        width: (53rem / 16);
                        height: (53rem / 16);
                        font-size: (28rem / 16);
                    }
                    @include media-breakpoint-up(lg) {
                        width: (65rem / 16);
                        height: (65rem / 16);
                        font-size: (36rem / 16);
                    }
                    border-radius: 50%;
                    background-color: #0068AA;
                    margin-left: (5rem / 16);
                    color: #ffffff !important;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .numerosGarantis {
                text-transform: uppercase;
                @include media-breakpoint-between(all,
                xs) {
                    font-size: (14rem / 16);
                }
                @include media-breakpoint-only(md) {
                    font-size: (18rem / 16);
                }
                @include media-breakpoint-up(lg) {
                    font-size: (22rem / 16);
                }
                .num,
                .num-sep {
                    font-weight: 500;
                }
            }
            >.lqZoneMessageAVenir {
                font-size: (18rem / 16);
                font-weight: 500;
                letter-spacing: (0.6rem / 16);
                text-align: center;
                color: #111111;
                padding: (30rem / 16) 0;
                @include media-breakpoint-only(xxs) {
                    font-size: (16em / 16);
                }
                @include media-breakpoint-up(md) {
                    font-size: (22em / 16);
                }
                @include media-breakpoint-up(lg) {
                    padding-bottom: (80rem / 16);
                }
            }
            //Lien "Voir resultats complets" sous le logo principal
            .lienResultatsComplets {
                letter-spacing: (0.6rem / 16);
                text-decoration: underline;
                color: #111;
                font-weight: 400;
                font-size: 18px;
                padding: (30rem / 16) 0;
                @include media-breakpoint-only(xxs) {
                    font-size: 16px;
                }
                @include media-breakpoint-up(md) {
                    font-weight: 500;
                    font-size: 22px;
                    padding-top: 0;
                    padding-bottom: 0;
                }
                @include media-breakpoint-up(lg) {
                    font-weight: 500;
                    font-size: 22px;
                    padding-bottom: (80rem / 16);
                    padding-top: 0;
                    padding-bottom: 0;
                }
                &:hover {
                    text-decoration: none;
                }
                &:active,
                &:visited {
                    text-decoration: underline;
                    color: #111111;
                }
                &:focus {
                    text-decoration: none;
                }
                .lqInvisible {
                    position: absolute;
                    width: (1rem / 16);
                    height: (1rem / 16);
                    padding: 0;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border: 0;
                }
            }
            //Zone pour l'Extra
            .lqZoneProduit {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: (32rem / 16) (16rem / 16) 0;
                border-top: dashed (1rem / 16) #9a9a9a;
                @include media-breakpoint-up(lg) {
                    margin: (24rem / 16) (28rem / 16) 0;
                    padding-top: (16rem / 16);
                }
                .logo {
                    width: (208rem / 16);
                    height: auto;
                    pointer-events: none;
                    color: black;
                    img.noir {
                        margin-left: (-64rem / 16);
                        @include media-breakpoint-between(all,
                        xs) {
                            margin-left: (-144rem / 16);
                        }
                        width: 70%;
                    }
                }
                // Numeros pour produit remorque (Extra)
                .numeros {
                    margin-left: (-48rem / 16);
                    margin-top: 0;
                    @include media-breakpoint-between(all,
                    xs) {
                        margin-left: (-112rem / 16);
                    }
                    .num,
                    .num-sep,
                    .parentheses {
                        margin-left: (5rem / 16);
                        font-size: (16rem / 16);
                        font-weight: 500;
                        @include media-breakpoint-only(sm) {
                            font-size: (18rem / 16);
                        }
                        @include media-breakpoint-only(md) {
                            font-size: (21rem / 16);
                        }
                        @include media-breakpoint-up(lg) {
                            font-size: (24rem / 16);
                        }
                        line-height: normal;
                        color: #000000;
                    }
                }
                .lqZoneMessageAVenir {
                    letter-spacing: (0.6rem / 16);
                    text-align: center;
                    color: #111111;
                    margin-left: (-48rem / 16);
                    font-size: (14rem / 16);
                    @include media-breakpoint-only(xxs) {
                        margin-left: (-131rem / 16);
                        font-size: (13rem / 16);
                    }
                    @include media-breakpoint-only(xs) {
                        margin-left: (-131rem / 16);
                    }
                }
            }
            .lqZoneErreur {
                margin-bottom: 42%;
                @include media-breakpoint-between(all,
                xs) {
                    margin-top: 20%;
                    margin-bottom: 20%;
                }
            }
        }
        .btn-resultats-sommaires {
            @include useFont(gordita,
            bold);
            font-size: (11rem / 16);
            margin-top: (24rem / 16);
            //Bouton "Tous les resultats"
            .lienResultatsDetailles {
                padding: (13rem / 16) (27rem / 16) (6rem / 16);
                margin: 0 (5rem / 16) (8rem / 16);
                display: inline-block;
                vertical-align: middle;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                box-shadow: 0 0 (11rem / 16) (2rem / 16) rgba(240, 82, 49, 0.34);
                border: solid (3rem / 16) #f27a6f;
                background-color: #FFFFFF;
                border-radius: (25rem / 16);
                @include useFont(Gordita,
                700);
                color: #000000;
                line-height: normal;
                letter-spacing: (0.8rem / 16);
                text-transform: uppercase;
                font-size: (16rem / 16);
                @include media-breakpoint-only(xxs) {
                    font-size: (10.5rem / 16);
                    line-height: (16rem / 16);
                    letter-spacing: (0.6rem / 16);
                    padding-top: (11rem / 16);
                    padding-bottom: (11rem / 16);
                }
                @include media-breakpoint-only(xs) {
                    font-size: (11.5rem / 16);
                    line-height: (16rem / 16);
                    letter-spacing: (0.6rem / 16);
                    padding-top: (11rem / 16);
                    padding-bottom: (11rem / 16);
                }
                @include media-breakpoint-only(sm) {
                    height: (51rem / 16);
                    font-size: (14.5rem / 16);
                    padding-top: (11rem / 16);
                }
                @include media-breakpoint-only(md) {
                    height: (56rem / 16);
                    padding-top: (12rem / 16);
                    font-size: (16rem / 16);
                }
                @include media-breakpoint-up(lg) {
                    height: (55rem / 16);
                    font-size: (13.5rem / 16);
                    line-height: (16rem / 16);
                    letter-spacing: (0.6rem / 16);
                    padding-top: (18rem / 16);
                }
                @include media-breakpoint-up(xl) {
                    padding-top: (16rem / 16);
                    font-size: (15rem / 16);
                }
                &:hover,
                &:active {
                    border: solid (3rem / 16) #c62d1f !important;
                    background-color: #c62d1f !important;
                    box-shadow: none !important;
                    text-decoration: none;
                    color: #ffffff;
                }
                &:focus {
                    box-shadow: 0 0 (11rem / 16) (2rem / 16) rgba(240, 82, 49, 0.34) !important;
                    border: solid (3rem / 16) #f27a6f !important;
                    background-color: white;
                }
            }
            //Bouton acheter
            .btn {
                margin: (3rem / 16) (5rem / 16) (10rem / 16);
                @include media-breakpoint-between(all,
                xs) {
                    font-size: (11.5rem / 16);
                }
                @include media-breakpoint-only(md) {
                    font-size: (16rem / 16);
                }
                @include media-breakpoint-up(lg) {
                    font-size: (15rem / 16);
                }
            }
            //Bouton acheter
            .btn.texte-bouton {
                padding: (13rem / 16) (27rem / 16) (6rem / 16);
                margin: 0 (5rem / 16) (8rem / 16);
                display: inline-block;
                vertical-align: middle;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                box-shadow: 0 0 (11rem / 16) (2rem / 16) rgba(239, 51, 64, 0.34);
                border: solid (3rem / 16) #ef3340;
                background-color: #FFFFFF;
                border-radius: (25rem / 16);
                @include useFont(Gordita,
                700);
                color: #000000;
                line-height: normal;
                letter-spacing: (0.8rem / 16);
                text-transform: uppercase;
                font-size: (16rem / 16);
                @include media-breakpoint-only(xxs) {
                    font-size: (10.5rem / 16);
                    line-height: (16rem / 16);
                    letter-spacing: (0.6rem / 16);
                    padding-top: (11rem / 16);
                    padding-bottom: (11rem / 16);
                }
                @include media-breakpoint-only(xs) {
                    font-size: (11.5rem / 16);
                    line-height: (16rem / 16);
                    letter-spacing: (0.6rem / 16);
                    padding-top: (11rem / 16);
                    padding-bottom: (11rem / 16);
                }
                @include media-breakpoint-only(sm) {
                    height: (51rem / 16);
                    font-size: (14.5rem / 16);
                    padding-top: (11rem / 16);
                }
                @include media-breakpoint-only(md) {
                    height: (56rem / 16);
                    font-size: (13.5rem / 16);
                    padding-top: (12rem / 16);
                    font-size: (16rem / 16);
                }
                @include media-breakpoint-up(lg) {
                    height: (55rem / 16);
                    font-size: (15rem / 16);
                }
                &:hover,
                &:active {
                    background-color: #c21f2a !important;
                    border-color: #c21f2a !important;
                    box-shadow: none;
                    text-decoration: none;
                    color: #ffffff;
                }
                &:focus {
                    border: solid (3rem / 16) #ef3340 !important;
                    background-color: white;
                }
            }
        }
    }
}
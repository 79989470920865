    @charset "utf-8";
    body {
        min-width: (320rem / 16);
        width: auto !important;
    }
    
    #entete {
        height: 11.5rem;
        visibility: hidden;
        @include media-breakpoint-down('1440px') {
            height: 13.25rem;
        }
    }
    
    sup {
        font-size: 70%;
    }
    
    .note {
        font-size: (14em / 16);
        @include media-breakpoint-up(md) {
            font-size: (12em / 16);
        }
    }
    
     :lang(fr)>q {
        quotes: '\00ab\00a0' '\00a0\00bb' '\0022' '\0022';
    }
    
     :lang(en)>q {
        quotes: '"' '"' ''' ''';
    }
    
    .page-generique {
        padding: 0;
        margin: 0;
        /* À refactoriser avec les autres composantes 
    * qui ont le même comportement comme Galerie / conteneur arrondis
    */
        @include media-breakpoint-down(sm) {
            //Pas de background en mobile
            background-size: 0%;
            background: none !important;
        }
        &.bg-cover {
            background-size: 100%;
            background-repeat: no-repeat;
            @include media-breakpoint-down(sm) {
                //Pas de background en mobile
                background-size: 0%;
            }
        }
        &.bg-no-repeat {
            background-repeat: no-repeat;
            @include media-breakpoint-down(sm) {
                //Pas de background en mobile
                background-size: 0%;
            }
        }
    }
    
    .cache {
        visibility: hidden;
    }
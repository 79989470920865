@charset "utf-8";

.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.lotto-max {
                >.numeros {
                    .num, .num-sep {
                    	@include media-breakpoint-between(all, xs) {
                            font-size: (19rem / 16);
                        }
                        
                        @include media-breakpoint-only(lg) {
                            font-size: (35rem / 16);
                        }
                    }
                    .complementaire {
                        @include media-breakpoint-between(all, xs) {
                            font-size: (19rem / 16);
                        }
                        @include media-breakpoint-only(lg) {
                            height: (59rem / 16);
                            width: (59rem / 16);
                            margin-left: (1.6rem / 16);
                        }
                    }
                }
                .lqZoneProduit {
                    .num, .num-sep {
                        @include media-breakpoint-only(lg) {
                            font-size: (24rem / 16);
                        }
                    }
                }
            }
        }
    }
}
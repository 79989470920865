@charset "utf-8";
@include media-breakpoint-up(md) {
    .containerMenu {
        margin: 0 auto (5rem / 16);
        max-width: 950px;
        width: 100%;
        //margin-top: (22rem / 16);
        margin-top: (64rem / 16);
        @include media-breakpoint-up(lg) {
            margin-bottom: (10rem / 16);
            width: 1140px;
            max-width: none;
        }
        .lqZoneWrapper {
            margin-top: 2px;
            position: relative;
            width: 100%;
            display: inline;
            z-index: 99;
            /*height: 100%;*/ //Pose problème pour fond de page pleine largeur LSWI-1161
            #lqZoneLogoPrincipal {
                /* margin-left: 5.26315789%;*/
                margin-left: 4%;
                float: left;
                width: 20%;
                height: auto;
                margin-bottom: (2rem / 16);
                position: relative;
                a {
                    width: 100%;
                }
                img[data-sizes="auto"] {
                    height: (52rem / 16);
                    width: (228rem / 16);
                    &.lazyload {
                        visibility: hidden;
                    }
                    @include media-breakpoint-between(sm, md) {
                        height: (37rem / 16);
                        width: (176rem / 16);
                    }
                }
            }
        }
        #lqZoneSecondaire {
            display: none;
        }
        #lqZoneMenuComplet {
            //display: flex;
            width: 100%;
            border: 0;
            display: inline;
            padding-bottom: 0px;
            /*
            display: inline-block;
            */
            clear: both;
            text-align: left;
            position: relative;
            font-family: functionpro, Helvetica, Arial, sans-serif;
            #lqSousMenu.show {
                display: block;
                clear: both;
            }
            .lqZoneMenuCompletLiensOnglets {
                background-color: #B7C3CC;
                border-bottom: 1px dotted #90969C;
                display: none;
                float: left;
                //% pris direct du debug de loteries mag 5
                padding-top: 1.05263158%;
                padding-bottom: 0;
                padding-left: 2.10526316%;
                padding-right: 2.10526316%;
                width: 100%;
                li {
                    float: left;
                    //font-size: .75em;
                    font-size: 12px;
                    line-height: 2em;
                    margin-bottom: 10px;
                    margin-right: 5px;
                    text-transform: uppercase;
                    width: 212px;
                }
                .loggueClientJoueur,
                .loggueClientParticipant,
                .loggueClientInvite {
                    display: none;
                    &.loggue {
                        display: block;
                    }
                }
            }
            a.lqZoneMenuLienInterne {
                color: #313a45;
                float: left;
                padding: 2px 9px;
                font-weight: 400;
                line-height: 12px;
                font-family: functionpro, Helvetica, Arial, sans-serif;
            }
            a.lqZoneMenuLienInterne:hover,
            a.lqZoneMenuTitreGroupeLiens:hover {
                color: #313a45;
                background-color: #eef3f6;
                text-decoration: none;
            }
            a.lqZoneMenuLienInterne.pageActuelle:hover {
                background-color: #313a45;
                color: #FFFFFF;
                text-decoration: none;
            }
            a.lqZoneMenuLienInterne.pageActuelle {
                background-color: #313a45;
                color: #FFFFFF;
            }
            a {
                color: #313a45;
            }
            #lqOnglets {
                width: 100%;
                #lqZoneMenuCompletOnglets {
                    display: flex;
                    float: right;
                    overflow: hidden;
                    width: auto;
                    height: (28rem/16);
                    flex-direction: row;
                    padding-inline-start: 2px;
                    .lqZoneMenuOngletNiveau1 {
                        font-size: (14rem/16);
                        &:hover a {
                            background-color: #dce3e9;
                            border-radius: 6px 6px 0 0;
                        }
                        display: flex;
                        flex-direction: row;
                        .lqFlecheSubMenu {
                            display: none;
                        }
                        .lqSansFleche {
                            display: none;
                        }
                        .menuPetit {
                            display: none;
                        }
                        .lqZoneMenuTitreGroupeLiens {
                            padding: (10rem/16) (17rem/16);
                            line-height: (14rem/16);
                            height: fit-content;
                            @include media-breakpoint-down(md) {
                                padding: (7rem/16) (8rem/16);
                            }
                        }
                        .lqSeparateurDroite {
                            border-right: 1px dotted #808080;
                            height: (11rem/16);
                            margin: auto 0;
                            margin-right: -1px;
                        }
                        &.premierOnglet,
                        &.ongletParDefault,
                        &:hover {
                            .lqSeparateurDroite {
                                border-right: 1px solid transparent;
                            }
                        }
                        &.ongletParDefault,
                        &.ongletParDefault:hover a {
                            background-color: #b7c3cc;
                            border-radius: 6px 6px 0 0;
                            .lqSeparateurDroite {
                                border-right: 1px solid transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}
@charset "utf-8";

.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.triplex {
                .numeros {
                    .num, .num-sep {
                        margin: (3rem / 16);
                    }
                }
            }
        }
    }
}
.resultats-recents.resultat-sommaires .contenantResultat {
    min-height: auto;
}

.resultats-recents.resultat-sommaires .media-bouton.btn-text .btn.btn-color,
.resultats-recents.resultat-sommaires .media-bouton.btn-text .btn.btn-reverse-color {
    border-color: $btn-border-color;
}

.resultats-recents.resultat-sommaires .media-bouton.btn-text {
    margin: 0;
    margin-bottom: 15px;
    text-align: center;
}

.resultats-recents.resultat-sommaires .deux-btn .media-bouton.btn-text {
    width: 45%;
}

.resultats-recents.resultat-sommaires .trois-btn .media-bouton.btn-text {
    width: 30%;
    @include media-breakpoint-up(md) {
        // width: 40%;
    }
    .btn {
        min-width: initial;
    }
}

.resultats-recents.resultat-sommaires .conteneur-flex-3-colonnes {
    .trois-btn .media-bouton.btn-text {
        width: 40%;
    }
}

.resultats-recents.resultat-sommaires {
    button.btn,
    a.btn {
        min-width: initial;
    }
}

.resultats-recents.resultat-sommaires .media-bouton.btn-text .btnContainer {
    border-radius: 0.9375rem;
}

.resultats-recents.resultat-sommaires .media-bouton.btn-text .btn.btn-color,
.resultats-recents.resultat-sommaires .media-bouton.btn-text .btn.btn-reverse-color {
    border-width: 2px;
}

.resultats-recents.resultat-sommaires .media-bouton.btn-text .btn {
    padding-left: 0;
    padding-right: 0;
}

.resultats-recents.resultat-sommaires .media-bouton.btnContainer {
    margin: 0;
    width: 100%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral>.lqZoneMessageAVenir {
    padding: 0;
    font-size: $numeros-font-size;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .numeros .groupe {
    line-height: 2rem;
    text-align: center;
}

.resultats-recents.resultat-sommaires .media-bouton.btn-text .btn.btn-color:hover,
.resultats-recents.resultat-sommaires .media-bouton.btn-text .btn.btn-color:focus {
    background-color: $btn-border-color;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit .date {
    background-image: none;
}

.resultats-recents.resultat-sommaires .media-bouton.btn-text .btn {
    padding-top: 0;
    padding-bottom: 0;
}

.resultats-recents.resultat-sommaires .media-bouton {
    font-size: $btn-font-size;
    line-height: $btn-text-line-height;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .remorque .numeros,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .numeros {
    width: auto;
    margin: 0 auto;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .numerosGarantis {
    width: 80%;
    margin: 0 auto;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lienResultatsComplets {
    text-align: center;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .numeros .num,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .numeros .num-sep {
    @include fluid-type($xxs, $xxl, 16px, $numeros-font-size);
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .numeros .groupe .num,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .numeros .groupe .num-sep {
    font-size: $numeros-font-size;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .numeros .complementaire {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.25rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit .logo img.noir {
    margin-left: 0;
}

.conteneur-flex-1-colonnes {
    .resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.banco .numeros .groupe .num,
    .resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.banco .numeros .groupe .num-sep {
        font-size: 0.75rem;
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit {
    margin-bottom: 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit {
    margin-bottom: 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lienResultatsComplets {
    font-size: $numeros-font-size;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .numerosGarantis {
    font-size: 1.0625rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-quotidienne .lqConteneurNumeros .lqContenuQuotidienne .titre span.numeros {
    font-size: $numeros-font-size;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-quotidienne .lqConteneurNumeros .lqContenuQuotidienne {
    font-size: $numeros-font-size;
}

.resultats-recents.resultat-sommaires div#zoneLP .col-4.align-self-center {
    display: none;
}

.resultats-recents.resultat-sommaires .infoProgressif .zoneBouton {
    display: none;
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes {
    .lqConteneurGeneral.lotto-6-49 .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit {
        left: 0;
    }
}

.resultats-recents.resultat-sommaires .conteneur-flex-2-colonnes {
    .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit .date {
        font-size: 1rem;
    }
    .resultat-sommaires .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit .lqZoneBoni,
    .resultat-sommaires .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit .lqZoneSuperTirage {
        font-size: 1rem;
    }
}

.resultats-recents.resultat-sommaires {
    .conteneur-flex-1-colonnes,
    .conteneur-flex-2-colonnes {
        .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit {
            .lqZoneBoni,
            .lqZoneSuperTirage,
            .date {
                font-size: 1.1rem;
            }
        }
    }
    .conteneur-flex-3-colonnes {
        .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit {
            .lqZoneBoni,
            .lqZoneSuperTirage,
            .date {
                font-size: 0.8rem;
                display: inline-block;
                text-align: right;
            }
        }
    }
}

.resultats-recents.resultat-sommaires .conteneur-flex-3-colonnes {
    .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit .lqZoneBoni,
    .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit .lqZoneSuperTirage,
    .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit .date {
        font-size: 0.9rem;
        display: inline-block;
        text-align: right;
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit {
    border-top: dashed 0.0625rem #131B4D;
}

.resultats-recents.resultat-sommaires {
    background-image: none;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
    position: relative;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit {
    padding: 0 0;
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes .contenantResultat .lqConteneurGeneral .lqZoneProduit {
    text-align: center;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit {
    padding: 0 12%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit.remorque.enBlackout {
    padding: 0 0;
}

.resultats-recents.resultat-sommaires .conteneur-flex-3-colonnes .contenantResultat .lqConteneurGeneral .lqZoneProduit.remorque.enBlackout {
    padding: 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit .logo img.noir {
    max-width: 57%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .remorque .numeros .num,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .remorque .numeros .num-sep {
    @include fluid-type($xxs, $xxl, 16px, 20px);
}

.resultats-recents.resultat-sommaires .col-8 {
    flex: initial;
    max-width: initial;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit.remorque.enBlackout .logo {
    width: auto;
    text-align: center;
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes .contenantResultat .lqConteneurGeneral .lqZoneProduit.remorque.enBlackout .logo {
    width: 33%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit.remorque .logo {
    width: auto;
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes .contenantResultat .lqConteneurGeneral .lqZoneProduit.remorque .logo {
    text-align: center;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit.remorque .logo.blanc {
    display: none;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit.remorque .numeros {
    margin: 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .remorque .numeros .num {
    margin-right: 4px;
    line-height: 1rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-quotidienne .lqConteneurNumeros .lqContenuQuotidienne .titre {
    width: 15rem;
    padding: 0;
}

.resultats-recents.resultat-sommaires .media-bouton.btn-text .btn {
    align-items: center;
    display: flex;
    justify-content: center;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit {
    margin: 1.5rem 1.25rem 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit .date {
    padding-bottom: 0.2rem;
}

.resultats-recents.resultat-sommaires {
    .produit-placeholder {
        .lqZoneErreur {
            .lqZoneMessageNonDisponibilite {
                text-align: center;
            }
        }
    }
}
@charset "utf-8";
.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.astro {
                .legende {
                    margin: 0 auto;
                    display: flex;
                    text-align: center;
                    justify-content: space-between;
                    width: (400rem / 16);
                    font-size: (14rem / 16);
                    font-weight: 500;
                    color: #9a9a9a;
                    text-transform: uppercase;
                    @include media-breakpoint-between(all, xs) {
                        width: (300rem / 16);
                        font-size: (11rem / 16);
                        font-weight: 700;
                    }
                    @include media-breakpoint-only(md) {
                        font-size: (16rem / 16);
                        width: (530rem / 16);
                        padding-right: (16rem / 16);
                    }
                    @include media-breakpoint-up(lg) {
                        width: (480rem / 16);
                        font-size: (16rem / 16);
                        padding-right: (16rem / 16);
                        padding-left: (18rem / 16);
                    }
                    @media (min-width: 1200px) and (max-width: 1221px) {
                        padding-bottom: 24px;
                    }
                    .jour {
                        width: 16.5%;
                        @include media-breakpoint-between(all, xs) {
                            width: 18.5%;
                        }
                        @include media-breakpoint-only(md) {
                            width: 19%;
                        }
                    }
                    .mois {
                        width: 42%;
                        @include media-breakpoint-between(all, xs) {
                            width: 36%;
                        }
                        @include media-breakpoint-up(lg) {
                            width: 49%;
                        }
                    }
                    .annee {
                        width: 15.5%;
                        @include media-breakpoint-between(all, xs) {
                            width: 10.5%;
                        }
                        @include media-breakpoint-only(md) {
                            width: 19%;
                        }
                        @include media-breakpoint-up(lg) {
                            width: 18%;
                        }
                    }
                    .signe {
                        width: 26%;
                        @include media-breakpoint-between(all, xs) {
                            width: 34%;
                        }
                        @include media-breakpoint-only(md) {
                            width: 19%;
                        }
                        @include media-breakpoint-up(lg) {
                            width: 23%;
                        }
                    }
                    .num-sep {
                        display: none;
                    }
                }
                >.numeros {
                    margin: 0 auto;
                    @include media-breakpoint-up(lg) {
                        margin-top: (-32rem / 16);
                    }
                    display: flex;
                    align-items: center;
                    width: (400rem / 16);
                    height: (70rem / 16);
                    justify-content: space-between;
                    padding: 0 (15rem / 16);
                    border: 1px dashed #9a9a9a;
                    @include useFont(gordita, bold);
                    font-size: (21rem / 16);
                    @include media-breakpoint-up(xxs) {
                        width: (295rem / 16);
                        font-size: (13.5rem / 16);
                    }
                    @include media-breakpoint-up(sm) {
                        width: (400rem / 16);
                        font-size: (21rem / 16);
                    }
                    @include media-breakpoint-only(md) {
                        font-size: (26rem / 16);
                        width: (530rem / 16);
                        padding: (10rem / 16) (30rem / 16);
                        height: (90rem / 16);
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: (26rem / 16);
                        width: (480rem / 16);
                        padding: 0 (30rem / 16);
                        height: (90rem / 16);
                    }
                    .jour,
                    .annee {
                        width: 9%;
                    }
                    .num-sep {
                        width: 6.5%;
                        @include media-breakpoint-up(lg) {
                            font-size: (26rem / 16);
                            width: 3%;
                        }
                    }
                    .mois {
                        width: 37.5%;
                        @include media-breakpoint-up(lg) {
                            width: 42%;
                        }
                    }
                    .signe {
                        width: (70rem / 16);
                        float: left;
                        text-align: center;
                        height: 65px;
                        &.noir {
                            width: auto;
                        }
                        &.blanc {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.is-ie {
    .resultat-sommaires {
        .contenantResultat {
            .lqConteneurGeneral {
                &.astro {
                    .legende {
                        @include media-breakpoint-up(lg) {
                            height: (60rem / 16);
                        }
                    }
                }
            }
        }
    }
}
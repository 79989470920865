@charset "utf-8";

.containerMenu {
    margin-bottom: (8rem / 16);
    overflow-x: hidden;
	#lqZoneMenuComplet nav {
		display: block;
		left: 0;
		position: relative;
		top: 0;
		width: 100%;
		z-index: 87;
	}
    #lqZoneMenuComplet {
    
        #lqOnglets {
            #lqZoneMenuCompletOnglets {
                list-style-type: none;
                text-transform: uppercase;
                margin-bottom: 0;
                .lqZoneMenuOngletNiveau1 {
                    :not(.lqFlecheSubMenu) {
                        @include useFont(functionpro, 400);
                    }
                    a, a:hover {
                        text-decoration: none;
                        color: #313a45;
                    }
                    .menuPetit {
                        display: none;
                        list-style-type: none;
                    }
                }
            }
        }
        #lqSousMenu {
            //Par défaut caché à moins d'être connecté
            .lqZoneMenuCompletLiensOnglets {
                display: none;
                list-style-type: none;
                margin-top: -1px;
            }
            .lqZoneMenuCompletLiensOnglets.show {
                display: block;
            }
        }
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.jour-de-chance-2024 .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
    left: 8%;
    top: 17%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.jour-de-chance-2024>.lqZoneDescriptionLots {
    font-size: 1rem;
    line-height: 1rem;
    margin: 0 auto;
    margin-top: 6.28125rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.jour-de-chance-2024 .numeros {
    margin-bottom: 5.03125rem;
    margin-top: 1.25rem;
    padding: 0;
    width: auto;
    display: flex;
    line-height: 1.5rem;
    .num {
        margin-left: 0;
        line-height: 1.5rem;
    }
}

.resultat-sommaires .contenantResultat .lqConteneurGeneral.jour-de-chance-2024 .numeros .num,
.resultat-sommaires .contenantResultat .lqConteneurGeneral.jour-de-chance-2024 .numeros .num-sep {
    line-height: 1.5rem;
}

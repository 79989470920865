@charset "utf-8";

.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.banco {

                .numeros {
                    margin-bottom: (16rem / 16);
                    font-size    : (15.5rem / 16);

                    @include media-breakpoint-between(xxs, xs) {
                        font-size: (14rem /16);
                    }

                    //Alignement de l'extra
                    @include media-breakpoint-up(xxs) {
                        margin-top: (18rem / 16);
                    }

                    .groupe {
                        line-height: 2.25;

                        .num,
                        .num-sep {
                            font-size  : (15rem / 16);
                            line-height: 2;
                            margin     : 0 (-2rem / 16);

                            @include media-breakpoint-up(xxs) {
                                font-size  : (14rem /16);
                                line-height: 1;
                                margin     : 0 (0.5rem / 16);
                            }

                            @include media-breakpoint-up(xs) {
                                font-size: (14rem /16);
                            }

                            @include media-breakpoint-up(sm) {
                                font-size: (24rem /16);
                                margin   : 0 (1rem / 16);
                            }

                            @include media-breakpoint-up(lg) {
                                font-size: (24rem / 16);
                                margin   : 0 (0.5rem / 16);
                            }

                            @include media-breakpoint-up(xl) {
                                font-size: (28rem / 16);
                            }
                        }
                    }
                }

                .lqConteneurMultiplicateur {
                    display        : flex;
                    justify-content: center;
                    align-items    : center;

                    img.multiplicateur {
                        width: (99rem / 16);

                        @include media-breakpoint-up(sm) {
                            width: (105rem / 16);
                        }

                        @include media-breakpoint-up(lg) {
                            width: (126rem / 16);
                        }
                    }

                    p {
                        padding-left: (10rem / 16);
                        font-weight : bold;
                        color       : #000000;
                        margin      : 0;
                        font-size   : (16rem / 16);

                        @include media-breakpoint-up(sm) {
                            font-size: (21rem / 16);
                        }
                    }
                }
            }
        }
    }
}
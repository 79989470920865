.resultats-recents {
    .conteneur-flex-1-colonnes {
        display: flex;
        @include media-breakpoint-up(md) {
            display: none;
        }

        .column {
            width: 100%;
            margin: 0;
        }
    }

    .conteneur-flex-2-colonnes {
        display: none;
        .column {
            width: 50%;
            margin: 0 auto;
            padding: 0 1%;
        }
        @include media-breakpoint-up(md) {
            display: flex;
        }
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .conteneur-flex-3-colonnes {
        display: none;
        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: center;
        }

    }

    .column {
        display: flex;
        flex-direction: column;
        margin: 0 1.25vw;


        @include media-breakpoint-up(md) {
            box-sizing: border-box;
        }

        @include media-breakpoint-up(lg) {

        }
    }
}


.resultats-recents {
    .conteneur-flex-3-colonnes {
        .column .produit-placeholder {
            width: calc( 100vw * 500/1920);
            max-width: 500px;
        }
    }
}
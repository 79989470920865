@charset "utf-8";
.accordeon {
    margin-bottom: 2vh;
    @include media-breakpoint-up(md) {
        margin-bottom: 1.5vh;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 1.5vh;
    }
    &__header {
        box-shadow: 0px 2px 3px #00000029;
        margin-bottom: 1vw;
        @include media-breakpoint-up(md) {
            margin-bottom: 0.7vw;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 0.5vw;
        }
        .btn-link {
            padding-left: 0 !important;
            .accordeon__icone-header,
            .plus {
                background-image: url('../images/svg/fleche-accordeon.svg');
                background-size: 70%;
                @include media-breakpoint-up(md) {
                    background-size: 85%;
                }
                @include media-breakpoint-up(lg) {
                    background-size: 100%;
                }
            }
            span {
                font-style: normal;
                font-variant: normal;
                font-weight: bolder;
                font-size: (13rem / 16);
                line-height: (13rem / 16);
                font-family: Roboto;
                color: #141b4d;
                @include media-breakpoint-up(md) {
                    font-size: (14rem / 16);
                    line-height: (14rem / 16);
                }
                @include media-breakpoint-up(lg) {
                    font-size: (18rem / 16);
                    line-height: (18rem / 16);
                }
            }
        }
    }
    &-body {
        overflow-x: hidden;
        font-size: 13px;
        color: #111111;
        padding: 0;
        margin: 0;
        @include media-breakpoint-up(lg) {
            font-size: 18px;
        }
        div.row {
            margin-bottom: 10px;
            word-wrap: break-word;
        }
        .media-text {
            padding: 1vw 3vw 1vw 3vw;
            @include media-breakpoint-up(lg) {
                padding: 1vw 3vw 1vw 3vw;
            }
        }
        .conteneur-tableau {
            padding: 1vw 0vw 1vw 0vw;
            @include media-breakpoint-up(md) {
                padding: 1vw 0.5vw 1vw 0.5vw;
            }
            @include media-breakpoint-up(lg) {
                padding: 1vw 1.5vw 1vw 1.5vw;
            }
        }
        .rangee {
            padding: 1vw 3vw 1vw 3vw;
            @include media-breakpoint-up(lg) {
                padding: 1vw 3vw 1vw 3vw;
            }
        }
        .lqcat-image {
            padding: 1vw 3vw 1vw 3vw;
            margin: 1vw 0;
            @include media-breakpoint-up(lg) {
                padding: 1vw 3vw 1vw 3vw;
            }
        }
    }
}
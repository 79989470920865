@charset "utf-8";

/**/


/*********************************************************************************************
* Pour le moment dépendant de _lq.carousel.scss
* Les styles ici font juste le complément nécessaire à Galerie-Owl et non utile à Carrousel
*
**********************************************************************************************/

.conteneur-galerie {
    a:hover {
        text-decoration: none;
    }
    .bg-cover {
        background-size: cover;
        @include media-breakpoint-down(md) {
            &:not(.background-full) {
                //Pas de background à moins d'être en background pleine largeur
                background-size: 0%;
            }
        }
    }
    //Si le côté droit est arrondi
    &.radius-left {
        padding-right: (15rem / 16);
        @include media-breakpoint-up(md) {
            padding-right: (48rem / 16);
        }
        @include media-breakpoint-up(xl) {
            padding-right: (144rem / 16);
        }
        .encadre-galerie-owl {
            margin-right: (-15rem / 16);
            @include media-breakpoint-up(md) {
                margin-right: (-48rem / 16);
            }
            @include media-breakpoint-up(xl) {
                margin-right: 0;
            }
        }
        .background-bordershadow {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    //Si le côté gauche est arrondi
    &.radius-right {
        padding-left: (15rem / 16);
        @include media-breakpoint-up(md) {
            padding-left: (48rem / 16);
        }
        @include media-breakpoint-up(xl) {
            padding-left: (144rem / 16);
        }
        .encadre-galerie-owl {
            margin-left: (-15rem / 16);
            @include media-breakpoint-only(md) {
                margin-left: (-48rem / 16);
            }
            @include media-breakpoint-up(lg) {
                margin-left: 0;
            }
            &.reverse {
                flex-direction: column;
                @include media-breakpoint-up(lg) {
                    flex-direction: row-reverse;
                    .titre-galerie {
                        text-align: left;
                        align-items: left;
                        .underline {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .background-bordershadow {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .bouton-galerie {
        
        @include media-breakpoint-only(xxs) {
            font-size: (12rem / 16);
        }
        @include media-breakpoint-up(xs) {
            font-size: (12rem / 16);
        }
        @include media-breakpoint-up(sm) {
            font-size: (16rem / 16);
        }
        padding: 0;
        margin-top: (32rem / 16);
        @include media-breakpoint-up(lg) {
            font-size: 0.9vw;
            min-height: (40rem / 16);
        }
        @include media-breakpoint-up(xl) {
            min-height: (46rem / 16);
        }

        .btnContainer {
            box-shadow: 0 0 13.9px 2.1px rgba(39, 39, 39, 0.29);
            width: fit-content;
            border-radius: (26rem / 16);
            display: table; //correction bug dans edge et ie
            padding-top: 0.625rem;
            padding-bottom: 0.625rem;
            margin-left: auto;
            margin-right: auto;
        }

        .btn {
            height: auto;
            padding-top: (7rem / 16);
            padding-bottom: (7rem / 16);
            margin-top: (20rem / 16);
            @include media-breakpoint-up(xxs) {
                padding-left: (15rem / 16);
                padding-right: (15rem / 16);
            }
            @include media-breakpoint-up(sm) {
                padding-left: (32rem / 16);
                padding-right: (32rem / 16);
            }

            &.btn-color { 
                border: solid 3px transparent;
            }

            &.btn-color:not(:hover),
            &.btn-color:not(:focus) {
                color: rgb(0, 0, 0);
                background-color: #fff;
                box-shadow: none;
                z-index: 2;    
                background-clip: padding-box;
            }
            &.btn-color:hover,
            &.btn-color:focus {
                background-image: none;
                background-color: transparent;
                color: rgb(255, 255, 255);
            }
        }
    }
    .background-bordershadow {
        border-radius: (40rem / 16);
        @include media-breakpoint-down(md) {
            /*Parce que la couleur de fond pour Desktop est remplie dans Magnolia on est obligé de mettre un important pour surcharge pour petits bp*/
            box-shadow: 0 0 (15.7rem / 16) (2.3rem / 16) rgba(20, 27, 77, 0.4) !important;
        }
    }
    .col-lg-9 {
        padding: (26rem / 16) 0 (42rem / 16) (26rem / 16);
        @include media-breakpoint-only(xxs) {
            padding: 0 0 (29rem / 16) (9rem / 16); //image 260*260
        }
        @include media-breakpoint-only(xs) {
            padding: 0 0 (29rem / 16) (9rem / 16);
        }
    }
    .encadre-galerie-owl {
        align-items: center;
        /*permet alignement vertical (flex)*/
        padding: (33rem / 16) (33rem / 16) 0;
        @include media-breakpoint-between(all, sm) {
            padding: (20rem / 16) (50rem / 16) (5rem / 16) (33rem / 16);
        }
        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }
        .titre-galerie {
            padding: 0;
            @include media-breakpoint-between(all, sm) {
                padding-left: (17rem / 16);
            }
            @include media-breakpoint-up(md) {
                height: (130rem / 16);
            }
            text-align: center;
            @include media-breakpoint-up(lg) {
                text-align: right;
                align-items: right;
            }
            h2 {
                line-height: 1.2;
                .emphasis {
                    display: block;
                }
                .underline {
                    display: none;
                    margin-top: (22rem / 16);
                    margin-left: auto;
                    @include media-breakpoint-up(lg) {
                        display: block;
                        width: (98rem / 16);
                        height: (8rem / 16);
                    }
                }
            }
        }
    }
    .galerie-owl {
        margin: auto;
        @include media-breakpoint-up(lg) {
            width: 90%;
        }
        &.owl-carousel {
            margin-top: (16rem / 16);
            @include media-breakpoint-only(xxs) {
                margin-top: (5rem / 16);
            }
            @include media-breakpoint-only(md) {
                margin-top: 0;
            }
            @include media-breakpoint-up(lg) {
                margin-top: (36rem / 16);
            }
            .owl-stage-outer {
                @include media-breakpoint-down(md) {
                    overflow: visible;
                }
                .owl-stage {
                    @include media-breakpoint-up(lg) {
                        margin: 20px auto;
                    }
                    .owl-item {
                        box-shadow: (-0.4rem / 16) (7rem / 16) (11.3rem / 16) (1.7rem / 16) rgba(0, 0, 0, 0.15);
                        transition: box-shadow 500ms;
                        &:not(.active) {
                            @include media-breakpoint-up(lg) {
                                //Pour éviter de voir une ombre quand la card est hors du champ
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
            .owl-nav {
                width: 100%;
                position: absolute;
                height: 100%;
                z-index: -1;
                top: (-20rem / 16);
                /* Gestion des flèches "noires" 
					*  By default black arrows
					* for white arrows see below 
					*/
                button[class*=owl-] {
                    @include useFont(roboto, 900);
                    font-size: (112rem / 16);
                    &.disabled {
                        display: none;
                    }
                    &:hover {
                        background: none;
                        color: #212529;
                    }
                }
                button {
                    height: 100%;
                    &.owl-prev {
                        float: left;
                        margin-left: (-35rem / 16);
                    }
                    &.owl-next {
                        margin-right: (-35rem / 16);
                        float: right;
                    }
                }
            }
        }
        /* Gestion des flèches "blanches" - 
			* white arrows
			* SASS Grandparent selectors
			* https://codeburst.io/how-to-do-sass-grandparent-selectors-b8666dcaf961
			*/
        &.arrow-white {
            .owl-nav {
                button[class*=owl-] {
                    color: white;
                    &:hover {
                        color: white;
                    }
                }
            }
        }
    }
    .col {
        h2 {
            p {
                margin-bottom: 0;
            }
        }
    }
}

//Styles pour fonctionner dans Internet Explorer
.is-ie {
    .conteneur-galerie {
        .col-lg-3 {
            //Flex-basis par défaut est différent en IE, voir https://github.com/philipwalton/flexbugs/issues/58
            flex: 1 0 auto;
        }
        .col-lg-9 {
            flex: 1 0 auto;
            .owl-stage {
                display: flex;
                .card {
                    height: 100%;
                    h4 {
                        margin: (10rem / 16) 0;
                    }
                    .card-body {
                        flex: 1 0 auto;
                    }
                }
            }
        }
    }
}
@charset "utf-8";
@include media-breakpoint-down(sm) {
    .lqZoneWrapper {
        margin-top: 2px;
        position: relative;
        width: 100%;
        #lqZoneLogoPrincipal {
            img {
                padding-top: (22.4rem / 16);
                margin-left: (11rem / 16);
                width: (132rem / 16);
                &.lazyload {
                    visibility: hidden;
                }
            }
        }
        #lqZoneSecondaire {
            position: absolute;
            right: 0;
            top: 2px;
            #lqBoutonMenuNavigation {
                height: 34px;
                width: 34px;
                a {
                    border: 1px solid transparent;
                    color: #313a45;
                    display: block;
                    padding: 12px 0;
                    line-height: 34px;
                    text-align: center;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 0.75em;
                    height: 100%;
                    width: 100%;
                    .iconeMenu {
                        padding-left: (3rem / 16);
                        padding-top: (5rem / 16);
                        &:hover {
                            background-color: #A9D0F5;
                        }
                        .lqMenuSeparateur {
                            border-top: 3px solid #233039;
                            width: 26px;
                            padding-bottom: 5px;
                        }
                    }
                    .iconeMenuFermer {
                        display: none;
                        background-color: #dce3e9;
                        font-size: (24rem / 16);
                        text-transform: none;
                        &:hover {
                            background-color: #A9D0F5;
                        }
                    }
                }
            }
        }
    }
    
    #lqSousMenu {
      display: none !important;
    }
    #lqZoneMenuComplet {
        display: none;
        padding: 0.5rem 0;
        &.menuVisibleMobile {
            display: block;
        }
        #lqOnglets {
            #lqZoneMenuCompletOnglets {
                background-color: #dce3e9;
                padding: 0;
                .lqZoneMenuOngletNiveau1 {
                    font-size: (16rem / 16);
                    min-height: (40rem / 16);
                    line-height: (40rem / 16);
                    border: 1px dotted #808080;
                    &:not(.premierOnglet) {
                        border-bottom: none;
                    }
                    button,
                    input[type="submit"],
                    input[type="reset"] {
                        border: none;
                        padding: 0;
                        font: inherit;
                        outline: inherit;
                    }
                    .lqSansFleche,
                    .lqFlecheSubMenu {
                        float: left;
                        width: (40rem / 16);
                        height: 40px;
                        border-right: 1px dotted #808080;
                        margin-right: (10rem / 16);
                    }
                    button.lqFlecheSubMenu {
                        background-color: #b7c3cc;
                        &:hover {
                            background-color: #EEF3F6;
                        }
                    }
                    .lqFlecheSubMenu::after {
                        content: "+";
                        display: inline-block;
                        height: (22rem / 16);
                        width: (22rem / 16);
                        border: 1px solid black;
                        background-color: #dce3e9;
                        border-radius: 50%;
                        font-size: (28rem / 16);
                        line-height: (14rem / 16);
                        vertical-align: text-top;
                        margin-left: (-3rem / 16);
                    }
                    .lqFlecheSubMenu.iconeActuelle::after {
                        content: "-";
                        font-size: (39rem / 16);
                        line-height: (11rem / 16);
                    }
                    a {
                        background-color: transparent;
                        display: block;
                        height: 40px;
                        width: 100%;
                        font-size: (16rem / 16);
                        font-weight: 400;
                    }
                    a:hover {
                        background-color: #EEF3F6;
                    }
                    .menuPetit {
                        padding-left: 0;
                        background-color: #b7c3cc;
                        a {
                            font-size: (15rem / 16);
                        }
                        .lqZoneLienOnglet {
                            border-top: 1px dotted #808080;
                        }
                        .loggueClientJoueur,
                        .loggueClientParticipant,
                        .loggueClientInvite {
                            display: none;
                            &.loggue {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

//Pour compatibilité avec Internet Explorer
.is-ie {
    #lqZoneMenuComplet {
        #lqOnglets {
            #lqZoneMenuCompletOnglets {
                .lqZoneMenuOngletNiveau1 {
                    .lqFlecheSubMenu::after {
                        padding-top: (7rem / 16);
                    }
                }
            }
        }
    }
}
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lottod .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
    height: 85%;
    left: 2%;
    top: 9%;
 }

 .resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral.lottod > .numeros {
    text-align: center;
}

.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral.lottod .remorque .numeros {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
}
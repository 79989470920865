@charset "utf-8";
$enable-responsive-font-sizes: true;
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 2vw;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((0: 0, 1: ($spacer * 0.25), 2: ($spacer * 0.5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3), 6: ($spacer * 5), 7: ($spacer * 7.5)), $spacers);
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (all:0, xxs: 270px, xs: 321px, sm: 576px, md: 768px, lg: 1200px, xl: 1460px) !default;
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (xxs: 320px, xs: 440px, sm: 540px, md: 720px, lg: 1170px, xl: 1400px) !default;
            .avis-groslots {
                border-bottom: dashed 0.06rem #9a9a9a;
                padding-top: 10px;
                padding-bottom: 10px;
                height: 255px;
                display: flex;
                @include media-breakpoint-only(xs) {
                    height: 180px;
                }
                .classique.texteClassique {
                    color: red;
                    font-size: 0.5rem;
                }
                a,
                a:hover {
                    text-decoration: none;
                    color: #181818;
                    width: 100%;
                    .media {
                        display: flex;
                        @include media-breakpoint-only(xxs) {
                            display: none;
                        }
                    }
                    .media-xxs {
                        display: flex;
                        padding-left: 25px;
                        padding-right: 25px;
                        padding-top: 0;
                        padding-bottom: 10px;
                        @include media-breakpoint-up(xs) {
                            display: none;
                        }
                    }
                    .prod223 p.groslot-valeur {
                        color: #135fa7;
                    }
                    .prod223 p.groslot-plus {
                        color: #5b940e;
                        padding-left: 28px;
                        background-image: url('../images/ic_plus_maxi_millions.png');
                        background-repeat: no-repeat;
                        background-position-y: center;
                        background-position-x: 10px;
                        background-size: (13rem/16);
                    }
                    .prod212 p.groslot-valeur,
                    .prod212 p.groslot-plus {
                        color: #052e52;
                    }
                    .tirage {
                        .section-1 {
                            .superTirage {
                                /*
                                color: #e6292a;
                                text-transform: uppercase;
                                font-size: 1.25em;
                                */
                                display: none;
                                position: relative;
                                top: -1.5em;
                                width: 71%;
                                .plus {
                                    font-weight: 800;
                                }
                                .super {
                                    font-style: italic;
                                    font-weight: 800;
                                }
                                .tirage {
                                    font-weight: 800;
                                }
                                @include media-breakpoint-only(xxs) {
                                    display: inline;
                                    width: 35%;
                                    margin-bottom: -1rem;
                                    top: 0px;
                                    right: (8rem / 16);
                                }
                                @include media-breakpoint-only(xs) {
                                    top: -1.3em;
                                }
                            }
                        }
                        @include useFont(gordita);
                        img.imgproduit {
                            width: 100%;
                            @include media-breakpoint-only(xl) {
                                width: 90%;
                            }
                            @include media-breakpoint-only(xxs) {
                                width: 40%;
                                float: left;
                            }
                        }
                        .date {
                            @include useFont(gordita,
                            500);
                            font-weight: 500;
                            font-style: normal;
                            font-stretch: normal;
                            text-align: left;
                            text-transform: uppercase;
                            font-size: (14rem / 16);
                            @include media-breakpoint-only(xxs) {
                                float: right;
                                width: 60%;
                                font-size: (9rem / 16);
                                height: 3rem;
                                display: flex;
                                align-items: end;
                            }
                            @include media-breakpoint-only(xs) {
                                font-size: (10rem / 16);
                            }
                            p {
                                padding-left: 22px;
                                padding-right: 10px;
                                background-image: url('../images/svg/ic_temps.svg');
                                background-repeat: no-repeat;
                                background-position-y: 0.2rem;
                                margin-bottom: 5px;
                                padding-top: 2px;
                                padding-bottom: 2px;
                                margin-left: (2rem / 16);
                                margin-top: (2rem / 16);
                                @include media-breakpoint-up(xxs) {
                                    background-size: 18px 18px; //Il faut préciser pour que ca fonctionne avec ie
                                }
                                @include media-breakpoint-up(md) {
                                    background-size: 20px 20px; //Il faut préciser pour que ca fonctionne avec ie
                                }
                                @include media-breakpoint-only(xs) {
                                    background-position-y: 0;
                                }
                            }
                        }
                        .groslot-info-wrap {
                            margin-top: 0.5rem;
                            @include media-breakpoint-up(md) {
                                margin-top: 0px;
                            }
                            @include media-breakpoint-down(sm) {
                                margin-top: (-4rem / 16);
                            }
                            @include media-breakpoint-only(xxs) {
                                float: left;
                                width: 100%;
                                margin-top: -10px;
                            }
                        }
                        .groslot-info {
                            width: 100%;
                            margin-top: 10px;
                            margin-bottom: -10px;
                            text-align: left;
                            text-transform: uppercase;
                            @include media-breakpoint-only(xs) {
                                margin-top: 5px;
                            }
                            @include media-breakpoint-only(xxs) {
                                text-align: center;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                            sup {
                                font-weight: normal;
                            }
                            p {
                                margin-bottom: 0px;
                            }
                            .superTirage {
                                position: absolute;
                                width: 15%;
                            }
                            p.groslot-valeur {
                                @include useFont(gordita,
                                800);
                                font-size: (32rem / 16);
                                padding-top: 5px;
                                line-height: 1.15;
                                @include media-breakpoint-only(xxs) {
                                    /* @include font-size(26rem / 16); */
                                    font-size: (26rem / 16);
                                }
                                @include media-breakpoint-only(xs) {
                                    font-size: (22rem / 16);
                                }
                                @include media-breakpoint-up(md) {
                                    font-size: (36rem / 16);
                                }
                            }
                            p.groslot-plus {
                                //display   : inline;
                                //text-align: left;
                                font-size: (20rem / 16);
                                font-weight: bold;
                                line-height: 1.7;
                                @include media-breakpoint-only(xxs) {
                                    font-size: (14rem / 16);
                                    float: left;
                                    margin-left: 18px;
                                }
                                @include media-breakpoint-only(xs) {
                                    font-size: (13rem / 16);
                                }
                                @include media-breakpoint-up(md) {
                                    font-size: (22rem / 16);
                                    line-height: 1.55;
                                }
                                @include media-breakpoint-only(lg) {
                                    font-size: (20rem / 16);
                                }
                            }
                            div.classique {
                                font-weight: bold;
                                color: #ec292a;
                                .texteClassique {
                                    font-size: (9rem / 16);
                                    margin-left: 0.5rem;
                                    @include media-breakpoint-up(lg) {
                                        font-size: (12rem / 16);
                                    }
                                    @include media-breakpoint-only(md) {
                                        font-size: 1.5vw;
                                    }
                                    @include media-breakpoint-only(sm) {
                                        font-size: 1.75vw;
                                    }
                                    @include media-breakpoint-only(xs) {
                                        font-size: 1.75vw;
                                    }
                                }
                                .montantClassique {
                                    font-size: (12rem / 16);
                                    @include media-breakpoint-up(lg) {
                                        font-size: 1.5rem;
                                    }
                                    @include media-breakpoint-only(md) {
                                        font-size: 2.25vw;
                                    }
                                    @include media-breakpoint-only(sm) {
                                        font-size: 2.5vw;
                                    }
                                    @include media-breakpoint-only(xs) {
                                        font-size: 2.75vw;
                                    }
                                }
                            }
                            div.bouleOr {
                                display: flex;
                                flex-direction: row;
                                column-gap: 0.5rem;
                                align-items: center;
                                font-weight: bold;
                                @include media-breakpoint-down(sm) {
                                    column-gap: 0rem;
                                }
                                img.bouleOrImg {
                                    width: 10.5%;
                                    @include media-breakpoint-only(xl) {
                                        width: 4.2vw;
                                    }
                                    @include media-breakpoint-up(lg) {
                                        width: 4.9vw;
                                    }
                                    @include media-breakpoint-only(md) {
                                        width: 15%;
                                    }
                                    @include media-breakpoint-only(sm) {
                                        width: 15%;
                                    }
                                    @include media-breakpoint-only(xs) {
                                        width: 18%;
                                    }
                                    @include media-breakpoint-only(xxs) {
                                        width: 25%;
                                    }
                                }
                                .lqZoneDetailBouleOr {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: left;
                                    align-items: center;
                                    text-align: left;
                                    .texteBouleOr {
                                        color: #8B6B21;
                                        /* color: gold; */
                                        width: 100%;
                                        font-size: 1.25vw;
                                        @include media-breakpoint-up(lg) {
                                            font-size: 1rem;
                                            margin-bottom: -0.7rem;
                                        }
                                        @include media-breakpoint-only(md) {
                                            margin-bottom: -0.4rem;
                                            font-size: 2.25vw;
                                        }
                                        @include media-breakpoint-only(sm) {
                                            font-size: 2.5vw;
                                            margin-bottom: -0.6rem;
                                        }
                                        @include media-breakpoint-only(xs) {
                                            font-size: 2.75vw;
                                            margin-bottom: -0.5rem;
                                        }
                                        @include media-breakpoint-only(xxs) {
                                            font-size: (12rem / 16);
                                            margin-bottom: -0.3rem;
                                        }
                                    }
                                    .pglBouleOr {
                                        color: #B48B2B;
                                        /* color: gold; */
                                        font-size: 3.1vw;
                                        margin-bottom: -0.4rem;
                                        @include media-breakpoint-up(lg) {
                                            font-size: 2rem;
                                        }
                                        @include media-breakpoint-only(md) {
                                            font-size: 3.75vw;
                                        }
                                        @include media-breakpoint-only(sm) {
                                            font-size: 4.75vw;
                                        }
                                        @include media-breakpoint-only(xs) {
                                            font-size: 4.75vw;
                                        }
                                        @include media-breakpoint-only(xxs) {
                                            font-size: 1.25rem;
                                        }
                                    }
                                }
                            }
                        }
                        .groslot-boutons-jouer {
                            margin-top: 15px;
                            @include media-breakpoint-only(xs) {
                                margin-top: 10px;
                            }
                            @include media-breakpoint-only(xxs) {
                                clear: both;
                                display: block;
                                font-size: (12rem / 16);
                            }
                        }
                        div.groslot-approx {
                            display: flex;
                            font-size: (11rem / 16);
                            @include media-breakpoint-only(xxs) {
                                margin-bottom: (10rem / 16);
                                padding-top: 1rem;
                            }
                            @include media-breakpoint-only(xs) {
                                font-size: (10rem / 16);
                            }
                            @include media-breakpoint-up(md) {
                                font-size: (14rem / 16);
                                margin-right: 80px;
                            }
                            @include media-breakpoint-only(lg) {
                                margin-right: 15px;
                                padding-right: 0;
                            }
                            p {
                                margin-bottom: 0;
                                text-align: right;
                                width: 100%;
                                @include media-breakpoint-only(xxs) {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
                .texte-bouton {
                    @include media-breakpoint-down(xs) {
                        margin: (3rem / 16) (5rem / 16) (10rem / 16);
                        font-size: (11.5rem / 16);
                        padding-left: (36rem / 16);
                        padding-right: (35rem / 16);
                        padding-top: (12rem / 16);
                        padding-bottom: (11rem / 16);
                    }
                }
            }
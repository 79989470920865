@charset "utf-8";

/**/


/*********************************************************************************************
* Surcharge des styles natifs de Bootstrap
* https://getbootstrap.com/docs/4.3/components/card/
*
**********************************************************************************************/

//Fonctionnel commun à toutes les cards
.card {
    .btnContainer {
        box-shadow: 0 0 13.9px 2.1px rgba(39, 39, 39, 0.29);
        width: fit-content;
        border-radius: (26rem / 16);
        display: table; //correction bug dans edge et ie
        @include media-breakpoint-only(xxs) {
            font-size: (10rem / 16);
        }
    }
    .btn {
        width: fit-content;
        &.btn-color {
            //Pour faire apparaitre le background du conteneur en tant que bordure du bouton
            box-shadow: none;
            z-index: 2;
            background-clip: padding-box;
            border: solid 3px transparent;
            &:not(:hover),
            &:not(:focus) {
                background-color: rgb(255, 255, 255);
                color: rgb(0, 0, 0);
            }
            &:hover,
            &:focus {
                background-image: none;
                background-color: transparent;
                color: rgb(255, 255, 255);
            }
        }
    }
}

//Cartes des galeries seulement
.card.galerie {
    border: none;
    border-radius: 0;
    //Champ surtitre
    .h3 {
        &.card-title {
            font-weight: 500;
            font-size: 4vw; //(13.44rem / 16);
            @include media-breakpoint-only(xxs) {
                font-size: (11.5rem / 16);
            }
            @include media-breakpoint-only(md) {
                font-size: 2vw; //(13.44rem / 16);
            }
            @include media-breakpoint-up(lg) {
                font-size: (13.44rem / 16); //0.8vw;
            }
            margin: 0;
        }
    }
    //Champ titre
    .h4 {
        text-transform: uppercase;
        font-size: 4.2vw; //(15.12rem / 16);
        margin-top: 2%;
        &.card-title {
            margin: 0;
            @include media-breakpoint-only(xs) {
                margin-top: -10px;
            }
            @include media-breakpoint-only(sm) {
                margin-top: -15px;
            }
        }
        @include media-breakpoint-only(xxs) {
            font-size: (13.4rem / 16);
        }
        @include media-breakpoint-only(md) {
            font-size: 2vw; //font-size: (15.12rem / 16);
            line-height: 1.5;
        }
        @include media-breakpoint-only(lg) {
            font-size: (10rem / 16); //LSWI-689
            line-height: 1.5;
        }
        @include media-breakpoint-up(xl) {
            font-size: (14rem / 16);
            line-height: 1.5;
        }
    }
    .btn {
        white-space: nowrap;
        overflow: hidden;
    }
    //Bouton pour le lien de la card
    .btnContainer {
        @include media-breakpoint-up(lg) {
            border-radius: (20rem / 16);
        }
        @include media-breakpoint-up(xl) {
            border-radius: (25rem / 16);
        }
        .btn {
            @include media-breakpoint-up(lg) {
                font-size: 0.9vw;
                min-height: (40rem / 16);
            }
            @include media-breakpoint-up(xl) {
                min-height: (46rem / 16);
            }
            @include media-breakpoint-down(xs) {
                font-size: (12rem / 16);
            }
            @include media-breakpoint-down(sm) {
                padding-top: (10rem / 16);
                padding-bottom: (10rem / 16);
            }
            &.texte-bouton {
                @include media-breakpoint-only(xxs) {
                    padding-left: (15rem / 16);
                    padding-right: (15rem / 16);
                }
            }
        }
    }
    //Texte en overlay sur card
    .card-img-overlay {
        /* surcharge de bootstrap*/
        top: 50%;
        color: white;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include media-breakpoint-between(all, xs) {
            padding: (10rem / 16) (13rem / 16);
        }
        @include media-breakpoint-up(lg) {
            padding: (10rem / 16) (20rem / 16);
        }
        @include media-breakpoint-up(xl) {
            padding: (10rem / 16) (9rem / 16);
        }
    }
    //Boite de texte sans overlay
    .card-body {
        width: 100%;
        color: #000000;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include media-breakpoint-between(all, xs) {
            padding: (10rem / 16) (15rem / 16);
        }
        @include media-breakpoint-up(lg) {
            padding: (10rem / 16) (10rem / 16);
        }
        .h3 {
            @include media-breakpoint-only(xxs) {
                min-height: (36rem / 16);
            }
        }
        .h4 {
            @include media-breakpoint-only(xxs) {
                font-size: (13.4rem / 16);
                padding-top: (9rem / 16);
                min-height: (40rem / 16);
            }
            @include media-breakpoint-up(lg) {
                min-height: (35rem / 16);
            }
        }
        .btnContainer {
            box-shadow: 5px 5px 13.9px 2.1px rgba(39, 39, 39, .29);
        }
    }
    //Image de la carte overlay
    img.card-img {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    //Image de la carte normale
    img.card-img-top {
        width: 100%;
        border-radius: 0;
    }
    //Conteneur de l'image lorsque carrée
    &.squared-img {
        height: 100%;
        width: 100%;
        margin: auto;
    }
    //Pour les cards normales rectangulaires
    &:not(.squared-img) {
        height: 85vw;
        @include media-breakpoint-only(md) {
            height: 44.5vw;
        }
        @include media-breakpoint-up(lg) {
            height: 18.6vw;
        }
    }
}

.is-ie {
    .card.galerie {
        .btnContainer {
            margin-top: 1em;
            .btn {
                @include media-breakpoint-up(xl) {
                    min-height: (40rem / 16);
                }
            }
        }
    }
    .card.carrousel {
        div.card-img-overlay {
            .h4 {
                @include media-breakpoint-up(lg) {
                    position: relative;
                    top: 0;
                }
                @include media-breakpoint-up(xl) {
                    position: relative;
                    top: (85rem / 16);
                }
            }
        }
    }
}

//Cartes pour carrousel seulement
.card.carrousel {
    border: none;
    border-radius: 0;
    &:hover {
        text-decoration: none;
    }
    &.black {
        .card-img-overlay {
            color: white;
            background-color: black;
        }
    }
    &.white {
        .card-img-overlay {
            color: black;
            background-image: linear-gradient(346deg, #f1f1f1, white);
        }
    }
    &.transparent {
        .card-img-overlay {
            //color: transparent;
            color: #000000;
            //background-image: linear-gradient(346deg, #f1f1f1, white);
            background-image: none;
            box-shadow: none;
            @include media-breakpoint-up(lg) {
                background-image: none;
            }
            .progress-bar {
                display: none;
            }
        }
    }
    .card-img {
        height: (230rem / 16);
        width: 140%;
        @include media-breakpoint-up(md) {
            height: (400rem / 16);
        }
        @include media-breakpoint-up(lg) {
            height: (420rem / 16);
            width: 100%;
        }
        @include media-breakpoint-up(xl) {
            height: (485rem / 16);
        }
        object-fit: cover;
        font-family: "object-fit: cover";
        &.imagemobile {
            display: block;
            width: 100%;
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
        &.imagedesktop {
            display: none;
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }
    div.card-img-overlay {
        box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.35);
        height: (166rem / 16);
        position: relative;
        text-align: left;
        padding: (15rem / 16);
        @include media-breakpoint-between(all, xs) {
            height: (100rem / 16);
            padding: 0 0 0 10px;
        }
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
            height: auto;
            position: absolute;
            left: 61.5%;
            bottom: (46rem / 16);
            margin-top: 10px;
            width: (400rem / 16);
            padding: (134rem / 16) (52rem / 16) (30rem / 16);
        }
        @include media-breakpoint-up(xl) {
            width: (454rem / 16);
        }
        .h3 {
            position: absolute;
            bottom: (90rem / 16);
            max-width: 57%;
            margin: 0;
            font-size: (21rem / 16);
            line-height: 1.2;
            @include media-breakpoint-between(all, xs) {
                position: unset;
                padding-top: (14rem / 16);
                font-size: .85rem;
                line-height: (15rem/ 16);
                max-width: 59%;
            }
            @include media-breakpoint-up(sm) {
                font-size: (25rem / 16);
                max-width: 59%;
            }
            @include media-breakpoint-up(md) {
                font-size: (28rem / 16);
                max-width: 59%;
            }
            @include media-breakpoint-up(lg) {
                bottom: (230rem / 16);
                font-size: (32rem / 16);
                min-height: (40rem / 16);
                width: (296rem / 16);
                max-width: none;
            }
            @include media-breakpoint-up(xl) {
                position: unset;
                bottom: (225rem / 16);
                width: (350rem / 16);
            }
        }
        .abs {
            position: relative;
            margin: 0;
            text-transform: none;
            @include media-breakpoint-between(all, xs) {
                padding-top: (5rem / 16);
                font-size: .8rem;
                line-height: (11rem/ 16);
                max-width: 59%;
            }
            @include media-breakpoint-up(sm) {
                font-size: (18rem / 16);
            }
            @include media-breakpoint-up(md) {
                font-size: (20rem / 16);
                line-height: 1.05;
            }
            @include media-breakpoint-up(lg) {
                top: (21rem / 16);
            }
            @include media-breakpoint-up(xl) {
                width: (350rem / 16);
            }
        }
        .h4 {
            position: absolute;
            top: (69rem / 16);
            max-width: 57%;
            margin: 0;
            text-transform: none;
            @include useFont(roboto);
            font-size: (16rem / 16);
            line-height: 1.17;
            @include media-breakpoint-between(all, xs) {
                position: unset;
                font-size: .8rem;
                line-height: (12rem/ 16);
            }
            @include media-breakpoint-up(sm) {
                font-size: (18rem / 16);
                max-width: 58%;
            }
            @include media-breakpoint-up(md) {
                font-size: (20rem / 16);
                line-height: 1.05;
                max-width: 59%;
            }
            @include media-breakpoint-up(lg) {
                position: relative;
                top: 0;
                width: (296rem / 16);
                font-size: (21rem / 16);
                line-height: 1.24;
            }
        }
        .btnContainer {
            position: absolute;
            left: 67%;
            top: 40%;
            height: fit-content;
            margin: auto 0 auto auto;
            @include media-breakpoint-up(xxs) {
                top: 30%;
                max-width: 58%;
            }
            @include media-breakpoint-up(xs) {
                top: 30%;
                line-height: (11rem / 16);
                left: 65%;
                max-width: 58%;
            }
            @include media-breakpoint-up(sm) {
                left: 62%;
                max-width: 58%;
            }
            @include media-breakpoint-up(md) {
                left: 62%;
                max-width: 58%;
            }
            @include media-breakpoint-up(lg) {
                top: auto;
                bottom: (50rem / 16);
                left: (52rem / 16);
                max-width: (296rem / 16);
            }
            @include media-breakpoint-up(xl) {
                max-width: (350rem / 16);
            }
            .btn {
                padding: 0;
                line-height: 1.2;
                @include media-breakpoint-only(xxs) {
                    font-size: 0.5rem;
                    letter-spacing: normal;
                    max-width: (91rem / 16);
                    padding-left: (4.2rem / 16);
                    padding-right: (4.2rem / 16);
                }
                @include media-breakpoint-only(xs) {
                    font-size: .55rem;
                    letter-spacing: (0.7rem / 16);
                    max-width: (120rem / 16);
                }
                @include media-breakpoint-up(sm) {
                    max-width: (20vw);
                    padding: (4rem / 16) (12rem / 16);
                }
                @include media-breakpoint-up(md) {
                    max-width: (35vw);
                    padding: (4rem / 16) (32rem / 16);
                }
                @include media-breakpoint-up(xl) {
                    max-width: (20vw);
                }
                &.btn-color {
                    background-image: none;
                    background-color: transparent;
                }
            }
        }
        .progress {
            height: 2%;
            position: absolute;
            left: 0;
            top: 98%;
            width: 100%;
            border-radius: 0;
            background-color: transparent;
            .progress-bar {
                -webkit-transition: none;
                -moz-transition: none;
                -ms-transition: none;
                -o-transition: none;
                transition: none;
                @keyframes fillBar {
                    0% {
                        width: 0;
                    }
                    100% {
                        width: 100%;
                    }
                }
            }
        }
    }
    &.black {
        .card-img-overlay {
            .btn {
                &.btn-color {
                    color: black;
                }
            }
        }
    }
    &.white {
        .card-img-overlay {
            .btn {
                &.btn-color {
                    color: white;
                }
            }
        }
    }
}

//Cartes pour gagnants seulement LSWI-1076
.card.gagnant {
    border: none;
    border-radius: 0;
    text-align: center;
    //Image de la carte normale
    img.card-img-top {
        width: 100%;
        border-radius: 0;
    }
    .card-body {
        width: 100%;
        color: #000000;
        padding: 0.25rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        height: 8rem;
        @include media-breakpoint-up(xs) {
            height: 11rem;
        }
        @include media-breakpoint-up(lg) {
            margin-top: (6rem /16);
            margin-bottom: (6rem /16);
            height: (100rem /16);
        }
        @include media-breakpoint-up(xl) {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        &.prizeAmountOne {
            @include media-breakpoint-up(xs) {
                padding-top: 2rem;
            }
            @include media-breakpoint-up(lg) {
                padding-top: 1rem;
            }
        }
        &.prizeAmountTwo {
            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }
        //Champ Nom gagnant
        //Champ Date
        .h3,
        p.prizeDate {
            &.card-title {
                @include useFont(robotoMedium);
                font-size: 4vw;
                font-weight: bold;
                margin: 0;
                line-height: 1.3;
                @include media-breakpoint-only(md) {
                    font-size: 2vw;
                }
                @include media-breakpoint-up(lg) {
                    font-size: (13.44rem / 16);
                }
            }
        }
        //Champ Montant du Lot
        .prizeAmount {
            @include useFont(gordita, 800);
            text-transform: uppercase;
            font-size: 150%;
            margin-bottom: 0;
            color: #063e3c;
            @include media-breakpoint-up(lg) {
                font-size: 1.5vw;
            }
            @include media-breakpoint-up(xl) {
                font-size: (25rem / 16);
                line-height: 1.2;
                margin-bottom: 0;
            }
            &.card-title {
                margin: 0;
                @include media-breakpoint-only(xs) {
                    margin-top: -10px;
                }
                @include media-breakpoint-only(sm) {
                    margin-top: -15px;
                }
            }
        }
    }
}

.media-card-image {
    border: none;
    border-radius: 0;
    background: none;
    .bg-img {
        width: 100%;
    }
    .mobile-bas {
        @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: column-reverse !important;
        }
    }
    .col-md-6 {
        @include media-breakpoint-down(md) {
            margin-top: 1rem;
        }
    }
    .card-body {
        height: 100%;
        border-radius: 15px;
        &.center-vertical {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        h3,
        .h3 {
            font-size: (18rem /16);
            @include media-breakpoint-up(md) {
                font-size: (21rem /16);
            }
            @include media-breakpoint-up(lg) {
                font-size: (28rem /16);
            }
        }
    }
    /*
    .row-normal {
        .card-body {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            background-color: #ffa38b80;
        }
    }
    .flex-row-reverse {
        .card-body {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            background-color: #FFBF3F80;
        }
    }
    */
}
@charset "utf-8";
#lqZonePied {
    margin: 0 auto;
    max-width: 950px;
    width: 100%;
    float: left;
    clear: both;
    background-color: #f8fbfd;
    margin-top: 1px;
    @include media-breakpoint-up(md) {
        float: none;
    }
    @include media-breakpoint-up(lg) {
        width: 1140px;
        max-width: none;
    }
    h2 {
        @include useFont(functionpro, 400);
        text-align: left;
        font-weight: 400;
    }
    a {
        text-decoration: none;
        color: #313946;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    .piedDePageLiensColonne {
        display: block;
        float: left;
        padding-top: 30px;
        padding-bottom: 20px;
        @include media-breakpoint-only(xs) {
            padding-bottom: 0;
            padding-top: 0;
        }
    }
    #piedDePageLiensRapides {
        display: none;
        background-color: #fff;
        @include media-breakpoint-up(sm) {
            display: block;
            width: 42.10526316%;
        }
        h2 {
            text-transform: uppercase;
            @include media-breakpoint-up(sm) {
                font-size: 17px;
                line-height: 20px;
                margin-bottom: 20px;
                margin-left: 10.25641026%;
                margin-right: 10.25641026%;
            }
        }
        a {
            font-family: Helvetica, Arial;
            font-weight: bold;
            text-transform: uppercase;
        }
        .piedDePageLienRapide {
            display: table;
            height: auto;
            @include media-breakpoint-up(sm) {
                margin-left: 10.25641026%;
                margin-right: 10.25641026%;
                min-height: 70px;
            }
        }
        img {
            display: none;
            max-width: none;
            @include media-breakpoint-up(sm) {
                display: block;
                width: 40px;
                height: 40px;
            }
            @include media-breakpoint-up(md) {
                width: 70px;
                height: 70px;
            }
        }
        .piedDePageLienRapideDetails {
            display: table-cell;
            vertical-align: top;
            width: 100%;
            text-align: left;
            >div {
                @include media-breakpoint-up(sm) {
                    margin-left: 7.40740741%;
                }
            }
        }
        .piedDePageLienRapideTitre {
            @include media-breakpoint-up(sm) {
                font-size: 12px;
                line-height: 17px;
                margin-bottom: 10px;
            }
        }
        .piedDePageLienRapideTexte {
            display: none;
            font-family: Helvetica;
            @include media-breakpoint-up(md) {
                display: block;
                font-size: 12px;
                line-height: 18px;
            }
        }
        .piedDePageLienRapideSeparateur {
            @include media-breakpoint-up(sm) {
                margin-left: 40px;
            }
            @include media-breakpoint-up(md) {
                margin-left: 70px;
            }
        }
        p.piedDePageSeparateur {
            @include media-breakpoint-up(sm) {
                margin-left: 17.94871795%;
                margin-right: 10.25641026%;
            }
        }
    }
    #piedDePageMenuLiensInfos {
        background-image: url(../images/cadrille_pieddepage.png);
        background-repeat: no-repeat;
        background-position: 20px 10px;
        width: 50.52631579%;
        @include media-breakpoint-up(sm) {
            width: 25.26315789%;
            background-position: 25px 40px;
        }
        @include media-breakpoint-up(md) {
            background-position: 40px 40px;
        }
        @include media-breakpoint-up(lg) {
            background-position: 50px 40px;
        }
        h2 {
            text-transform: uppercase;
            &.piedDePageGroupeDeLiensTitre {
                font-size: 17px;
                margin-left: 8.5106383%;
                margin-top: 10px;
                margin-bottom: 20px;
                @include media-breakpoint-up(sm) {
                    margin-left: 17.39130435%;
                }
            }
        }
        ul {
            margin-left: 8.5106383%;
            margin-bottom: 20px;
            @include media-breakpoint-up(sm) {
                margin-left: 17.39130435%;
            }
            a {
                font-size: 12px;
                @include media-breakpoint-up(sm) {
                    font-size: 14px;
                }
            }
            li {
                margin-right: 4.25531915%;
                @include media-breakpoint-up(sm) {
                    margin-right: 8.69565217%;
                }
            }
            &#piedDePageMenuOnglets {
                li {
                    text-align: left;
                    margin-bottom: 10px;
                    line-height: 20px;
                    @include media-breakpoint-up(sm) {
                        margin-bottom: 10px;
                        line-height: 20px;
                    }
                }
                a {
                    font-family: Arial;
                }
            }
            &#piedDePageLiensInfos {
                a {
                    font-family: Arial;
                }
                li {
                    text-align: left;
                    margin-bottom: 15px;
                    line-height: 19px;
                    @include media-breakpoint-up(sm) {
                        margin-bottom: 15px;
                        line-height: 19px;
                    }
                }
            }
        }
        p.piedDePageAppsTitre {
            @include media-breakpoint-up(sm) {
                margin-left: 3.68421053%;
            }
        }
        img#piedDePageMobile {
            margin-left: 14.89361702%;
            margin-top: 20px;
            height: 30px;
            width: auto;
            @include media-breakpoint-up(sm) {
                margin-left: 30.43478261%;
            }
        }
        .piedDePageSeparateur {
            margin-left: 8.5106383%;
            @include media-breakpoint-up(sm) {
                margin-left: 17.39130435%;
            }
        }
    }
    #piedDePageLiensAutres {
        background-image: url(../images/cadrille_pieddepage.png);
        background-repeat: no-repeat;
        background-position: 0 10px;
        text-align: left;
        padding-bottom: 0;
        width: 45.26315789%;
        min-height: 345px;
        min-width: 121px;
        @include media-breakpoint-up(sm) {
            width: 28.42105263%;
            background-position: 0 40px;
        }
        a {
            font-family: Arial;
            text-transform: uppercase;
            font-size: 12px;
        }
        li {
            margin-right: 4.25531915%;
            margin-bottom: 15px;
            line-height: 17px;
            @include media-breakpoint-up(sm) {
                margin-right: 7.40740741%;
            }
        }
        img.piedDePageAppLogos {
            margin-top: 20px;
            width: 30px;
            height: 30px;
            margin-right: 6px;
        }
        img#piedDePageLogoLoteries {
            width: 100px;
            margin-bottom: 18px;
            margin-top: 4px;
        }
    }
    #piedDePageLogos {
        width: 100%;
        text-align: left;
        padding-top: 0;
        @include media-breakpoint-up(sm) {
            clear: none;
            float: left;
            text-align: left;
            width: 28.42105263%;
        }
        .piedDePageSeparateur {
            margin: 0 4.21052632%;
            @include media-breakpoint-up(sm) {
                margin: 0;
            }
        }
        img.piedDePageLogo {
            margin-left: 4.21052632%;
            margin-top: 15px;
            @include media-breakpoint-up(sm) {
                margin-left: 7.40740741%;
            }
        }
        img#piedDePageLogoLQ {
            width: 35px;
            height: 45px;
        }
        img#piedDePageLogoWLA {
            width: 170px;
            height: 55px;
        }
        img#piedDePageLogoAidejeu {
            width: 100px;
            height: 30px;
            margin-bottom: 15px;
            margin-left: 30px;
        }
    }
    p.copyright {
        clear: both;
        font-family: Helvetica, Arial, Sans-serif;
        text-align: left;
        padding: 0 4.21052632%;
        font-size: 10px;
        line-height: 15px;
        @include media-breakpoint-up(sm) {
            padding-left: 43%;
            padding-right: 0;
        }
    }
    .clipToHide {
        clip: rect(0px, 0px, 0px, 0px)!important;
        width: 0!important;
        height: 0!important;
        position: absolute!important;
        overflow: hidden;
    }
}

p.piedDePageSeparateur {
    background-image: url(../images/fondLigne.png);
    background-repeat: repeat-x;
    background-position: center;
    padding: 10px 0;
}

p.piedDePageAppsTitre {
    display: none;
    position: absolute;
    font-family: Arial;
    text-align: left;
    font-size: 12px;
    line-height: 17px;
    margin-top: -13px;
    @include media-breakpoint-up(md) {
        display: block;
    }
}
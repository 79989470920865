.resultats-recents {



   .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit {
        background-color: $entete-couleur-background;
        height: 15.51vw;

        @include media-breakpoint-up(md) {
            height: 7.75vw;
        }
        @include media-breakpoint-up(lg) {
            height: 114px;
        }
    }

    &.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit {
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
    }

    &.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit {
        align-items: flex-end;
        background-color: initial;
        flex-direction: column-reverse;
        margin: 0;
        padding: 0;
        height: auto;
        justify-content: flex-end;
        width: 60%;

        & .lqZoneBoni,
        & .lqZoneSuperTirage {
            margin: 0;
            margin-right: 20px;
            padding-left: 0;
            text-align: right;
            text-align: right;
            top: -0.5rem;
            position: relative;
        }

        & .date {
            margin-right: 20px;
            margin-top: 20px;
        }
    }

    &.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .logo {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: flex-start;
        padding-bottom: 0;
        width: 40%;

        img {
            height: 70%;
        }
    }


}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes .contenantResultat .lqConteneurGeneral .lqConteneurEnteteProduit {
    height: 20vw;
}
@charset "utf-8";

//**************************************************
//* R O B O T O
//**************************************************/

//**************
//* Normal - 400
//**************
@font-face {
	font-family: 'roboto';
	src: url('../fonts/roboto-v19-latin-regular.woff2') format('woff2'),
		 url('../fonts/roboto-v19-latin-regular.woff')  format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'roboto';
	src: url('../fonts/roboto-v19-latin-italic.woff2') format('woff2'),
		 url('../fonts/roboto-v19-latin-italic.woff')  format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'roboto';
	src: url('../fonts/roboto-v19-latin-700.woff2') format('woff2'),
		 url('../fonts/roboto-v19-latin-700.woff')  format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'roboto';
	src: url('../fonts/roboto-v19-latin-700italic.woff2') format('woff2'),
		 url('../fonts/roboto-v19-latin-700italic.woff')  format('woff');
	font-weight: 700;
	font-style: italic;
}

//**************
//* Normal - 500
//**************
@font-face {
	font-family: 'roboto-medium';
	src: url('../fonts/roboto-v20-latin-medium.woff2') format('woff2'),
		 url('../fonts/roboto-v20-latin-medium.woff')  format('woff');
	font-weight: 500;
	font-style: normal;
}


//**************************************************
//* R O B O T O
//**************************************************/

//**************************************************
//* G O R D I T A
//**************************************************/

//*************
//* Light - 300
//*************
@font-face {
	font-family: 'gordita';
	src: url('../fonts/gordita-light-webfont.woff2') format('woff2'),
		 url('../fonts/gordita-light-webfont.woff')  format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'gordita';
	src: url('../fonts/gordita-lightitalic-webfont.woff2') format('woff2'),
		 url('../fonts/gordita-lightitalic-webfont.woff')  format('woff');
	font-weight: 300;
	font-style: italic;
}

//**************
//* Normal - 400
//**************
@font-face {
	font-family: 'gordita';
	src: url('../fonts/gordita-regular-webfont.woff2') format('woff2'),
		 url('../fonts/gordita-regular-webfont.woff')  format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'gordita';
	src: url('../fonts/gordita-regularitalic-webfont.woff2') format('woff2'),
		 url('../fonts/gordita-regularitalic-webfont.woff')  format('woff');
	font-weight: 400;
	font-style: italic;
}

//**************
//* Medium - 500
//**************
@font-face {
	font-family: 'gordita';
	src: url('../fonts/gordita-medium-webfont.woff2') format('woff2'),
		 url('../fonts/gordita-medium-webfont.woff')  format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'gordita';
	src: url('../fonts/gordita-mediumitalic-webfont.woff2') format('woff2'),
		 url('../fonts/gordita-mediumitalic-webfont.woff')  format('woff');
	font-weight: 500;
	font-style: italic;
}

//************
//* Bold - 700
//************
@font-face {
	font-family: 'gordita';
	src: url('../fonts/gordita-bold-webfont.woff2') format('woff2'),
		 url('../fonts/gordita-bold-webfont.woff')  format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'gordita';
	src: url('../fonts/gordita-bolditalic-webfont.woff2') format('woff2'),
		 url('../fonts/gordita-bolditalic-webfont.woff')  format('woff');
	font-weight: 700;
	font-style: italic;
}

//******************
//* Extra Bold - 800
//******************
@font-face {
	font-family: 'gordita';
	src: url('../fonts/gordita-black-webfont.woff2') format('woff2'),
		 url('../fonts/gordita-black-webfont.woff')  format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'gordita';
	src: url('../fonts/gordita-blackitalic-webfont.woff2') format('woff2'),
		 url('../fonts/gordita-blackitalic-webfont.woff')  format('woff');
	font-weight: 800;
	font-style: italic;
}

//*************
//* Black - 900
//*************
@font-face {
	font-family: 'gordita';
	src: url('../fonts/gordita-ultra-webfont.woff2') format('woff2'),
		 url('../fonts/gordita-ultra-webfont.woff')  format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'gordita';
	src: url('../fonts/gordita-ultraitalic-webfont.woff2') format('woff2'),
		 url('../fonts/gordita-ultraitalic-webfont.woff')  format('woff');
	font-weight: 900;
	font-style: italic;
}

//**************************************************
//* G O R D I T A
//**************************************************/

//**************************************************
//* F U N C T I O N   P R O
//**************************************************/

//*************
//* Medium - 500
//*************

@font-face {
	font-family: 'functionpro';
	src: url('../fonts/functionpro-medium-webfont.woff')  format('woff');
	font-weight: 500;
	font-style: normal;
}

//**************************************************
//* F U N C T I O N   P R O
//**************************************************/
@charset "utf-8";
h1,
.h1 {
    @include useFont(gordita, 900);
    text-align: left;
    letter-spacing: 0px;
    color: #141B4D;
    opacity: 1;
    font-size: (25rem / 16);
    line-height: (34rem / 16);
    margin-top: 3vw;
    @include media-breakpoint-up(md) {
        font-size: (39rem / 16);
        line-height: (51rem / 16);
        margin-top: 0;
        margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
        font-size: (46rem / 16);
        line-height: (72rem / 16);
    }
    @include media-breakpoint-up(xl) {
        line-height: (81rem / 16);
    }
}

h2,
.h2 {
    @include useFont(gordita, 300);
    font-size: (46rem / 16);
    text-transform: uppercase;
    line-height: 1.41;
    @include media-breakpoint-only(xxs) {
        font-size: (26rem / 16);
        line-height: 1.07;
    }
    @include media-breakpoint-only(xs) {
        font-size: (28rem / 16);
        line-height: 1.07;
    }
    @include media-breakpoint-only(sm) {
        font-size: (38rem / 16);
        line-height: 1.11;
    }
    @include media-breakpoint-only(md) {
        font-size: (42rem / 16);
        line-height: 1.14;
    }
    .subTitle,
    .emphasis {
        font-weight: 800;
    }
}

h3,
.h3 {
    @include useFont(gordita, 700);
    font-size: (39rem / 16);
    line-height: (48rem / 16);
    @include media-breakpoint-only(xxs) {
        font-size: (26rem / 16);
        line-height: 1.23;
    }
    @include media-breakpoint-only(xs) {
        font-size: (26rem / 16);
        line-height: 1.23;
    }
    @include media-breakpoint-only(sm) {
        font-size: (32rem / 16);
        line-height: 1.56;
    }
    @include media-breakpoint-only(md) {
        font-size: (36rem / 16);
        line-height: 1.39;
    }
    @include media-breakpoint-up(lg) {
        line-height: 1.2;
    }
}

h4,
.h4 {
    @include useFont(gordita, 700);
    font-size: (28rem / 16);
    text-transform: uppercase;
    line-height: (48rem / 16);
    letter-spacing: (1.1rem / 16);
    @include media-breakpoint-only(xxs) {
        font-size: (16rem / 16);
        line-height: 1.15;
        letter-spacing: (0.6rem / 16);
    }
    @include media-breakpoint-only(xs) {
        font-size: (20rem / 16);
        line-height: 1.2;
        letter-spacing: (0.8rem / 16);
    }
    @include media-breakpoint-only(sm) {
        font-size: (24rem / 16);
        line-height: 1.25;
        letter-spacing: (1rem / 16);
    }
    @include media-breakpoint-only(md) {
        font-size: (28rem / 16);
        line-height: 1.71;
    }
}

h5,
.h5 {
    @include useFont(gordita, 700);
    font-size: (18rem / 16);
    line-height: (21rem / 16);
    letter-spacing: 0.78px;
    @include media-breakpoint-only(xs) {
        font-size: (18rem / 16);
        line-height: 1.33;
        letter-spacing: (0.7rem / 16);
    }
    @include media-breakpoint-only(sm) {
        font-size: (22rem / 16);
        line-height: 1.5;
        letter-spacing: (0.9rem / 16);
    }
    @include media-breakpoint-only(md) {
        font-size: (26rem / 16);
        line-height: 1.54;
        letter-spacing: (1rem / 16);
    }
}

h6,
.h6 {
    @include useFont(gordita, 700);
    font-size: (16rem / 16);
    line-height: (24rem / 16);
    text-transform: uppercase;
    letter-spacing: (0.7rem / 16);
    @include media-breakpoint-only(xxs) {
        font-size: (11rem / 16);
        line-height: 1.13;
        letter-spacing: (0.6rem / 16);
    }
    @include media-breakpoint-only(xs) {
        font-size: (11rem / 16);
        line-height: 1.13;
        letter-spacing: (0.6rem / 16);
    }
    @include media-breakpoint-only(sm) {
        font-size: (16rem / 16);
        line-height: 1.13;
        letter-spacing: (0.6rem / 16);
    }
    @include media-breakpoint-only(md) {
        font-size: (18rem / 16);
        line-height: 1;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    .sp-first {
        @include useFont(gordita, 400);
        @include media-breakpoint-up(sm) {
            font-size: (32rem / 16);
        }
        text-transform: uppercase;
    }
    .sp-last {
        @include useFont(gordita, 700);
        @include media-breakpoint-up(sm) {
            font-size: (32rem / 16);
        }
        text-transform: uppercase;
    }
    .sp-subTitle {
        @include useFont(gordita, 400);
        font-size: (21rem / 16);
        text-transform: none;
        @include media-breakpoint-only(xxs) {
            font-size: (14rem / 16);
        }
    }
}

h2,
.h2 {
    .underline {
        margin-top: (22rem / 16);
        @include media-breakpoint-up(lg) {
            width: (98rem / 16);
            height: (8rem / 16);
            background-color: $body-color;
        }
    }
    &.text-center {
        .underline {
            margin: auto;
        }
    }
}
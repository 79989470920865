@charset "utf-8";

/**/


/*********************************************************************************************
* Reprise de _lq.carousel.scss
*
*
**********************************************************************************************/

html {
    //Pour permettre au carrousel de tenir compte de la barre de défilement dans le calcul de largeur
    //https   ://github.com/angular-ui/ui-grid/issues/2497#issuecomment-75446394
    overflow-y: scroll;
}

.fn-carousel.carousel.owl-carousel {
    .owl-dots {
        justify-content : center;
        display         : block;
        position        : relative;
        background-color: rgba(255, 255, 255, 0.6);
        height          : (21rem / 16);
        width           : 100%;
        margin-top      : (-187rem / 16);
        margin-bottom   : (166rem / 16);

        @include media-breakpoint-between(all, xs) {
            margin-top   : (-120rem / 16) !important;
            margin-bottom: (120rem / 16);
        }

        @include media-breakpoint-up(lg) {
            display      : flex;
            height       : (35rem / 16);
            margin-top   : (-35rem / 16) !important;
            margin-bottom: 0;
        }

        &::after {
            content: '';
            display: none;

            @include media-breakpoint-up(lg) {
                display         : block;
                position        : absolute;
                background-color: rgba(255, 255, 255, 0.6);
                height          : (35rem / 16);
                width           : calc(62.6% - 400px); // Ratio = (Largeur à partir du début du texte) / (Largeur avant le texte)
                left            : calc(100% + 400px);
            }

            @include media-breakpoint-up(xl) {
                width: calc(62.6% - 454px);
                left : calc(100% + 454px);
            }
        }

        .owl-dot {
            height: 100%;

            span {
                margin          : auto (5rem / 16);
                background-color: white;
                border-radius   : 50%;
                border          : 1px black solid;
                height          : (14rem / 16);
                width           : (14rem / 16);
                transition      : background-color 200ms ease;

                @include media-breakpoint-up(lg) {
                    border: 2px black solid;
                    height: (18rem / 16);
                    width : (18rem / 16);
                }

                &:hover,
                &:focus {
                    background-color: black;
                }
            }

            &.active {
                span {
                    background-color: black;
                }
            }
        }

        &.disabled {
            display: none !important;
        }
    }

    .owl-nav {
        top           : 0;
        margin-top    : 0;
        color         : white;
        width         : 100%;
        position      : absolute;
        height        : 100%;
        pointer-events: none;

        button[class*=owl-] {
            margin        : 0;
            height        : 100%;
            text-shadow   : 2px 2px 5px rgba(0, 0, 0, 0.65);
            pointer-events: all;
            outline       : none;

            @include useFont(roboto, 900);
            font-size: (60rem / 16);

            @include media-breakpoint-up(md) {
                font-size: (80rem / 16);
            }

            @include media-breakpoint-up(lg) {
                font-size: (112rem / 16);
            }

            &.disabled {
                display: none;
            }

            &:hover {
                background: none;
                outline   : none;
                color     : inherit;
            }

            &.owl-prev {
                float: left;
            }

            &.owl-next {
                float: right;
            }
        }
    }
}

.btn.play,
.btn.stop {
    background-color: transparent;
    border          : none;
    position        : absolute;
    z-index         : 3;
    right           : (20rem / 16);
    bottom          : (166rem / 16);
    width           : (21rem / 16);
    height          : (21rem / 16);
    line-height     : (17rem / 16);
    min-width       : 0;
    min-height      : 0;
    font-size       : 0;
    padding         : 0;

    @include media-breakpoint-up(lg) {
        bottom     : 0;
        width      : (35rem / 16);
        height     : (35rem / 16);
        line-height: (20rem / 16);
    }

    @include media-breakpoint-only(xxs) {
        bottom: (120rem / 16);
    }

    @include media-breakpoint-only(xs) {
        bottom: (120rem / 16);
    }

    .icon {
        border-radius   : 50%;
        border          : 1px solid black;
        background-color: white;
        padding         : (7rem / 16);

        @include media-breakpoint-up(xxs) {
            padding-bottom: (8rem / 16);
        }

        @include media-breakpoint-up(md) {
            padding-bottom: (8rem / 16);
        }

        @include media-breakpoint-up(lg) {
            padding: (9rem / 16);
            border : 2px solid black;
        }

        &:hover {
            background-color: black;
        }
    }
}

.btn.stop {
    .icon {

        &::before,
        &::after {
            content         : '';
            position        : absolute;
            top             : 59%;
            left            : 50%;
            height          : (10rem / 16);
            width           : (3rem / 16);
            background-color: black;

            @include media-breakpoint-up(lg) {
                height: (12rem / 16);
                width : (4rem / 16);
            }
        }

        &:hover::before,
        &:hover::after {
            background-color: white;
        }

        &::before {
            -webkit-transform: translate(-140%, -70%);
            -o-transform     : translate(-140%, -70%);
            -ms-transform    : translate(-140%, -70%);
            -moz-transform   : translate(-140%, -70%);
            transform        : translate(-140%, -70%);

            @include media-breakpoint-up(md) {
                -webkit-transform: translate(-120%, -60%);
                -o-transform     : translate(-120%, -60%);
                -ms-transform    : translate(-120%, -60%);
                -moz-transform   : translate(-120%, -60%);
                transform        : translate(-120%, -60%);
            }

            @include media-breakpoint-up(lg) {
                -webkit-transform: translate(-120%, -73%);
                -o-transform     : translate(-120%, -73%);
                -ms-transform    : translate(-120%, -73%);
                -moz-transform   : translate(-120%, -73%);
                transform        : translate(-120%, -73%);
            }
        }

        &::after {
            -webkit-transform: translate(30%, -70%);
            -o-transform     : translate(30%, -70%);
            -ms-transform    : translate(30%, -70%);
            -moz-transform   : translate(30%, -70%);
            transform        : translate(30%, -70%);

            @include media-breakpoint-up(md) {
                -webkit-transform: translate(30%, -60%);
                -o-transform     : translate(30%, -60%);
                -ms-transform    : translate(30%, -60%);
                -moz-transform   : translate(30%, -60%);
                transform        : translate(30%, -60%);
            }

            @include media-breakpoint-up(lg) {
                -webkit-transform: translate(30%, -73%);
                -o-transform     : translate(30%, -73%);
                -ms-transform    : translate(30%, -73%);
                -moz-transform   : translate(30%, -73%);
                transform        : translate(30%, -73%);
            }
        }
    }
}

.btn.play {
    .icon {
        &::after {
            content          : '';
            position         : absolute;
            top              : 50%;
            left             : 50%;
            border           : 5px solid transparent;
            border-left      : 8px solid black;
            background-color : transparent;
            -webkit-transform: translate(-20%, -50%);
            -o-transform     : translate(-20%, -50%);
            -ms-transform    : translate(-20%, -50%);
            -moz-transform   : translate(-20%, -50%);
            transform        : translate(-20%, -50%);

            @include media-breakpoint-up(lg) {
                border           : 7px solid transparent;
                border-left      : 9px solid black;
                -webkit-transform: translate(-18%, -50%);
                -o-transform     : translate(-18%, -50%);
                -ms-transform    : translate(-18%, -50%);
                -moz-transform   : translate(-18%, -50%);
                transform        : translate(-18%, -50%);
            }
        }

        &:hover::after {
            border-left-color: white;
        }
    }
}

.is-ie {
    .btn.stop {
        .icon {
            &::before {
                -webkit-transform: translate(-134%, -70%);
                -o-transform     : translate(-134%, -70%);
                -ms-transform    : translate(-134%, -70%);
                -moz-transform   : translate(-134%, -70%);
                transform        : translate(-134%, -70%);

                @include media-breakpoint-up(md) {
                    -webkit-transform: translate(-120%, -70%);
                    -o-transform     : translate(-120%, -70%);
                    -ms-transform    : translate(-120%, -70%);
                    -moz-transform   : translate(-120%, -70%);
                    transform        : translate(-120%, -70%);
                }

                @include media-breakpoint-up(lg) {
                    -webkit-transform: translate(-120%, -80%);
                    -o-transform     : translate(-120%, -80%);
                    -ms-transform    : translate(-120%, -80%);
                    -moz-transform   : translate(-120%, -80%);
                    transform        : translate(-120%, -80%);
                }
            }

            &::after {
                -webkit-transform: translate(30%, -70%);
                -o-transform     : translate(30%, -70%);
                -ms-transform    : translate(30%, -70%);
                -moz-transform   : translate(30%, -70%);
                transform        : translate(30%, -70%);

                @include media-breakpoint-up(md) {
                    -webkit-transform: translate(30%, -70%);
                    -o-transform     : translate(30%, -70%);
                    -ms-transform    : translate(30%, -70%);
                    -moz-transform   : translate(30%, -70%);
                    transform        : translate(30%, -70%);
                }

                @include media-breakpoint-up(lg) {
                    -webkit-transform: translate(30%, -80%);
                    -o-transform     : translate(30%, -80%);
                    -ms-transform    : translate(30%, -80%);
                    -moz-transform   : translate(30%, -80%);
                    transform        : translate(30%, -80%);
                }
            }
        }
    }
}

.fn-carousel__iframe {
    width   : 150%;
    height  : 100%;
    position: relative;

    padding-bottom: (230rem / 16);

    @include media-breakpoint-up(md) {
        padding-bottom: (400rem / 16);
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: (420rem / 16);
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: (485rem / 16);
    }

    @include media-breakpoint-up(lg) {
        width: 100%;
    }
}

iframe.iframe-animation {
    width : 100%;
    height: 100%;

    border           : 0;
    position         : absolute;
    top              : 50%;
    -webkit-transform: translateY(-50%);
    -o-transform     : translateY(-50%);
    -ms-transform    : translateY(-50%);
    -moz-transform   : translateY(-50%);
    transform        : translateY(-50%);

    @include media-breakpoint-up(lg) {
        width: 100%;
    }
}

.lien-overlay {
    position: absolute;
    top     : 0;
    right   : 0;
    bottom  : 0;
    left    : 0;
}

.card.carrousel.white .card-img-overlay.mobileonly,
.card.carrousel.black .card-img-overlay.mobileonly {
    @include media-breakpoint-up(lg) {
        background-color: transparent;
        box-shadow      : none;
        background-image: none;

        .progress,
        .card-title {
            display: none !important;
        }
    }
}
                    @charset "utf-8";
                    /***************************************************************
**
**   max-width container
**
***************************************************************/
                    
                    $c-sm: 576px;
                    $c-md: 768px;
                    $c-lg: 992px;
                    $c-xl: 1200px;
                    $c-xxl: 1320px;
                    $c-fluid: 100vw;
                    $c-name-sm:"sm";
                    $c-name-md:"md";
                    $c-name-lg:"lg";
                    $c-name-xl:"xl";
                    $c-name-xxl:"xxl";
                    $c-name-fluid:"fluid";
                    $c-name-list: $c-name-sm,
                    $c-name-md,
                    $c-name-lg,
                    $c-name-xl,
                    $c-name-xxl;
                    //                    $c-name-fluid;
                    $c-list: $c-sm,
                    $c-md,
                    $c-lg,
                    $c-xl,
                    $c-xxl;
                    //                    $c-fluid;
                    /* MAX-WIDTH CONTAINER */
                    
                    @mixin max-width-containers() {
                        @for $i from 1 through length($c-list) {
                            $c-i: nth($c-list, $i) !global;
                            $c-i-name: nth($c-name-list, $i) !global;
                            .container-#{$c-i-name} {
                                max-width: $c-i;
                                width: 100%;
                                margin-right: auto;
                                margin-left: auto;
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }
                    }
                    
                    .rangee {
                        padding: 0;
                        margin: 0;
                        .bg-cover {
                            background-size: 0;
                        }
                        @include media-breakpoint-up(md) {
                            background-size: cover;
                        }
                        .col-md-6 {
                            @include media-breakpoint-down(sm) {
                                padding-top: 3vw;
                            }
                        }
                        @include max-width-containers
                    }
                    /*
            main.page-generique>div.rangee {
                margin: 0 2vw;
                padding: 0;
                @include media-breakpoint-up(md) {
                    margin: 0 10vw;
                }
                @include media-breakpoint-up(lg) {
                    margin: 0 15vw;
                }
            }
            */
.resultats-recents.resultat-sommaires .contenantResultat .roue-de-fortune-eclair .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .logo.blanc {
    display: none;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.extra > .numeros,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.roue-de-fortune-eclair > .numeros {
    text-align: center;
}



.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.extra .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.roue-de-fortune-eclair .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {

    left: -3%;
    top: 15%;
    height: 71%;
}


.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.extra .numeros {
    padding: 0;
    margin: 6.625rem 0;

    .num {
        font-size: 23px;
    }
}


@media screen and (min-width: $xxs) {

    .resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.extra .numeros {

        .num {
            font-size: 1.5rem;
        }
    }
}
.entete {
    position: relative;
    img {
        width: 100%;
        &.imagemobile {
            display: block;
            width: 100%;
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
        &.imagedesktop {
            display: none;
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }
    .col-12 {
        min-height: 100px;
    }
    .choixProfil {
        width: inherit;
    }
    .bloc-entete {
        max-width: 67vw;
        position: absolute;
        top: 20%;
        @include media-breakpoint-up(xs) {
            top: 30%;
        }
        @include media-breakpoint-up(md) {
            top: 25%;
        }
        @include media-breakpoint-up(lg) {
            top: 35%;
        }
        &.versionMobile {
            position: relative;
            top: 20%;
            @include media-breakpoint-up(sm) {
                top: 25%;
            }
            @include media-breakpoint-up(md) {
                position: absolute;
                top: 35%;
                left: unset;
            }
        }
        &.centre {
            @include media-breakpoint-down(sm) {
                left: 5%;
            }
            @include media-breakpoint-up(md) {
                left: 50%;
                transform: translateX(-50%);
            }
        }
        &.droite {
            margin-left: 5vw;
            @include media-breakpoint-up(md) {
                right: 5%;
            }
        }
        &.gauche {
            margin-left: 0vw;
            left: 5%;
            @include media-breakpoint-up(xs) {
                left: 5%;
            }
            @include media-breakpoint-up(md) {
                left: 10%;
            }
            @include media-breakpoint-up(lg) {
                left: 15%;
            }
        }
    }
    .text-wrap {
        .rich-text {
            h3 {
                color: #141B4D;
                font-weight: 900;
                font-family: Gordita;
                line-height: 1.0;
                font-size: (25rem / 16);
                @include media-breakpoint-up(xs) {
                    font-size: (25rem / 16);
                }
                @include media-breakpoint-up(sm) {
                    font-size: (25rem / 16);
                }
                @include media-breakpoint-up(md) {
                    font-size: (39rem / 16);
                    white-space: nowrap;
                }
                @include media-breakpoint-up(lg) {
                    font-size: (61rem / 16);
                }
            }
        }
    }
    .shape-wrapper {
        width: inherit;
        display: flex;
        justify-content: flex-start;
        flex-flow: row nowrap;
        width: 100%;
        @include media-breakpoint-up(md) {
            width: 70%;
        }
        @include media-breakpoint-up(lg) {
            width: 80%;
        }
    }
    .shape:first-child {
        margin-right: 3vw;
    }
    .shape {
        margin: 5px;
        padding: 1px;
        width: 20vw;
        overflow: hidden;
        margin-left: unset;
    }
    .boutons {
        padding-left: 15px;
    }
    #bouton {
        text-transform: uppercase;
        color: #000000;
        text-decoration: none;
    }
    #bouton2 {
        text-transform: uppercase;
        color: #000000;
        text-decoration: none;
    }
}

.margeReduite {
    @include media-breakpoint-up(xs) {
        .entete.row {
            margin: 0 24px 16px 24px;
        }
    }
    @include media-breakpoint-up(md) {
        .entete.row {
            margin: 0 24px 24px 24px;
        }
    }
}
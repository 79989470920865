.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.astro .numeros {
    width: 82%;
}
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.astro .lqZoneProduit.remorque .numeros {
    width: auto!important;
}
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.astro .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
    height: 88%;
    left: -8%;
    top: 9%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.astro .legende .signe {
    width: auto;
    height: 100%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.astro > .numeros .signe.noir {
    width: 100%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.astro > .numeros .signe {
    height: 100%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.astro .legende .mois {
    width: 19%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.astro .legende {
    font-size: 1rem;
    justify-content: center;
    margin-top: 1.875rem;
    margin-bottom: 0.5rem;

    div {
        margin: 0 0.5rem;
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.astro > .numeros {
    height: 4.5rem;
    padding: 0 1rem;
    margin-bottom: 2.125rem;
    margin-top: 0;

    div {
        text-align: center;
    }
}

.resultats-recents.resultat-sommaires  .lqConteneurGeneral.astro .legende {
    padding-bottom: 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.astro .legende {
    width: 100%;
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes {
    .contenantResultat .lqConteneurGeneral.astro .legende .annee {
        text-align: left;
        margin-left: 0;
        margin-right: 6%;
    }
}

.resultats-recents.resultat-sommaires .conteneur-flex-2-colonnes {
    .contenantResultat .lqConteneurGeneral.astro .legende .annee {
        text-align: center;
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.astro > .numeros {
    height: 4.5rem;
    padding: 0 1rem;
    margin-bottom: 2.125rem;
    margin-top: 0;
    font-size: 1rem;
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes {
    .lqConteneurGeneral.astro > .numeros {
        max-width: 25rem;
    }

    .lqConteneurGeneral.astro .legende {
        max-width: 30rem;
    }

}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.astro .numeros .num-sep {
    font-size: 1rem;
}
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
    height: 106%;
    width: auto;
    left: 3%;
    top: -2%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 .numerosGarantis {
    width: auto;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 .numeros {
    margin-top: 3.3041875rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 .numeros .num,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 .lqZoneMessageAVenir .num,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 a.lienResultatsComplets .num {
    margin-bottom: 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 .numeros,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 {
    margin-bottom: 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 .remorque .numeros {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 a.lienResultatsComplets .resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 .lqZoneMessageAVenir {
    margin-bottom: 4%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 .numerosGarantis {
    margin-bottom: 2.88425rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .avis-groslots {
    .avis-groslots {
        a,
        a:hover {
            .groslot-info {
                margin-top: 5px;
                div.classique {
                    font-family: "Gotham Medium Regular", sans-serif;
                    font-weight: bold;
                    color: #ec292a;
                    padding-left: 18%;
                    padding-bottom: 1rem;
                    margin-left: 0.5rem;
                    @include media-breakpoint-up(md) {
                        padding-left: 15%;
                    }
                    @include media-breakpoint-up(lg) {
                        padding-left: 20%;
                    }
                    .texteClassique {
                        font-size: 0.604375rem;
                        margin-left: 0;
                        @include media-breakpoint-up(lg) {
                            font-size: (12rem / 16);
                        }
                        @include media-breakpoint-only(md) {
                            font-size: 1.5vw;
                        }
                        @include media-breakpoint-only(sm) {
                            font-size: 1.75vw;
                        }
                        @include media-breakpoint-only(xs) {
                            font-size: 1.75vw;
                        }
                    }
                    .montantClassique {
                        font-size: 0.906875rem;
                        white-space: nowrap;
                        @include media-breakpoint-up(lg) {
                            font-size: 1.5rem;
                        }
                        @include media-breakpoint-only(md) {
                            font-size: 2.25vw;
                        }
                        @include media-breakpoint-only(sm) {
                            font-size: 2.5vw;
                        }
                        @include media-breakpoint-only(xs) {
                            font-size: 2.75vw;
                        }
                    }
                }
                div.bouleOr {
                    display: flex;
                    flex-direction: row;
                    column-gap: 0.5rem;
                    align-items: center;
                    font-weight: bold;
                    img.bouleOrImg {
                        width: 18%;
                        @include media-breakpoint-up(md) {
                            width: 15%;
                        }
                        @include media-breakpoint-up(lg) {
                            width: 20%;
                        }
                    }
                    .lqZoneDetailBouleOr {
                        display: flex;
                        flex-direction: column;
                        justify-content: left;
                        align-items: center;
                        text-align: left;
                        .texteBouleOr {
                            font-family: "Gotham Medium Regular", sans-serif;
                        }
                        .pglBouleOr {
                            font-family: "Gotham Medium Regular", sans-serif;
                        }
                    }
                }
            }
            .groslot-boutons-jouer {
                margin-top: 15px;
                @include media-breakpoint-only(xs) {
                    margin-top: 10px;
                }
                @include media-breakpoint-only(xxs) {
                    clear: both;
                    display: block;
                    font-size: (12rem / 16);
                }
            }
        }
    }
}

.resultats-recents.resultat-sommaires {
    .produit-212.contenantResultat {
        .avis-groslots a .tirage div.groslot-approx,
        .avis-groslots a:hover .tirage div.groslot-approx {
            display: flex;
            font-size: (11rem / 16);
            padding-top: 1rem;
            @include media-breakpoint-only(xxs) {
                margin-bottom: (10rem / 16);
            }
            @include media-breakpoint-only(xs) {
                font-size: (10rem / 16);
            }
            @include media-breakpoint-up(md) {
                font-size: (14rem / 16);
                margin-right: 80px;
                padding-top: 0.5rem;
            }
            @include media-breakpoint-only(lg) {
                margin-right: 15px;
                padding-right: 0;
            }
        }
    }
}
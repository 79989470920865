@charset "utf-8";
.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.la-poule-aux-oeufs-dor {
                justify-content: flex-start;
                .lqConteneurNumeros {
                    margin-top: auto;
                    margin-bottom: auto;
                    .numeros {
                        justify-content: center;
                        display: flex;
                        flex-wrap: wrap;
                        .col1 {
                            .prix {
                                display: flex;
                                justify-content: center;
                                @include useFont(gordita);
                                font-size: (14rem / 16);
                                @include media-breakpoint-between(all, xs) {
                                    font-size: (14rem / 16);
                                }
                                @include media-breakpoint-only(sm) {
                                    font-size: (18rem / 16);
                                }
                                @include media-breakpoint-only(md) {
                                    font-size: (20rem / 16);
                                }
                                @include media-breakpoint-only(lg) {
                                    font-size: (22rem / 16);
                                }
                                @include media-breakpoint-up(xl) {
                                    font-size: (24rem / 16);
                                }
                                .numero {
                                    justify-self: flex-start;
                                    .num {
                                        font-size: (15rem / 16);
                                        letter-spacing: 0.1em;
                                        @include media-breakpoint-only(xxs) {
                                            font-size: (14rem / 16);
                                            font-weight: bold;
                                        }
                                        @include media-breakpoint-only(xs) {
                                            font-size: (15rem / 16);
                                        }
                                        @include media-breakpoint-only(sm) {
                                            font-size: (18rem / 16);
                                        }
                                        @include media-breakpoint-only(md) {
                                            font-size: (20rem / 16);
                                        }
                                        @include media-breakpoint-only(lg) {
                                            font-size: (22rem / 16);
                                        }
                                        @include media-breakpoint-up(xl) {
                                            font-size: (24rem / 16);
                                        }
                                    }
                                }
                            }
                        }
                        .col2 {
                            .prix {
                                display: flex;
                                justify-content: center;
                                @include useFont(gordita);
                                font-size: (14rem / 16);
                                @include media-breakpoint-only(xxs) {
                                    font-size: (14rem / 16);
                                }
                                @include media-breakpoint-only(xs) {
                                    font-size: (14rem / 16);
                                }
                                @include media-breakpoint-only(sm) {
                                    font-size: (18rem / 16);
                                }
                                @include media-breakpoint-only(md) {
                                    font-size: (20rem / 16);
                                }
                                @include media-breakpoint-only(lg) {
                                    font-size: (22rem / 16);
                                }
                                @include media-breakpoint-up(xl) {
                                    font-size: (24rem / 16);
                                }
                                .numero {
                                    justify-self: flex-start;
                                    width: 50%;
                                    text-align: left;
                                    .num {
                                        font-size: (15rem / 16);
                                        letter-spacing: 0.1em;
                                        @include media-breakpoint-only(xxs) {
                                            font-size: (14rem / 16);
                                            font-weight: bold;
                                        }
                                        @include media-breakpoint-only(xs) {
                                            font-size: (15rem / 16);
                                        }
                                        @include media-breakpoint-only(sm) {
                                            font-size: (18rem / 16);
                                        }
                                        @include media-breakpoint-only(md) {
                                            font-size: (20rem / 16);
                                        }
                                        @include media-breakpoint-only(lg) {
                                            font-size: (22rem / 16);
                                        }
                                        @include media-breakpoint-up(xl) {
                                            font-size: (24rem / 16);
                                        }
                                    }
                                }
                            }
                        }
                        .col1 {
                            line-height: 30px;
                            width: 50%;
                            .prix {
                                .lot {
                                    margin-right: (14rem / 16);
                                    width: (82rem / 16);
                                    @include media-breakpoint-only(xs) {
                                        margin-right: (8rem / 16);
                                        width: 57%;
                                    }
                                    @include media-breakpoint-only(sm) {
                                        width: (105rem / 16);
                                    }
                                    @include media-breakpoint-only(md) {
                                        width: (116rem / 16);
                                    }
                                    @include media-breakpoint-only(lg) {
                                        width: (128rem / 16);
                                    }
                                    @include media-breakpoint-only(xl) {
                                        width: (140rem / 16);
                                    }
                                    text-align: right;
                                }
                                .numero {
                                    text-align: left;
                                    .num {
                                        text-align: left;
                                    }
                                    @include media-breakpoint-only(xxs) {
                                        width: (61rem / 16);
                                    }
                                    @include media-breakpoint-only(xs) {
                                        width: (68rem / 16);
                                    }
                                    @include media-breakpoint-only(sm) {
                                        width: (76rem / 16);
                                    }
                                    @include media-breakpoint-only(md) {
                                        width: (84rem / 16);
                                    }
                                    @include media-breakpoint-only(lg) {
                                        width: (93rem / 16);
                                    }
                                    @include media-breakpoint-only(xl) {
                                        min-width: (93rem / 16);
                                    }
                                }
                            }
                        }
                        .col2 {
                            line-height: 30px;
                            width: 50%;
                            text-transform: uppercase;
                            .prix {
                                .lot {
                                    margin-right: (8rem / 16);
                                    text-align: right;
                                    width: 50%;
                                    @include media-breakpoint-only(xs) {
                                        margin-right: (6rem / 16);
                                        width: 47%;
                                    }
                                }
                            }
                        }
                        .oeufBoni {
                            margin: auto;
                            .prix {
                                line-height: 70px;
                                .lot {
                                    font-weight: bold;
                                }
                                .numero {
                                    display: inline-block;
                                    background-repeat: no-repeat;
                                    background-size: 100%;
                                    background-position: center;
                                    width: 35px;
                                    text-align: center;
                                    .num {
                                        font-size: 0.8rem;
                                    }
                                }
                            }
                            .illico_prix.oeuf {
                                display: none;
                            }
                        }
                    }
                }
                .lqConteneurEnteteProduit {
                    .lqEnteteZoneProduit {
                        .logo {
                            img {
                                margin: (12rem / 16);
                                @include media-breakpoint-only(xxs) {
                                    width: (182rem / 16);
                                }
                            }
                        }
                    }
                }
                .lienResultatsComplets {
                    margin-bottom: auto;
                    margin-top: auto;
                }
            }
        }
    }
}

.is-ie {
    .resultat-sommaires {
        .contenantResultat {
            .lqConteneurGeneral {
                &.la-poule-aux-oeufs-dor {
                    .lqConteneurNumeros {
                        //Correction LSWI-471 : Bouton Poule en IE trop bas
                        @include media-breakpoint-up(xl) {
                            min-height: 12em;
                        }
                    }
                }
            }
        }
    }
}
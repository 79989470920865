@charset "utf-8";

.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.la-mini {
                justify-content: start;

                .numeros, .lqZoneMessageAVenir, a.lienResultatsComplets {
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }
    }
}
@charset "utf-8";


/**********************************************
* Reprise de _lq.columns
*
*
***********************************************/ 
.row.content-container {
  /*@include mq-scale(margin-top, 150, 120, 80);*/	
  margin-top: vwValue(150);	
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  
  @include media-breakpoint-up(md) {
	  	margin-top: rem(120 * $tablet-ratio);
	  }
	
  @include media-breakpoint-up(lg) {
	  	margin-top: rem(80);
	  }
  
  &.no-top-margin,
  & + .tabs-container,
  & + .media-see-more,
  &.header-block,
  &.sticky-bar {
    margin-top: 0;
  }
 
 .squared-img .tile-sizer:after,
  &.squared-img .tile-sizer:after {
    padding-top: 100%;
  }
  
  .col,
  .column {
    height: 100%;
  } 
  
 .tile-sizer:after {
    padding-top: $wide-ratio;
  } 
 
 .tile-inner {
    margin: 0;
  }
  
  .after-line {
    display: inline-block;
    position: relative;

    &:after {
      /*@include mq-scale(bottom, -42, -42, -18);
      @include mq-scale(left, 11, 11, 5);
      @include mq-scale(width, 193, 193, 82);
      @include mq-scale(height, 9, 9, 4);*/
      
      
      bottom: vwValue(-42);
      left: vwValue(11);
      width: vwValue(193);
      height: vwValue(9);
      
      content: "";
      position: absolute;
      
      
      	
      @include media-breakpoint-up(md) {
	  	bottom: rem(-42 * $tablet-ratio);
	  	left: rem(11 * $tablet-ratio);
	  	width: rem(193 * $tablet-ratio);
	  	height: rem(9 * $tablet-ratio);
	  }
	
  	  @include media-breakpoint-up(lg) {
	  	bottom: rem(-18);
	  	left: rem(5);
	  	width: rem(82);
	  	height: rem(4);
	  }
      
      
      
      
    }

    + .rich-text {
      /*@include mq-scale(margin-top, 82, 82, 35);*/
      margin-top: vwValue(82);	
      @include media-breakpoint-up(md) {
	  	margin-top: rem(82 * $tablet-ratio);
	  }
	
  	  @include media-breakpoint-up(lg) {
	  	margin-top: rem(35);
	  }
      
      
      
    }
  }
  
   
  
}

/**/





/*********************************************************************************************
* Reprise de _lq.rollover.scss
*
*
**********************************************************************************************/
@include media-breakpoint-up(lg) {
	.image-scale {
	    &.tile-static,
	    &.media-image a,
	    .tile-static,
	    .media-image a {
	      &:hover .bg-img {
	        /*@include scale(1.05);
	        @include transform(rotationZ(0.01));*/
	        transform: scale(1.05);
	    	transform: rotateZ(0.01);
	      }
	    }
	  }


	a.image-scale:hover .media-image .bg-img {
	    /*@include scale(1.05);*/
	    /*@include transform(rotationZ(0.01));*/
	    transform: scale(1.05);
	    transform: rotateZ(0.01);
	  }

}


/**********************************************
* Reprise de _lq.media.image.scss
*
*
***********************************************/ 

.media-component {
	@include media-breakpoint-up(md) {
		&.vertical-align {
			.row {
				display: flex;
        		align-items: center;
        		
				.col  {
					height: inherit;
					}
				}
			}
	}

  .content {
    @include transitions(opacity 0.3s ease-in-out);
    position: relative;
    margin: 0;
    height:inherit;
  }
  
  .bg-img {
    @include transitions(all .5s ease-out);
    /*@include opacity(0);*/
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;

    &.lazyloaded {
      opacity: 1;
      height: 100%;
    }
  }
  
  .tile-sizer:after {
    content: "";
    display: block;
    width: 100%;
  }
  

}



.media-image {
    display: block;
	position: relative;
	// height:400px; // Pour voir quelque chose pour le moment tant que section et parents n ont pas de hauteur

    .content-img.with-text .bg-img:after {
      content: '';
      display: block;
      width: 100%;
      height: auto;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.7+100 */
      background: #000;
      /* IE9 SVG, needs conditional override of 'filter' to 'none' */
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC43Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
      background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.7))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%); /* IE10 preview */
      background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#b3000000',GradientType=0 ); /* IE6-8 */
      z-index: 1;
      bottom: 0;
      top: 60%;
      position: absolute;
    }
    .content-text {
      position: absolute;
      left: 10%;
      right: 10%;
      z-index: 2;
      // @include mq-scale(bottom, 30, 50, 20);
      // @include mq-scale(font-size, 33, 33, 14);
      // @include mq-scale(line-height, 48, 48, 21);
      
      bottom: vwValue(30);
      font-size: vwValue(33);
      line-height: vwValue(48);
  	
	  @include media-breakpoint-up(md) {
	  	bottom: rem(50 * $tablet-ratio);
	  	font-size: rem(33 * $tablet-ratio);
	  	line-height: rem(48 * $tablet-ratio);
	  }
	
	  @include media-breakpoint-up(lg) {
	  	bottom: rem(20);
	  	font-size: rem(14);
	  	line-height: rem(21);
	  }
	      
      
      
      
      
    }

	&.with-rollover-logo {
		.img-logo {
			opacity: 0;
		}
	}

	.content-text > div,
	.content-text-inner > div {
		font-family: $theme-font-bold;
		color: #FFFFFF; /* A refactorer avec couleurs */

        &.under-title-text {
          font-family: $theme-font-regular;
          //@include mq-scale(font-size, 28, 24, 14);   
          line-height: 1.2em;
          
          font-size: vwValue(28);
	
		  @include media-breakpoint-up(md) {	  	
			font-size: rem(24 * $tablet-ratio);
		  }
				
		  @include media-breakpoint-up(lg) {
			font-size: rem(14);
		  }
          
          
          
          
        }
        &.over-title-text {
          font-family: $theme-font-regular;
          //@include mq-scale(font-size, 36, 28, 18);
          line-height: 1.2em;
          //@include mq-scale(padding, 6, 4, 2);
          
          
          
          
          

          + .under-title-text {
            //@include mq-scale(font-size, 24, 20, 12);
            
            font-size: vwValue(24);
	
			@include media-breakpoint-up(md) {	  	
				font-size: rem(20 * $tablet-ratio);
			}
	
			@include media-breakpoint-up(lg) {
				font-size: rem(12);
			}
            
          }

        }
	}
    .under-title-text {
        //color: $color-media-image-undertitle-text;
    }

    .over-title-text {
        //color: $color-media-image-overtitle-text;
    }

	.tile-inner .alt-text {
		position: relative;
		&:after {
			content: '';
			//@include cover();
			//background: transparent url(../images/black-px.gif) repeat 0 0
		}
	}

}


@include media-breakpoint-down(sm){
	.mobile-gallery {
		white-space: nowrap;
		overflow-x: visible;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
}


@include media-breakpoint-up(lg) {

	.media-image {

		//.bg-img .img-logo {
        .img-logo {
			//@include cover();
			background: rgba(0,0,0,0.5) none no-repeat center center; //TODO variable

			.img-logo-inner {
				background-repeat: no-repeat;
				max-width: 40%;
				max-height: 30%;
				transform: translateX(-50%) translateY(-50%);
				top: 50%;
				left: 50%;
				position: absolute;
				background-size: contain;
			}
		}
		&.with-rollover-logo {

			//.bg-img .img-logo {
            .img-logo {
				//@include opacity (0);
				opacity: 0;
			}
			//&:hover .bg-img .img-logo,
            &:hover .img-logo,
			&:hover .content-text {
				//@include opacity(1);
				opacity: 1;
			}
		}

		&.with-rollover-text {
			.content-text,
			.content-img.with-text .bg-img:after {
				opacity: 0;
				color: #FFFFFF; /* A refactorer avec couleurs */
				//@include animate(opacity, .3s);
			}
			&:hover .content-text,
			&:hover .content-img.with-text .bg-img:after {
				opacity: 1;
			}
		}
		&.content-always-visible {
			//.bg-img .img-logo {
            .img-logo {
				background-color: transparent;
				//@include opacity(1);
				opacity: 1;
			}

			.content-text {
				//@include opacity(1);
				opacity: 1;
			}


		}
	}
}


// overwride media-image text behavior in multi-formats
.multi-formats {
  .media-image {
    .content-img .bg-img:after {
      opacity: 0 !important;
    }
    .content-text {
      //@extend .related-text-box;

      right: auto;

      .under-title-text {
        //@include mq-scale(font-size, 36, 28, 16);
        
        font-size: vwValue(36);
	
		@include media-breakpoint-up(md) {	  	
			font-size: rem(28 * $tablet-ratio);
		}
			
		@include media-breakpoint-up(lg) {
			font-size: rem(16);
		}
        
        
      }
      .over-title-text {
        font-family: $theme-font-black;
        //@include mq-scale(font-size, 48, 36, 22);
        line-height: 1em;
        padding: 0;
        
        font-size: vwValue(48);
	
		@include media-breakpoint-up(md) {	  	
			font-size: rem(36 * $tablet-ratio);
		}
			
		@include media-breakpoint-up(lg) {
			font-size: rem(22);
		}
        
        
        
      }
      div.over-title-text + .under-title-text {
        //@include mq-scale(font-size, 33, 24, 14);
        //@include mq-scale(margin-top, 8, 6, 4);
        
        font-size: vwValue(33);
		margin-top: vwValue(8);

		@include media-breakpoint-up(md) {	  	
			font-size: rem(24 * $tablet-ratio);
			margin-top: rem(6 * $tablet-ratio);
		}
	
		@include media-breakpoint-up(lg) {
			font-size: rem(14);
			margin-top: rem(4);
		}
        
        
        
        
      }

    }
  }
}

//@include section-colors() {
/*
  .multi-formats {
    .media-image {
      .content-text {
        .under-title-text {
          color: $color-section-fg;
        }
        div.over-title-text + .under-title-text {
          color: $color-theme-text-default;
        }
      }
    }
  }

}
*/
.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes {
    .contenantResultat .lqConteneurGeneral.banco .numeros .groupe .num,
    .contenantResultat .lqConteneurGeneral.banco .numeros .groupe .num-sep {
        @include fluid-type($xxs, $xxl, 14px, 24px);
    }
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes .contenantResultat .lqConteneurGeneral.banco .numeros .groupe {
    line-height: 21px;
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes {
    .contenantResultat .lqConteneurGeneral.banco .lqConteneurMultiplicateur {
        margin-top: 3vw;
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.banco .lqConteneurMultiplicateur {
    margin-top: 0.7135rem;
    margin-bottom: 0.9601rem;
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes .banco {
    .lqConteneurGeneral .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit {
        left: -10px;
        position: relative;
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.banco>.numeros {
    text-align: center;
    margin: 2.125rem auto 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.banco .numeros .groupe .num,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.banco .numeros .groupe .num-sep {
    font-size: 1.10rem;
}

.resultats-recents.resultat-sommaires .conteneur-flex-2-colonnes .contenantResultat .lqConteneurGeneral.banco .numeros .groupe .num,
.resultats-recents.resultat-sommaires .conteneur-flex-2-colonnes .contenantResultat .lqConteneurGeneral.banco .numeros .groupe .num-sep {
    font-size: 1.25rem;
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes .contenantResultat .lqConteneurGeneral.banco .numeros .groupe .num,
.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes .contenantResultat .lqConteneurGeneral.banco .numeros .groupe .num-sep {
    font-size: 1.25rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.banco .lqConteneurEnteteProduit {
    margin-bottom: 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.banco .lqZoneProduit.remorque .numeros {
    margin-top: 0;
}

.resultats-recents.resultat-sommaires {
    .contenantResultat .lqConteneurGeneral.banco .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
        height: 100%;
        left: -3%;
        top: 3%;
    }
    .conteneur-flex-3-colonnes {
        .contenantResultat .lqConteneurGeneral.banco .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
            height: 90%;
            left: -10%;
            top: 10%;
        }
    }
}

.resultats-recents .lqConteneurMultiplicateur img.multiplicateur {
    width: (99rem / 16);
    @include media-breakpoint-up(sm) {
        width: (80rem / 16);
    }
    @include media-breakpoint-up(lg) {
        width: (115rem / 16);
    }
}
@charset "utf-8";

.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.lotto-poker {
                .numeros {
                    span.carte {
                        img.carte {
                            width: (45rem / 16);
                            @include media-breakpoint-up(lg) {
                                width: (50rem / 16);
                            }
                        }
                    }
                }
            }
        }
    }
}
.resultats-recents {
    .contenantLots-progressifs a .lots-progressifs .infoProgressif,
    .contenantLots-progressifs a .lots-progressifs .infoProgressif2,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif2 {
        width: 40%;
    }
    .contenantLots-progressifs a .lots-progressifs .infoProgressif p.margeMsg,
    .contenantLots-progressifs a .lots-progressifs .infoProgressif2 p.margeMsg,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif p.margeMsg,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif2 p.margeMsg {
        margin-top: 0;
        margin-bottom: 0;
    }
    .contenantLots-progressifs a .lots-progressifs,
    .contenantLots-progressifs a:hover .lots-progressifs {
        height: auto;
    }
    .contenantLots-progressifs a .media,
    .contenantLots-progressifs a:hover .media {
        display: flex;
        justify-content: center;
    }
    .lots-progressifs {
        padding-bottom: 1rem;
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-poker .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
    height: 90%;
    left: 2%;
    top: 4%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-poker .numeros.typeLottoPoker {
    margin: 3.0625rem auto;
    text-align: center;
}

.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral.lotto-poker .lqConteneurEnteteProduit {
    margin-bottom: 0%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-poker .numeros .num-sep {
    font-size: 1.5rem;
    display: none;
}

.resultats-recents.resultat-sommaires .contenantLots-progressifs .infoProgressif {
    background-color: $entete-couleur-background;
    border-radius: 45px;
}

.resultats-recents.resultat-sommaires .contenantLots-progressifs {
    a .lots-progressifs .infoProgressif .montantProgressif,
    a .lots-progressifs .infoProgressif2 .montantProgressif,
    a:hover .lots-progressifs .infoProgressif .montantProgressif,
    a:hover .lots-progressifs .infoProgressif2 .montantProgressif {
        width: 100%;
        margin-bottom: 0;
    }
    a .lots-progressifs .infoProgressif p.margeMsg,
    a .lots-progressifs .infoProgressif2 p.margeMsg,
    a:hover .lots-progressifs .infoProgressif p.margeMsg,
    a:hover .lots-progressifs .infoProgressif2 p.margeMsg {
        margin-bottom: 1rem;
    }
}

.resultats-recents.resultat-sommaires {
    .contenantLots-progressifs a .lots-progressifs .infoProgressif .progressif-approx p,
    .contenantLots-progressifs a .lots-progressifs .infoProgressif2 .progressif-approx p,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif .progressif-approx p,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif2 .progressif-approx p {
        margin-bottom: 1rem;
    }
}
@charset "utf-8";

.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.la-quotidienne {
				.lqConteneurNumeros{
                    margin    : auto;
                    text-align: left;
                    display   : flex;
					flex-direction: column;
					align-items: stretch;
                    .lqContenuQuotidienne {
                        font-size: (21rem / 16);
 
                        @include media-breakpoint-only(sm) {
                            font-size: (22rem / 16);
                        }

                        @include media-breakpoint-up(md) {
                            font-size: (26rem / 16);
                        }

                        .titre {
                            margin: 0 auto;
                            width : 100%;
							display: inline-flex;
							align-items: center;
                            span.numeros {
                                margin     : 0;
                                margin-left: (7rem / 16);
                            }

                            span.quotidienneZoneBoni {
                                float: right; 
                                color: #d10000;
								font-size: (19rem / 16);
								padding-left: 2rem;
    							text-align: end;

                                .zoneEnLot {
                                    font-style: italic;
                                }

                                .zoneBoniGras {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
				.lqConteneurNumerosAvecBonis {
					min-width: 60%;                
    				margin    : 0;
                    text-align: left;
                    display   : flex;
					flex-direction: column;
					align-items: stretch;
					margin-inline: auto;
					width: fit-content;
                    .lqContenuQuotidienne {
                        font-size: (21rem / 16);
 
                        @include media-breakpoint-only(sm) {
                            font-size: (22rem / 16);
                        }

                        @include media-breakpoint-up(md) {
                            font-size: (26rem / 16);
                        }

                        .titre {						
							margin: 0 auto;
                            width : 100%;
							display: inline-flex;
							align-items: center;
							@include media-breakpoint-down(xs) {
	                            font-size: 1rem;
	                        }
                            span.numeros {
                                margin     : 0;
                                margin-left: (7rem / 16);
                            }

                            span.quotidienneZoneBoni {
                                float: right; 
                                color: #d10000;
								font-size: (19rem / 16);
								padding-left: 2rem;
    							text-align: end;
								width: 100%;
								@include media-breakpoint-down(xs) {
		                            font-size: (12rem / 16);
									padding-left: 1rem;
		                        }
                                .zoneEnLot {
                                    font-style: italic;
                                }

                                .zoneBoniGras {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
			}
        }
    }
}
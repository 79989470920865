@charset "utf-8";

// icônes sous media-icon-list
.media-icon{
    margin-top: 10px;
    margin-bottom: 2rem;

    margin-right: 0px;
    margin-left: -15px;
    .titre{
        font-size: 1.25rem;
        /*font-family: $theme-font-regular;*/
        line-height: 1.4rem;
        .rich-text{
            margin-top: 0.7rem;
            font-size: 1rem;
        }
    }    
    .icone{
        padding-right: 2.171%;

        width: (40rem /16);

        @include media-breakpoint-up(md) {
            width: (56rem /16);   
        }
        @include media-breakpoint-up(lg) {
            width: 6rem;   
        }

        img {
            height : (28rem /16);
            @include media-breakpoint-up(md) {
                height : (44rem /16);   
            }
            @include media-breakpoint-up(lg) {
                height : 4rem;    
            }
             
        }
        svg{
            height: 60px;
            width: 60px;
        }

        .bubble{
            display: none;
            transform: translate(0, 2rem);
            
            span{
                padding: 5px;
                white-space: nowrap;
            }

            // le 'ti triangle pour la bulle
            &:before {
                content: "";
                width: 0px;
                height: 0px;
                position: absolute;
                border-right: 5px solid transparent;
                border-top: 5px solid transparent;
                left: 10px;
                top: -10px;
            }
        }

        &:hover .bubble{
            display: block;
        }
    }

    &.justify-content-center {
        .icone {
            text-align: center;
        }
    }
}

//Application des couleur de section
/* @include section-colors(){
    .media-icon{
        .icone{
            svg{
                use{
                    //fill: $color-section-fg;
                }
            }
        }
    .titre{
        //color:$color-section-fg;
    }    
        .rich-text p{
            //color: $color-theme-fg;
        }
    }
}
*/


@charset "utf-8";

button.btn,
a.btn {
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &.texte-bouton {
        @include useFont(gordita, 700);
        color         : #1f1f1f;
        line-height   : normal;
        letter-spacing: (0.8rem / 16);
        text-transform: uppercase;
        padding-left  : (20rem / 16);
        padding-right : (20rem / 16);

        &:hover {
            color: #ffffff;
        }
    }

    width:auto;

    @include media-breakpoint-between(all, xxs) {
        min-height: (31rem / 16);
        min-width : (91rem / 16);
    }


    @include media-breakpoint-up(xs) {
        min-height: (31rem / 16);
        min-width : (91rem / 16);
    }

    @include media-breakpoint-up(sm) {
        min-width : (151rem / 16);
        min-height: (51rem / 16);
    }

    @include media-breakpoint-up(md) {
        min-width : (156rem / 16);
        min-height: (56rem / 16);
    }

    @include media-breakpoint-up(lg) {
        min-width : (170rem / 16);
        min-height: (55rem / 16);
    }
}

.btn-outline-primary {
    box-shadow      : 0 0 (11rem / 16) (2rem / 16) rgba(239, 51, 64, 0.34);
    border          : solid (3rem / 16) #ef3340;
    background-color: white;
    border-radius   : (25rem / 16);

    &:hover,
    &:active {
        background-color: #c21f2a !important;
        border-color    : #c21f2a !important;
        box-shadow      : none;
        text-decoration : none;
        color           : #ffffff;
    }

    &:focus {
        border          : solid (3rem / 16) #ef3340 !important;
        background-color: white;
    }
}

.btn-outline-secondary {
    box-shadow      : 0 0 (11rem / 16) (2rem / 16) rgba(0, 0, 0, 0.34);
    border          : solid (3rem / 16) #000000;
    background-color: white;
    border-radius   : (25rem / 16);
    margin          : (3rem / 16);
    color           : #000000;

    &:hover,
    &:active {
        background-color: #000000;
        border          : #000000;
        box-shadow      : none;
        text-decoration : none;
    }

    &:focus {
        border          : solid (3rem / 16) #000000 !important;
        background-color: white;
    }
}

.btn-outline-ternary {
    /*déplacement dans 27-media-bouton*/
    /*box-shadow      : 0 0 13.9px 2.1px rgba(39, 39, 39, 0.29);*/
    border          : solid 3px #000;
    background-color: #ffffff;
    border-radius   : 1.56rem;
    color           : #000000;

    &:hover, &:focus {
        /*border        : none;*/
        /*déplacement dans 27-media-bouton*/
        /*box-shadow      : 0 0 13.9px 2.1px rgba(48, 48, 48, 0.29);*/
        background-color: #000;
        text-decoration : none;
    }
}
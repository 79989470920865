.resultats-recents.resultat-sommaires {
    .contenantLots-progressifs a .lots-progressifs .infoProgressif .dateProgressif p,
    .contenantLots-progressifs a .lots-progressifs .infoProgressif2 .dateProgressif p,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif .dateProgressif p,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif2 .dateProgressif p {
        background-image: url('../images/svg/calendar.svg');
    }
    .resultats-recents.resultat-sommaires .contenantLots-progressifs a .lots-progressifs .infoProgressif,
    .resultats-recents.resultat-sommaires .contenantLots-progressifs a .lots-progressifs .infoProgressif2,
    .resultats-recents.resultat-sommaires .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif,
    .resultats-recents.resultat-sommaires .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif2 {
        justify-content: center;
    }
    .contenantLots-progressifs a .lots-progressifs .infoProgressif .dateProgressif,
    .contenantLots-progressifs a .lots-progressifs .infoProgressif2 .dateProgressif,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif .dateProgressif,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif2 .dateProgressif {
        justify-content: center;
        //font-size: 1rem;
        text-align: center;
        p {
            padding-right: 0;
            padding-left: 0;
        }
    }
    .contenantLots-progressifs a .lots-progressifs .infoProgressif .titreProgressif,
    .contenantLots-progressifs a .lots-progressifs .infoProgressif2 .titreProgressif,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif .titreProgressif,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif2 .titreProgressif {
        justify-content: center;
        padding-top: 0;
    }
    .contenantLots-progressifs a .lots-progressifs .infoProgressif,
    .contenantLots-progressifs a .lots-progressifs .infoProgressif2,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif,
    .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif2 {
        padding: 10px 0;
    }
}

.resultats-recents.resultat-sommaires {
    .produit-226 {
        .titreProgressif {
            font-size: 1rem !important;
            margin: 0 auto !important;
        }
        .row.justify-content-between {
            width: 100%;
            margin: 0;
        }
        .margeMsg {
            text-align: center !important;
            padding: 5%;
        }
        .progressif-approx {
            margin: 0 auto !important;
            p {
                text-align: center !important;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }
        }
        .montantProgressif {
            margin-bottom: 0;
            text-align: center;
        }
        .lots-progressifs.col-12.media-xxs {
            display: none;
        }
        .contenantLots-progressifs a .lots-progressifs .infoProgressif .dateProgressif p,
        .contenantLots-progressifs a .lots-progressifs .infoProgressif2 .dateProgressif p,
        .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif .dateProgressif p,
        .contenantLots-progressifs a:hover .lots-progressifs .infoProgressif2 .dateProgressif p {
            background: none;
        }
    }
}
@charset "utf-8";

$tablet-ratio: 0.6;
$rem-base: 16px !default;

/***************************************************************
**   FONT-FAMILY LOTERIES
***************************************************************/
$fonts-families: (
	functionpro: 'functionpro, Helvetica, Arial, sans-serif',
	gordita: 'gordita, Tahoma, Century gothic, Arial, sans-serif',
	roboto: 'roboto, Trebuchet MS, Arial, sans-serif',
	robotoMedium: 'roboto-medium, Trebuchet MS, Arial, sans-serif'
);


/***************************************************************
**   FONT-FAMILY THEME MAPPING
***************************************************************/
$theme-font-regular: #{map-get($fonts-families, roboto)};
$theme-font-medium: #{map-get($fonts-families, roboto)};
$theme-font-semibold: #{map-get($fonts-families, roboto)};
$theme-font-bold: #{map-get($fonts-families, roboto)};
$theme-font: #{map-get($fonts-families, roboto)};
$theme-font-black: #{map-get($fonts-families, roboto)};
$theme-font-light: #{map-get($fonts-families, roboto)};


/***************************************************************
**
**   MISC variables
**
***************************************************************/

$wide-ratio: (100% * 9 / 16);
@charset "utf-8";

/*********************************************************************************************
* Surcharge des styles natifs de Bootstrap
* https://getbootstrap.com/docs/4.3/components/card/ 
*
**********************************************************************************************/

.encadre-zone-outils {
    display: flex;
    padding-right: (15rem / 16);
    padding-left: (15rem / 16);
    padding-top: (28rem / 16);
    @include media-breakpoint-up(sm) {
        padding-right: (35rem / 16);
        padding-left: (35rem / 16);
    }
    @include media-breakpoint-only(md) {
        padding-bottom: (20rem / 16);
    }
    @include media-breakpoint-up(lg) {
        padding-top: 0;
    }
    @include media-breakpoint-only(xxs) {
        padding-top: (16rem / 16);
    }
    h2 {
        line-height: 0.6;
        @include media-breakpoint-only(xxs) {
            line-height: 0.8;
        }
        @include media-breakpoint-only(xs) {
            line-height: 1;
        }
        @include media-breakpoint-up(lg) {
            width: 100%;
            text-align: left;
        }
        .first {
            margin-right: (8rem / 16);
        }
        .sp-normal {
            @include useFont(gordita, 400);
            @include media-breakpoint-up(sm) {
                font-size: (32rem / 16);
            }
            text-transform: uppercase;
            @include media-breakpoint-only(xxs) {
                font-size: (18rem / 16);
            }
        }
        .sp-bold {
            @include useFont(gordita, 700);
            @include media-breakpoint-up(sm) {
                font-size: (32rem / 16);
            }
            text-transform: uppercase;
            @include media-breakpoint-only(xxs) {
                font-size: (18rem / 16);
            }
        }
    }
    .card-group {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        .card {
            border: 0;
            padding: 0;
            height: auto;
            img.card-img-top {
                background-size: cover;
                width: (70rem / 16);
                height: (70rem / 16);
                margin: (8rem / 16) auto;
                @include media-breakpoint-only(xxs) {
                    width: (43rem / 16);
                    height: (46rem / 16);
                    margin: (4rem / 16) auto;
                }
            }
            .card-body {
                padding: 0;
            }
            .h6 {
                color: #333333;
                word-break: keep-all;
                word-wrap: normal;
                @include media-breakpoint-only(xxs) {
                    font-size: (10rem / 16);
                    font-weight: bold;
                }
            }
            a {
                position: relative;
                display: inline-block;
                background-color: #ffffff;
                -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
            }
            a::after {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                box-shadow: 0 0 (31rem / 16) (5rem / 16) rgba(21, 21, 21, 0.16);
                opacity: 0;
                -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
            }
            a:hover {
                @include media-breakpoint-up(lg) {
                    background-color: #fdfdfd;
                    z-index: 5;
                    -webkit-transform: scale(1.07) translateY(3%);
                    transform: scale(1.07) translateY(3%);
                }
                text-decoration: none;
            }
            a:hover::after {
                opacity: 1;
            }
        }
    }
}
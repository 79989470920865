    .resultats-recents.resultat-sommaires {
        .prochain-tirage-titre {
            text-align: center;
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 0.2vw;
        }
        .avis-groslots {
            display: block;
            border-bottom: none;
            background-color: $entete-couleur-background;
            margin-top: 3%;
        }
        .groslot-info {
            width: auto !important;
        }
        .avis-groslots a .tirage .date,
        .avis-groslots a:hover .tirage .date {
            float: none;
            width: 100%;
            padding-top: 0;
            //font-size: 1rem;
        }
        .avis-groslots a .tirage.erreur .date,
        .avis-groslots a:hover .tirage.erreur .date {
            font-size: 0.725rem;
        }
        .avis-groslots a .tirage .date p,
        .avis-groslots a:hover .tirage .date p {
            background-image: none;
            padding-left: 0;
            padding-right: 0;
            font-family: "Lato Light", sans-serif;
            color: #000000;
            /*
            background-size: 20px;
            background-position-y: initial;
            text-align: left;
            */
        }
        .avis-groslots a .tirage .groslot-info p.groslot-valeur,
        .avis-groslots a:hover .tirage .groslot-info p.groslot-valeur {
            //font-size: 1.625rem;
        }
        .avis-groslots a .tirage .groslot-info p.groslot-plus,
        .avis-groslots a:hover .tirage .groslot-info p.groslot-plus {
            //font-size: .875rem;
            float: none;
            @include media-breakpoint-only(xxs) {
                margin-left: 0;
            }
        }
        .avis-groslots,
        .lots-progressifs {
            height: auto;
            width: 95%;
            margin: 0 auto;
            border-radius: 45px;
            .groslot-info-wrap {
                @include media-breakpoint-only(xxs) {
                    float: none;
                    width: 100%;
                    margin-top: 0;
                }
            }
            /*
            .groslot-approx {
                //margin-right: 0;
                display: block;
                margin-right: 10%;
                margin-bottom: 0;
*/
            p {
                @include media-breakpoint-only(xxs) {
                    text-align: center;
                }
            }
        }
        .avis-groslots a .tirage .date,
        .avis-groslots a:hover .tirage .date {
            display: flex;
            justify-content: center;
            margin-right: 0;
            margin-left: 0;
        }
        /*
        .avis-groslots {
            a,
            a:hover {
                .media-xxs {
                    display: none;
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 0;
                    padding-bottom: 0;
                    @include media-breakpoint-up(xs) {
                        display: flex !important;
                    }
                }
            }
        }
        */
        .avis-groslots {
            a,
            a:hover {
                .tirage {
                    .groslot-info-wrap {
                        @include media-breakpoint-only(xxs) {
                            float: none;
                            margin-top: 0;
                        }
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
                .media {
                    display: flex;
                    @include media-breakpoint-only(xxs) {
                        display: flex;
                    }
                }
                .media-xxs {
                    display: none;
                    @include media-breakpoint-up(xs) {
                        display: none;
                    }
                }
            }
        }
        .groslot-info-wrap {
            justify-content: center;
        }
        p.groslot-valeur {
            @include media-breakpoint-only(xxs) {
                font-size: 1.25rem;
            }
            @include media-breakpoint-only(xs) {
                font-size: 1.25rem;
            }
            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
            }
            @include media-breakpoint-only(lg) {
                font-size: 1.25rem;
            }
        }
        p.groslot-plus {
            @include media-breakpoint-only(xxs) {
                font-size: 1rem;
            }
            @include media-breakpoint-only(xs) {
                font-size: 1rem;
            }
            @include media-breakpoint-up(md) {
                font-size: 1rem;
            }
            @include media-breakpoint-only(lg) {
                font-size: 1rem;
            }
        }
    }
    
    .resultats-recents.resultat-sommaires .avis-groslots {
        a,
        a:hover {
            .groslot-info {
                width: auto;
            }
        }
    }
    
    .resultats-recents.resultat-sommaires {
        .avis-groslots a .tirage div.groslot-approx,
        .avis-groslots a:hover .tirage div.groslot-approx {
            margin: 0;
            width: 100%;
            p {
                text-align: center;
                font-size: .6875rem;
                font-weight: 400;
                font-family: "Lato Regular", sans-serif;
                color: #000000;
            }
        }
        .produit-223 {
            .prochain-tirage-titre {
                font-size: 1.25rem;
            }
            .groslot-info {
                flex-flow: column;
                width: auto !important;
            }
        }
        .produit-212 {
            .groslot-info.lotsGarantisAutre {
                display: block;
            }
            .float-left:nth-child(1) {
                float: none;
            }
            .float-left:nth-child(2) {
                align-self: center;
                float: none !important;
                flex: 1;
            }
            .groslot-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                div.classique {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-left: 25%;
                    margin-left: 0;
                    @include media-breakpoint-up(xxs) {
                        padding-left: 25%;
                    }
                    @include media-breakpoint-up(xs) {
                        padding-left: 18%;
                    }
                    @include media-breakpoint-up(sm) {
                        padding-left: 15%;
                    }
                    @include media-breakpoint-up(md) {
                        padding-left: 15%;
                        margin-left: 0.5rem;
                    }
                    @include media-breakpoint-up(lg) {
                        padding-left: 4.9vw;
                    }
                    @include media-breakpoint-up(xl) {
                        padding-left: 4.2vw;
                    }
                    .montantClassique {
                        line-height: 1.5rem;
                    }
                }
            }
            .avis-groslots a .tirage .groslot-info div.classique .texteClassique,
            .avis-groslots a:hover .tirage .groslot-info div.classique .texteClassique {
                margin-left: 0;
            }
            .avis-groslots a .tirage .groslot-info .superTirage,
            .avis-groslots a:hover .tirage .groslot-info .superTirage {
                margin-left: 3%;
                position: initial;
                width: 3rem;
                img {
                    display: block;
                    max-width: 3.75rem;
                    width: 100%;
                }
            }
            .avis-groslots a .tirage .groslot-info p.groslot-valeur,
            .avis-groslots a:hover .tirage .groslot-info p.groslot-valeur {
                font-size: 2rem;
            }
            .avis-groslots a .tirage .superTirage,
            .avis-groslots a:hover .tirage .superTirage {
                margin: 5% auto 0 37%;
                position: initial;
                width: 6rem;
                display: none;
                @include media-breakpoint-up(xs) {
                    margin: 5% auto 0 32%;
                }
                @include media-breakpoint-up(sm) {
                    margin: 3% auto 0 34%;
                }
                @include media-breakpoint-up(md) {
                    margin: 5% auto 0 25%;
                }
                @include media-breakpoint-up(lg) {
                    margin: 3% auto 0 28%;
                }
                @include media-breakpoint-up(xl) {
                    margin: 3% auto 0 33%;
                }
            }
        }
    }
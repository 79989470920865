    @charset "utf-8";
    .media-bouton {
        padding: 0;
        margin-top: (32rem / 16);
        @include media-breakpoint-up(xxs) {
            font-size: 0.7rem;
        }
        @include media-breakpoint-up(md) {
            font-size: 0.65rem;
        }
        @media screen and (min-width:945px) {
            font-size: 1rem;
        }
        &.btn-text {
            .btnContainer {
                width: fit-content;
                border-radius: (26rem / 16);
                display: table; //correction bug dans edge et ie
                /*padding-top: 0.625rem;
            padding-bottom: 0.625rem;*/
                &.btn-reverse-color {
                    &:hover,
                    &:focus {
                        background-image: none!important;
                    }
                }
                &.center-xs-auto {
                    @include media-breakpoint-down(sm) {
                        margin-left: auto !important;
                        margin-right: auto !important;
                    }
                }
            }
            .btn {
                height: auto;
                min-height: auto;
                padding-top: (7rem / 16);
                padding-bottom: (7rem / 16);
                /*margin-top: (20rem / 16);*/
                display: block;
                text-align: center;
                outline: none;
                /*ok pour a11y car focus change couleur du bouton */
                @include media-breakpoint-up(xxs) {
                    padding-left: (15rem / 16);
                    padding-right: (15rem / 16);
                }
                @include media-breakpoint-up(md) {
                    padding-left: (32rem / 16);
                    padding-right: (32rem / 16);
                }
                &.btn-color,
                &.btn-reverse-color {
                    border: solid 3px transparent;
                }
                &.btn-color:not(:hover),
                &.btn-color:not(:focus) {
                    color: rgb(0, 0, 0);
                    background-color: #fff;
                    box-shadow: none;
                    z-index: 2;
                    background-clip: padding-box;
                }
                &.btn-color:hover,
                &.btn-color:focus {
                    background-image: none;
                    background-color: transparent;
                    color: rgb(255, 255, 255);
                }
                &.btn-reverse-color:not(:hover),
                &.btn-reverse-color:not(:focus) {
                    background-image: none;
                    background-color: transparent;
                    color: rgb(255, 255, 255);
                }
                &.btn-reverse-color:hover,
                &.btn-reverse-color:focus {
                    color: rgb(0, 0, 0);
                }
                &.btn.texte-bouton:hover,
                &.btn.texte-bouton:focus {
                    color: #ffffff;
                    outline: none;
                }
                &.btn-outline-ternary.btn-ombrage {
                    box-shadow: 0 0 13.9px 2.1px rgba(39, 39, 39, 0.29);
                    &:hover,
                    &:focus {
                        box-shadow: 0 0 13.9px 2.1px rgba(48, 48, 48, 0.29);
                    }
                }
            }
        }
        svg {
            vertical-align: baseline;
            width: 0.8rem;
            height: 0.8rem;
            @media screen and (min-width: 945px) {
                width: 1rem;
                height: 1rem;
            }
        }
    }
@charset "utf-8";
.media-text {
    /* overflow-x: hidden;
     margin: 1vw 2vw 1vw 2vw; */
    padding: 0.25vw;
    font-size: 13px;
    color: #111111;
    @include media-breakpoint-up(lg) {
        font-size: 18px;
        /*        margin: 1vw 4vw 1vw 4vw; */
    }
    .image-title {
        background-size: cover;
        background-repeat: no-repeat;
    }
    span {
        .no-wrap,
        .lqContenuInsecable {
            white-space: nowrap;
        }
    }
    h2,
    h3,
    .h3,
    .h4 {
        color: #3a5dae;
        font-family: gordita;
        font-weight: bold;
        font-size: (18rem / 16);
        line-height: 24px;
        @include media-breakpoint-up(md) {
            font-size: (21rem / 16);
            line-height: 28px;
        }
        @include media-breakpoint-up(lg) {
            font-size: (28rem / 16);
            line-height: 37px;
        }
    }
    .rich-text {
        .lqChampRiche {
            font-family: Roboto;
            font-weight: normal;
            font-size: (13rem / 16);
            color: #373A3C;
            @include media-breakpoint-up(md) {
                font-size: (14rem / 16);
            }
            @include media-breakpoint-up(lg) {
                font-size: (18rem / 16);
            }
            p {
                margin-top: 1rem;
                margin-bottom: 0;
                img {
                    max-width: 100%;
                }
            }
            li {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }
            li::marker {
                color: #3a5dae;
            }
            a {
                &:link {
                    color: #4772d8;
                    text-decoration: none;
                }
                &:visited {
                    color: #6277a0;
                    text-decoration: none;
                }
                &:hover {
                    color: #4772d8;
                    text-decoration: underline;
                }
                &:active {
                    color: #4772d8;
                }
            }
            span.lqContenuInsecable {
                white-space: nowrap;
            }
        }
    }
    ul {
        @include media-breakpoint-down(sm) {
            padding-left: 5vw;
        }
    }
    // Dans l'édition du champ riche - classes spécifiques hors CSS Admin qui n'est pas appellée
    &.rich-text {
        &.cke_editable {
            a[data-lien-m5]::after {
                content: "[M5]";
                pointer-events: none;
                font-size: 11px;
                vertical-align: super;
            }
        }
    }
}

.over-title-text {
    display: block;
    font-size: 0.9em;
}
@charset "utf-8";

.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.extra {
                justify-content: start;
        
                .numeros {
                    margin-bottom: auto;
                    margin-top: auto;
                    padding-bottom: (30rem / 16);
                    @include media-breakpoint-up(lg) {
                        padding-bottom: (80rem / 16);
                    }
        
                    .num {
                        margin-left: 3px;
                    }
                }
                .lqZoneMessageAVenir {
                    margin-bottom: auto;
                    margin-top: auto;
                }
            }
        }
    }
}
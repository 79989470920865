.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.loto-ete .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {

   height: 65%;
   left: 2.5%;
   top: 3.1vw;

   @include media-breakpoint-up(md) {
      top: 1.6vw;

   }
   @include media-breakpoint-up(lg) {
      left: 4%;
      top: 1vw;
   }
}


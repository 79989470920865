@charset "utf-8";

/**********************************************
* _menu.scss
*
*
***********************************************/


/* À discuter - scoper dans une classe .menu ? */

// changement de langue dans Author (Edit et Preview)
// hors scope de classes (direct dans body)
.changeLangue{
    color:midnightblue;
}

#lqZoneMenuComplet {
    &.navbar-expand-lg {
        .navbar-nav {
            .megamenu-li {
                position: static;
            }
            .megamenu {
                position: absolute;
                width: 100%;
                left: 0;
                right: 0;
                padding: (15rem / 16);
                .dropdown-item {
                    white-space: normal;
                }
            }
            @include media-breakpoint-up(xl) {
                li.mobile-only {
                    display: none;
                }
            }
        }
    }  
}

html.is-ie .menu-bar span[class^=lqicon-] {
    @include media-breakpoint-down(lg) {
        font-size: 5px !important;
    }
}
@charset "utf-8";

#reader {
    margin: auto;
    max-width: 800px;
    width: calc(100% - 20px);
    
    // <div style="position: absolute; background-color: rgb(255, 255, 255); width: 5px;  height: 70%; left: 50%; margin-top: 12%;"></div>
    .vertical-line {
        background-color: rgb(255, 255, 255); 
        border-radius: 5px;
        height: 50%;
        top: 5%;
        left: 49%;
        margin-top: 12%;
        position: absolute;
        width: 3px;  
    }

    .vertical-line-haut-droite {
        background-color: rgb(255, 255, 255); 
        border-radius: 5px;
        height: 30%;
        top: 20%; 
        left: 100%; 
        position: absolute;
        width: 3px;  
    }

    .vertical-line-haut-gauche {
        background-color: rgb(255, 255, 255); 
        border-radius: 5px;
        height: 30%;
        top: 20%; 
        left:0; 
        position: absolute;
        width: 3px;  
    }

    .vertical-line-bas-droite {
        background-color: rgb(255, 255, 255); 
        border-radius: 5px;
        height: 30%;
        top: 71%; 
        left: 100%; 
        position: absolute;
        width: 3px;  
    }

    .vertical-line-bas-gauche {
        background-color: rgb(255, 255, 255); 
        border-radius: 5px;
        height: 30%;
        top: 71%; 
        left: 0; 
        position: absolute;
        width: 3px;  
    }

    // <div style="position: absolute; background-color: rgb(255, 255, 255); width: 100%; height: 5px; top: 50%;"></div>
    .horizontal-line {
        background-color: rgb(255, 255, 255); 
        border-radius: 5px;
        height: 3px; 
        position: absolute;
        top: 50%;
        width: 40%;
        left: 30%;
    }

    .horizontal-line-haut-droite {
        background-color: rgb(255, 255, 255); 
        border-radius: 5px;
        height: 3px; 
        position: absolute;
        top: 20%;
        left: 71%;
        width: 30%;
    }

    .horizontal-line-haut-gauche {
        background-color: rgb(255, 255, 255); 
        border-radius: 5px;
        height: 3px; 
        position: absolute;
        top: 20%;
        left: 0;
        width: 30%;
    }

    .horizontal-line-bas-droite {
        background-color: rgb(255, 255, 255); 
        border-radius: 5px;
        height: 3px; 
        position: absolute;
        top: 100%;
        left: 71%;
        width: 30%;
    }

    .horizontal-line-bas-gauche {
        background-color: rgb(255, 255, 255); 
        border-radius: 5px;
        height: 3px; 
        position: absolute;
        top: 100%;
        left: 0;
        width: 30%;
    }

    // Pour enlever le shading autour de la zone de scan
    // et cacher temporairement les coins délimiteurs zone de scan
    #qr-shaded-region {
        border-color: rgba(0, 0, 0, 0.0) !important;
        border-style: none !important;

        &.desired-state {
            border-style: solid !important;

        }
    }
}

#wrapperCamera {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -50px;
    margin-top: 15px;


    #iconeBack {
        z-index: 10;
        padding-left: 15px;
    }
    
    #iconeAide {
        z-index: 10;
        padding-right: 15px;
    }
    
    #instructionCamera {
        margin-bottom: 0px;
        color: white;
        z-index: 10;
        text-shadow: 0px 0px 4px black;
    }
}

/*.fullscreenCamera {
    margin: auto;
    position: fixed !important;
    width: 100%;
    height: 100%;
    left: 0;
    top: 13.25rem;
    background: rgba(51,51,51,0.7);
    z-index: 100;
}*/

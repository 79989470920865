@charset "utf-8";
.conteneur-resultats {
    background-size: 0;
    text-align: center;
    @include media-breakpoint-only(xxs) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    @include media-breakpoint-up(md) {
        background-size: contain;
    }
    &.bg-cover {
        background-size: 0;
        @include media-breakpoint-up(md) {
            background-size: cover;
        }
    }
    .encadre-zone-outils,
    .boite {
        background-color: white;
        border-radius: (40rem / 16);
        box-shadow: 0 0 (30.5rem / 16) (4.6rem / 16) rgba(21, 21, 21, 0.3);
    }
    @include media-breakpoint-up(lg) {
        .boite {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            &.gauche {
                border-bottom-right-radius: 0;
            }
            &.droite {
                border-bottom-left-radius: 0;
            }
        }
    }
}
@charset "utf-8";
.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.lotto-6-49 {
                .sectionBouleOr {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    padding-bottom: 2%;
                    .imgBoule {
                        padding-right: 0.5rem;
                    }
                    .numeroBouleOr,
                    .numeroBoule {
                        .num,
                        .num-sep {
                            font-size: (22rem / 16);
                            @include media-breakpoint-between(all, xs) {
                                font-size: (21rem / 16);
                            }
                            @include media-breakpoint-only(md) {
                                font-size: (21rem / 16);
                            }
                            @include media-breakpoint-up(lg) {
                                font-size: (23rem / 16);
                            }
                            font-weight: 700;
                            line-height: 1.2;
                            text-align: center;
                            color: #111111;
                        }
                    }
                }
            }
        }
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.lotto-6-49 .numerosBouleOr {
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
    .num,
    .num-sep {
        font-size: 1.5rem;
    }
    .imgBoule img {
        width: 2.75rem;
        height: 2.75rem;
    }
}
@charset "utf-8";

.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.sprinto {
                .resultatsSprinto {
                    .bgSprintoGauche, .bgSprintoDroite {
                        display: none;
                    }
                    .numeros {
                        //Pour enlever le quadrillé en arrière-plan
                        background-image: none !important;

                        .num:not(:last-of-type)::after {
                            content: "-";
                        }
                        .num-sep {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}


@charset "UTF-8";

$entete-couleur-background: #E5EDF5;


$btn-fontsize: 0.65rem;
$numeros-font-size: 1.5rem;
$btn-border-color: #EF333F;
$btn-color-text: black;
$btn-color-text-hover: white;
$btn-box-shadow: 0 0 6px#FF000033;
$btn-height: 2rem;
$btn-line-height: 1.625rem;
$btn-font-size: 0.70rem;
$btn-text-line-height: 1rem;

$xxs: 270px;
$md: 768px;
$xxl: 1920px;





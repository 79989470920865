.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .prix {
    margin-top: 0.9189375rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col1 .prix .numero {
    width: 50%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col1 {
    line-height: 2;
    padding-left: 5px;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros {
    max-width: 30rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col2 .prix .numero {
    display: flex;
    align-items: center;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col1 .prix .lot {
    margin-right: 0.5rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col1 .prix .numero {
    text-align: center;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col1 .prix,
.resultats-recents.resultat-sommaires .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col1 .prix .numero .num,
.resultats-recents.resultat-sommaires .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col2 .prix .lot,
.resultats-recents.resultat-sommaires .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col2 .prix .numero .num {
    font-size: 1.15rem;
    line-height: 1rem;
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
    .resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col1 .prix,
    .resultats-recents.resultat-sommaires .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col1 .prix .numero .num,
    .resultats-recents.resultat-sommaires .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col2 .prix .lot,
    .resultats-recents.resultat-sommaires .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col2 .prix .numero .num {
        font-size: 1rem;
    }
}


/*
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurNumeros .numeros .col1 {
    width: 48%;
}
*/

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurEnteteProduit .lqEnteteZoneProduit .logo img {
    margin: 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
    top: 12%;
    left: 8%;
    height: 72%;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.la-poule-aux-oeufs-dor {
    .numeros {
        margin: 4.6543125rem auto;
    }
}
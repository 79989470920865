@charset "UTF-8";
.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.CentMilleALHeure.version-2019-11-01 {
                //Lien "Voir resultats complets" sous le logo principal
                .lienResultatsComplets {
                    padding: (60rem / 16) 0;
                    @include media-breakpoint-up(lg) {
                        padding-bottom: (160rem / 16);
                    }
                    @include media-breakpoint-between(all,sm) {
                        padding-top   : (10rem / 16);
                        padding-bottom: (30rem / 16);
                    }
                }
            }
        }
    }
}
@charset "utf-8";
/* Copies de certaines fonctions issues de Bingo+*/

/*******
* - vwValue
* - rem
* - parseInt
* - mqScale
*/ 


/**
 * -- parseInt -- 
 * Parse integer value of $val
 * @param $val Value to parse as integer
 **/
@function parseInt($val) {
  @return $val / ($val * 0 + 1);
}


/**
 * rem
 * Converts a px value to REM base
 * @param $values Value in pixels to apply and convert to rem. Can be more than one value ex. for margin: 20 30 10 40
 **/
@function rem($values) {
  // define list of value to return
  $px: ();
  $rem: ();

  // Loop through each value in $values
  @each $value in $values {

    // If type of $value is not number, i.e.: !important is a string to be concatenate with the preceding value.
    @if (type-of($value) != "number") {
      $px: append($px, $value);
      $rem: append($rem, $value);
    } @else {
      // Separate unit and value
      $unit: unit($value);
      $val: parseInt($value);

      // If unit is empty, default is PX
      @if $unit == "" {
        $unit: px;
      }

      // If the value is either 0 or not a number, like auto or none, just append it to the list as-is
      @if $value == 0 or type-of($value) != "number" or ($unit != "px" and $unit != "rem") {
        $px: append($px, $value);
        $rem: append($rem, $value);
      } @else {
        // If the unit is px
        @if $unit == "px" {
          $px: append($px, $value);
          $rem: append($rem, ($val / parseInt($rem-base) + rem));
        }
          // At this point, unit is in REM
        @else {
          $px: append($px, ($val * parseInt($rem-base) + px));
          $rem: append($rem, $value);
        }
      }
    }
  }
  // output rem value(s)
  @return $rem;
}




/**
 * vwValue
 * Convert a unit to view width base
 * @param $val Value in px to convert
 * @param $vpWidth View port width in px to use for conversion. Default is 640px (low res mobile)
 **/
@function vwValue($val, $vpWidth: 750) {
  $val: parseInt($val);
  $vpWidth: parseInt($vpWidth);

  @return #{($val / $vpWidth * 100)}vw;
}



/**
 * mq-scale-value
 * Convert a unit to view width base
 * @param $val Value in px to convert
 * @param $vpWidth View port width in px to use for conversion. Default is 640px (low res mobile)
 **/
@function mq-scale-value($breakpoint, $value) {
  @if ($breakpoint == $xlarge-up) {
    @return rem($value);
  } @else if ($breakpoint == $large-up) {
    @return rem($value);
  } @else if ($breakpoint == $medium-up) {
    @return rem($value * $tablet-ratio);
  } @else {
    @return vwValue($value);
  }
}



/********************************************************************************************
* Copie de _transition.scss
*  était dans Bingoplus src\main\resources\bingoplus-templating\sources\stylesheets\sass\mixins
*  Est-ce encore utile ?
*********************************************************************************************/

// Usage:   @include transitions(width, height 0.3s ease-in-out);
// Output:  -webkit-transition(width 0.2s, height 0.3s ease-in-out);
//          transition(width 0.2s, height 0.3s ease-in-out);
//
// Pass in any number of transitions
@function unfoldTransition ($transition) {
  // Default values
  $property: all;
  $duration: .2s;
  $easing: null; // Browser default is ease, which is what we want
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i)
    } @else {
      $p: nth($defaultProperties, $i)
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}

@mixin transitions($transitions...) {
  $unfoldedTransitions: ();

  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
  }

  -webkit-transition: $unfoldedTransitions;
  transition: $unfoldedTransitions;
}
@charset "utf-8";
.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.celebration-2019,
            &.celebration-2020,
            &.celebration-2021,
            &.celebration-2022,
            &.celebration-2023,
            &.celebration-2024,
            &.celebration-2025 {
                justify-content: start;
                .lqZoneDescriptionLots {
                    font-weight: 500;
                    margin-top: (48rem / 16);
                    @include media-breakpoint-between(all, xs) {
                        font-size: (14rem / 16);
                        margin-top: (15rem / 16);
                    }
                    @include media-breakpoint-only(md) {
                        font-size: (18rem / 16);
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: (24rem / 16);
                    }
                }
                .numeros {
                    display: flex;
                    justify-content: center;
                    @include media-breakpoint-between(all, xs) {
                        margin-top: (4rem / 16);
                    }
                    .num {
                        margin: 0 (3rem / 16);
                    }
                }
                a.lienResultatsComplets {
                    margin-bottom: auto;
                    margin-top: auto;
                }
            }
        }
    }
}
@charset "utf-8";
.media-card-image {
    &.card.overlayPad {
        padding: 15px;
        @include media-breakpoint-up(md) {
            padding: 0;
        }
    }
    &.noSpaceCard {
        padding: 0 15px 0 15px;
        @include media-breakpoint-up(md) {
            padding: 0;
            margin: 0;
        }
    }
    .card-body {
        border-radius: 0;
        p.card-text {
            margin-bottom: 0;
            @include media-breakpoint-up(lg) {
                margin-bottom: 1rem;
            }
        }
        div.lqChampRiche {
            font-family: 'Roboto', sans-serif;
            @include media-breakpoint-up(xxs) {
                font-size: 13px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 18px;
            }
        }
    }
    .noSpace {
        padding: 0;
        margin: 0;
        @include media-breakpoint-up(md) {
            padding: 0;
            margin: 0;
        }
    }
    .overlaySpace {
        padding: 0;
        margin: 0;
    }
    .row {
        overflow: hidden;
        &.radius, .radius  {
            border-radius: (20rem / 16);
            @include media-breakpoint-up(md) {
                border-radius: (40rem / 16);
            }
        }
        &.radius-right-top, .radius-right-top {
            border-top-right-radius: (20rem / 16);
            @include media-breakpoint-up(md) {
                border-top-right-radius: (40rem / 16);
            }
        }
        &.radius-left-top, .radius-left-top {
            border-top-left-radius: (20rem / 16);
            @include media-breakpoint-up(md) {
                border-top-left-radius: (40rem / 16);
            }
        }
        &.radius-right-bottom, .radius-right-bottom {
            border-bottom-right-radius: (20rem / 16);
            @include media-breakpoint-up(md) {
                border-bottom-right-radius: (40rem / 16);
            }
        }
        &.radius-left-bottom, .radius-left-bottom {
            border-bottom-left-radius: (20rem / 16);
            @include media-breakpoint-up(md) {
                border-bottom-left-radius: (40rem / 16);
            }
        }
        &.noMobileRadius, .noMobileRadius {
            @include media-breakpoint-down(sm) {
                border-radius: 0;
            }
        }

    }
}
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.grande-vie .numeros {
    text-align: center;
}

.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral.grande-vie > .numeros {
   margin: 2.625rem 0;
}


.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.grande-vie .lqConteneurEnteteProduit .lqEnteteZoneProduit .lqEnteteZoneDroiteProduit {
    left: 0;
    position: relative;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.grande-vie .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
    height: 95%;
    left: 0;
    top: 1.5%;
}


.resultats-recents.resultat-sommaires  .contenantResultat .lqConteneurGeneral.grande-vie .numero_gn .num_gn {
    font-size: 1.25rem;
    width: 2rem;
    height: 2rem;
    padding-bottom: 0.1875em;
}
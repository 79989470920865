@charset "UTF-8";


.plus-options {
    .boutons-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding-top: 1rem;
    }

    .media-bouton.btn-text .btn.btn-outline-ternary.btn-ombrage {
        box-shadow: $btn-box-shadow;
    }

    .media-bouton.btn-text .btn.btn-reverse-color:hover,
    .media-bouton.btn-text .btn.btn-reverse-color:focus {
        background-color: $btn-border-color;
        box-shadow: initial;
    }

    .media-bouton.btn-text .btn.btn-reverse-color:hover,
    .media-bouton.btn-text .btn.btn-reverse-color:focus {
        color: $btn-color-text-hover;
    }

    .media-bouton.btn-text .btn.btn-reverse-color,
    .media-bouton.btn-text .btn.btn-reverse-color {
        color: $btn-color-text;
    }


    .media-bouton.btn-text .btnContainer {
        display: inline-block;
        width: 100%;

        .btn {

            height: $btn-height;
            line-height: $btn-line-height;
			min-height:2.7rem;

            span {
                display: inline-block;
                vertical-align: middle;
                line-height: 1.2;
                letter-spacing: initial;
                margin: 0 0.25rem;
                @include media-breakpoint-only(xxs) {
			        font-size: 9px;
			    }
            }
        }
    }

    .zone-boutons-titre {
        text-align: center;
        margin: 0;
        margin-top: 4%;
        font-weight: bold;
    }

    .collapsible-zone {
        height: 0;
        overflow: hidden;
    }

    .bouton-affichage-container {

        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 0;
        position: relative;
        top: 1.25rem;

        .bouton-affichage {
            display: flex;
            height: 1.8rem;
            width: 1.8rem;
            padding: 0;
            border: 0;
            background: none;
            align-items: center;
    		justify-content: center;
            
            img {
				width:1.5rem;
				height:1.5rem;
	
			}
        }

        .texte {
            display: block;
            margin-right: 5px;
            @include useFont(gordita, 500);

            &.ouvert {
                display: none;
            }
            &.ferme {
                display: inline;
            }

        }
    }


    &.ouvert {
        .collapsible-zone {
            height: auto;
        }

        .bouton-affichage-container {
            .bouton-affichage {
                transform: rotate(180deg);
                display: flex;
            }

            .texte {
                display: block;
                margin-right: 5px;

                &.ouvert {
                    display: inline;
                }
                &.ferme {
                    display: none;
                }
            }
        }
    }
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes .plus-options .bouton-affichage-container .texte {
    font-size: 15px;
}

.conteneur-flex-1-colonnes .plus-options .bouton-affichage-container .bouton-affichage {
    height: 20px;
    width: 20px;
    background-size: 14px;
    max-width: 20px;
}

.plus-options {
    .bouton-affichage-container .texte {
        font-size: 1.1875rem;
    }
}





@charset "utf-8";

/******************************************************************************************************
**
**  Section
**
**  Template script : <module>/templates/components/container/section.ftl
**
******************************************************************************************************/
.main {
  //Pour éviter un débordement par le box-shadow des cards de la galerie
  overflow: hidden;
}

.section-page {
  padding-bottom: vwValue(120);
  margin-top: vwValue(120);
  /*padding-left: 0;
  padding-right: 0;*/
  text-align: center;
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;

  &.bg-cover{
    background-size: cover;
  }

  &.media-bg-with-content {
    padding-bottom: 0;
  }

  &.no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.no-top-margin {
    margin-top: 0;
  }

  p {
    a:not(.btn) {
      white-space: nowrap;
      display: inline-block;
      border-bottom: (1rem / 16) solid;
    }
  }

  > h2,
  > .h2 {
    margin-top: vwValue(120);

    span {
      display: block;
      /*font-family: $theme-font-medium;*/
    }

    //&.light, span.light {
      /*font-family: $theme-font-light;*/
    //}

    + * {
      padding-top: vwValue(80);
      margin-top: 0;
    }

    + .note {
      font-size: vwValue(33);
      margin-top: vwValue(60);
      padding-top: 0;
      margin-top: 1.25em;
      line-height: 1.25em;
    }

    &:after {
      bottom: vwValue(-30);
      margin-left: vwValue(-95);
      left: 50%;
      display: none; // remove underline from all sections
    }
  }

  > .note {
    font-size: vwValue(28);
    /*color: $color-theme-text-alternative;*/
    margin: 1.25em auto;

    p {
      font-size: vwValue(28);
    }
  }

  > .note-bas-section p {
    font-size: vwValue(26);
    /*color: $color-theme-text-alternative;*/
  }

  > .btn,
  > .note-bas-section {
    margin-top: vwValue(120);
    margin-bottom: 0;
  }

  > .btn + .note-bas-section {
    margin-top: vwValue(60);
  }

  > h2,
  > .note {
    padding-left: vwValue(20);
    padding-right: vwValue(20);
  }

  .h3 {
    margin-top: (10rem / 16);
  }
}







    @charset "UTF-8";
    //Pour empecher le scroll quand le popup est ouvert
    html {
        overflow-y: unset;
    }

    .modal-open .modal {
        overflow: hidden;
    }

    .lqVbelform {
        overflow: auto;
        height: auto;
        margin-left: 0;
        margin-left: 0;
        font-family: 'roboto';
        font-size: (13rem / 16);
        @include media-breakpoint-up(md) {
            font-size: (14rem / 16);
        }
        @include media-breakpoint-up(lg) {
            font-size: (18rem / 16);
        }
        .hide {
            display: none;
        }
        .flex {
            display: flex;
        }
        .btnCenter {
            margin: auto;
            width: 60%;
            @include media-breakpoint-up(md) {
                margin: 0 0 0 10%;
            }
        }
        #etape1 {
            height: auto;
            .sectionErreur {
                //display: none;
                margin: 0 0 0 10%;
            }
            .lqInputVerifierBillet {
                margin: 25px 7px 12px 18%;
            }
            #zoneInfoEt1 {
                @include media-breakpoint-down(md) {
                    margin-bottom: 3vw;
                }
            }
        }
        #etape2 {
            height: auto;
            //            @include media-breakpoint-down(md) {
            padding: 15px 4% 0 4%;
            //            }
            .contenu {
                margin-left: 1vw;
                padding-top: 2vw;
                @include media-breakpoint-up(md) {
                    margin-left: 4vw;
                    padding-top: 0;
                }
                p {
                    margin-bottom: 1vw;
                }
            }
            .lqInputVerifierBillet {
                margin: 5px 7px 18px 1px;
            }
            .sectionErreur {
                width: 100%;
                padding: 0;
            }
        }
        #etape3 {
            height: auto;
            .goBack3 {
                //color: #000000;
                text-decoration: none;
                margin: 0 4% 0 4%;
            }
        }
        .radioButtonVbel {
            padding: 10px 4% 0 4%;
            .sectionradio {
                margin: 20px 0 0 18%;
                div {
                    display: block;
                    position: relative;
                    padding: 0 6px;
                    margin: 10px 0 0;
                }
                div input[type='radio'] {
                    display: none;
                }
                div label {
                    font-weight: normal;
                    &:hover {
                        cursor: pointer;
                    }
                }
                label:before {
                    content: " ";
                    display: inline-block;
                    position: relative;
                    top: 5px;
                    margin: 0 8px 0 0;
                    width: 20px;
                    height: 20px;
                    border-radius: 11px;
                    border: 2px solid #fe9f89;
                    background-color: transparent;
                    box-shadow: 0 0 7px rgb(255 0 0 / 60%);
                }
                input[type=radio]:hover+label:before {
                    border: 2px solid #ee3340;
                }
                input[type=radio]:checked+label:after {
                    border-radius: 11px;
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    top: 9px;
                    left: 10px;
                    content: " ";
                    display: block;
                    background: #ee3340;
                    box-shadow: 0 0 7px rgb(255 0 0 / 60%);
                }
            }
        }
        .lqInputVerifierBillet {
            background: none repeat scroll 0 0 #fff;
            border: 2px solid #ee3340;
            box-shadow: 0 0 3px #ee3340;
            color: #000000;
            font-size: 10.4px;
            font-family: 'gordita';
            @include media-breakpoint-up(xxs) {
                font-size: 0.7rem;
            }
            @include media-breakpoint-up(md) {
                font-size: 0.65rem;
            }
            @media screen and (min-width:945px) {
                font-size: 1rem;
            }
            font-weight: 700;
            text-transform: uppercase;
            white-space: normal;
            margin: 25px auto 12px auto;
            line-height: normal;
            letter-spacing: (0.8rem / 16);
            @include media-breakpoint-up(md) {
                margin: 25px 7px 12px 1px;
            }
            min-width: 17px;
            padding: (7rem / 16) 30px;
            text-align: center;
            text-decoration: none;
            width: fit-content;
            cursor: pointer;
            border-radius: 20px;
            &.btn-color:not(:hover),
            &.btn-color:not(:focus) //,
            // &.btn-color:focus:not(:hover)
            {
                color: rgb(0, 0, 0);
                background-color: #fff;
                z-index: 2;
                //background-clip: padding-box;
            }
            &.btn-color:hover {
                background-image: none;
                background-color: #ee3340;
                color: rgb(255, 255, 255);
            }
        }
        button.btn {
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            &.texte-bouton {
                @include useFont(gordita, 700);
                color: #1f1f1f;
                line-height: normal;
                letter-spacing: (0.8rem / 16);
                text-transform: uppercase;
                padding-left: (20rem / 16);
                padding-right: (20rem / 16);
                &:hover {
                    color: #ffffff;
                }
            }
            width:auto;
            @include media-breakpoint-between(all, xxs) {
                min-height: (31rem / 16);
                min-width: (91rem / 16);
            }
            @include media-breakpoint-up(xs) {
                min-height: (31rem / 16);
                min-width: (91rem / 16);
            }
            @include media-breakpoint-up(sm) {
                min-width: (151rem / 16);
                min-height: (51rem / 16);
            }
            @include media-breakpoint-up(md) {
                min-width: (156rem / 16);
                min-height: (56rem / 16);
            }
            @include media-breakpoint-up(lg) {
                min-width: (170rem / 16);
                min-height: (55rem / 16);
            }
        }
        .zoneInfo {
            background-color: #fff2ef;
            padding: 1.2vw 5px 1.2vw 10px;
            margin-bottom: 2vw;
            .circle {
                background-image: url('../images/svg/ic_info.svg');
                background-position: center;
                background-repeat: no-repeat;
                height: 22px;
                min-width: 22px;
            }
            .msg {
                font-size: (12rem / 16);
                padding: 0;
                @include media-breakpoint-up(md) {
                    font-size: (13rem / 16);
                    padding: 0;
                }
                @include media-breakpoint-up(lg) {
                    font-size: (16rem / 16);
                }
                margin: 0 0 0 10px;
                p {
                    margin: 0;
                    padding: 0;
                }
            }
        }
        .txtsection {
            margin: 20px 0 10px 12%;
            @include media-breakpoint-up(md) {
                margin: 0 0 0 5%;
            }
            .textesection {
                text-align: left;
                font-weight: bold;
            }
        }
        .erreurVbel {
            color: red;
            margin-top: unset;
            margin-bottom: 4px;
            //font-size: 0.85rem;
        }
        /*
    h3 {
        font-weight: bold;
        @include media-breakpoint-up(lg) {
            font-size: (32rem / 16);
        }
    }
    */
        h3,
        h4 {
            text-transform: none;
        }
        h2,
        h3,
        .h3,
        .h4 {
            font-weight: bold;
            font-size: (18rem / 16);
            line-height: 24px;
            @include media-breakpoint-up(md) {
                font-size: (21rem / 16);
                line-height: 28px;
            }
            @include media-breakpoint-up(lg) {
                font-size: (28rem / 16);
                line-height: 37px;
            }
        }
        .vosNbActions {
            float: left;
            display: flow-root;
            //margin: auto;
            width: 60%;
        }
        div.vosNbActions input {
            background-color: #ffffff;
            background-image: none;
            min-width: 160px;
            float: left;
        }
        .sectionErreur {
            float: left;
            width: 92%;
            min-height: 45px;
            font-size: (12rem / 16);
            padding: 0 5px 0 0;
            @include media-breakpoint-up(md) {
                font-size: (13rem / 16);
            }
            @include media-breakpoint-up(lg) {
                font-size: (16rem / 16);
                min-height: 60px;
            }
            .iconeWarning {
                display: inline-block;
                vertical-align: middle;
                background-image: url('../images/svg/ic_erreur_rouge.svg');
                background-position: center;
                background-repeat: no-repeat;
                height: 2.2rem;
                width: 1.7rem;
                margin-right: 2px;
                background-size: 70%;
            }
            #erreurValidation p,
            #erreurSaisie p,
            #erreurCommence0 p,
            #erreurIncomplet p {
                color: red;
                display: inline-block;
                margin-bottom: 4px;
                margin-top: 4px;
                max-width: 85%;
                vertical-align: middle;
            }
            .hideErreurSaisie,
            .hideErreurValidation,
            .hideErreurCommence0,
            .hideErreurIncomplet {
                display: none;
            }
        }
        .notebas {
            padding: 15px 4% 0 4%;
            font-size: 0.8em;
        }
        .lienVbel {
            color: #009397;
            cursor: pointer;
            background-image: url('../images/svg/ic_arrow_back.svg');
            background-position: left;
            background-repeat: no-repeat;
            height: 2.2rem;
            width: 1.7rem;
            margin-right: 5px;
            .textlien {
                padding-left: 20px;
                @include media-breakpoint-up(lg) {
                    padding-left: 28px;
                }
            }
            &::hover {
                text-decoration: underline;
            }
        }
        div.vosNbMessage {
            float: left;
            text-align: left;
            width: 100%;
            padding: 0;
            h2 {
                font-size: 1.2em;
                @include media-breakpoint-only(md) {
                    font-size: 1.25em;
                }
                font-weight: bold;
                line-height: 1.2em;
                margin: 0 0 10px 0;
            }
        }
        div.encadreVbel, #wrapperAide {
            text-align: center;
            padding: 30px 0 30px 0;
            margin: 0 4%;
            p {
                padding-bottom: 0;
            }
            background-size: 100%;
            background-position: center;
            @include media-breakpoint-down(sm) {
                background-size: 250%;
            }
            .encadreVbelInt {
                background-color: #fff;
                margin: auto;
                padding: 0;
                max-width: 500px;
                @include media-breakpoint-up(md) {
                    padding: 1rem 0 0 0;
                }
                border: 2px solid #8fa7ff;
                img {
                    margin: 0 auto;
                    width: 30vw;
                    min-width: 180px;
                    max-width: 250px;
                    @include media-breakpoint-up(md) {
                        width: 15vw;
                    }
                }
                #message {
                    font-weight: bold;
                    text-transform: none;
                }
                #nomProduit {
                    margin-bottom: 0;
                }
                #msgNoCtrl {
                    font-size: 0.8em;
                    margin-bottom: 0;
                }
            }
        }
        .messageVbel {
            width: 90%;
            padding: 5%;
            margin-top: 120px;
            display: none;
            p {
                text-align: center;
            }
        }
        #lqZoneNumeroControle {
            float: left;
            #lqNumeroControle {
                min-width: 300px;
                @include media-breakpoint-up(lg) {
                    min-width: 330px;
                }
                height: 40px;
                padding: 3px;
                border: 2px solid #000000;
                font-size: 0.95rem;
                outline: none;
                border-radius: 8px;
                background-color: #f7f7f7;
            }
            #lqNumeroControle.border-red {
                border-color: #c10000;
                border-width: 4px;
                outline: none;
            }
        }
        .masque-coquille {
            position: relative;
            display: inline-block;
            white-space: pre;
            width: 100%;
        }
        .masque-coquille .masque-superpose {
            background-color: transparent;
            position: absolute;
            left: 3px;
            z-index: 0;
            display: inline-block;
            bottom: 3px;
        }
        div#lienFSG,
        div#lienFSGAVenir {
            background-color: #FFF3F1;
            margin: 0 auto auto auto;
            padding: 10px;
            font-size: 1em;
            max-width: 500px;
            @include media-breakpoint-down(xs) {
                margin-left: 4%;
                margin-right: 4%;
            }
            img {
                max-width: 122px;
                margin-left: auto;
                margin-right: auto;
                @include media-breakpoint-up(md) {
                    width: 15vw;
                }
                @include media-breakpoint-down(md) {
                    width: 15vw;
                }
                @include media-breakpoint-down(xs) {
                    width: 90px;
                }
            }
            .encadreFSG {
                margin-right: auto;
                margin-left: 10px;
                #nomProduitSelections {
                    text-transform: uppercase;
                    font-weight: bold;
                }
                #lienSelections {
                    color: #009397;
                }
                p {
                    margin-bottom: 0.5rem;
                }
            }
            .FSGFlex {
                display: flex;
                @include media-breakpoint-down(xs) {
                    align-items: center;
                    font-size: 0.9em;
                }
            }
        }
        div#encadre2eChance {
            margin: 35px auto auto auto;
            padding: 15px 10px;
            font-size: 1em;
            text-align: center;
            border: 2px #d6a3e4 solid;
            max-width: 500px;
            @include media-breakpoint-down(xs) {
                margin-left: 4%;
                margin-right: 4%;
            }
            .containerMessage2eChance {           
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include media-breakpoint-up(sm) {
                    align-items: center;
                    justify-content: space-around;
                    flex-direction: row;
                }
            }
 
            img {
                margin-top: -60px;
                max-width: 160px;
            }
            #message2eChance {
                margin-bottom: 0;
                width: 70%;
                text-align: left;
                line-height: 1.25;
            }
            .bouton2eChance {
                border-color: #7532b6;
                box-shadow: 0 0 3px #7532b6;
                background-color:  #7532b6;
                margin: 10px auto 0 15px;
                color: white;
                &:hover {
                    background-color: white;
                    color: black;
                }
                @include media-breakpoint-up(sm) {
                    margin-top: 0;
                }
            }
            #formParticiper {
                margin-left: auto;
            }
        }

        div.lienReclamation {
            background-color: #203393;
            margin: 20px auto auto auto;
            padding: 15px 10px;
            font-size: 1em;
            text-align: left;
            max-width: 500px;
            //display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-image: url(../images/feux-artifices-rel.png);
            background-position: 100% 100%;
            background-size: 60%;
            background-repeat: no-repeat;
            @include media-breakpoint-up(sm) {
                align-items: center;
                justify-content: space-around;
                flex-direction: row;
                background-position: right;
                background-size: 50%;
                background-position: 100% 30%;
            }
            img {
                margin-top: -60px;
                max-width: 160px;
            }
            .boutonReclamer {
                border-color: #2755ff;
                box-shadow: 0 0 3px #2755ff;
                margin: 0 auto 0 auto;
                &:hover {
                    background-color: #2755ff;
                }
                font-size: 0.7rem;
                @include media-breakpoint-up(sm) {
                    font-size: 0.8rem;
                }
            }
            #messageReclamation {
                width: 80%;
                line-height: 1.25;
                @include media-breakpoint-up(sm) {
                    width: 50%;
                }
            }
            #texteReclamation, #titreReclamation {
                margin-bottom: 0;
                color: white;
            }
            #formReclamation {
                margin-left: auto;
                margin-top: 10px;
                @include media-breakpoint-up(sm) {
                    margin-top: 0;
                }
            }
        }

        //Style pour le popup FSG
        .modal-body {
            padding: 0;
        }
        .fermerFSG, .fermerCamera {
            position: absolute;
            height: 40px;
            width: 60px;
            margin-top: 8px;
            right: 5px;
            //margin-right: 30px;
        }
        #iframeFSG {
            width: 100%;
            height: 100vh;
            //border: 0;
        }
        #contenuModalCamera {
            width: 100%;
            height: 75vh;
            margin-top: 10px;
        }
        .modal {
            position: fixed;
            top: 50%;
            left: 50%;
            //z-index: 100001;

        }

        #popupCamera {
            z-index: 100001;
            border-radius: 1.25rem 1.25rem 0 0;
            transform: translate(-50%, -25%); //Ici pour changer l'animation de la modale
            //transform: translate(-50%, -40%);
        }

        .modal.fade .modal-dialog {
            transition: transform 0.3s ease-out;
            transform: translate(0, 350px);
        }

        .modal.show .modal-dialog {
            transform: none;
          }

        #popupSelection {
            z-index: 100002;
            transform: translate(-50%, -50%); //Ici pour changer l'animation de la modale
        }

        .modal-dialog {
            margin: 0;
            @include media-breakpoint-up(sm) {
                margin: auto;
                width: 60%;
            }
        }
        .modal-content {
            border: 0;
        }
    }
    
    .modal-backdrop {
    	z-index: 100000;
    }
    /**** DÉBUT : Styles utilisés par masque-saisie.js *****/
    
    .masque-coquille .masque-superpose .invisible {
        visibility: hidden;
    }
    
    .masque-coquille .masque-superpose .masque {
        color: #313a45;
    }
    
    .masque-coquille input {
        text-transform: uppercase;
        background-color: transparent;
    }

    #vbelTab {
        border-bottom: 1px solid black;
        margin: 10px 4% 10px 4%;
        .nav-item {
            flex: 1 1 0px;
            text-align: center;

            .nav-link {
                margin: 0 1rem 0 1rem;
                text-align: center;
            }
        }
    }

    .imgBouton {
        img {
            padding-left: 25px;
            background: url("https://static.thenounproject.com/png/101791-200.png") no-repeat left;
            background-size: 20px;
        }
    }

    #vbelTab.nav {
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    #verifManuelle-tab, #verifCamera-tab {
        color: rgb(55, 58, 60);
        font-weight: bold;
        line-height: 1;
    }

    #verifManuelle-tab.active, #verifCamera-tab.active {
        border-bottom: 5px solid rgb(238, 51, 64)
    }

    #selectionCamera {
        padding: 10px 4% 0 4%;
    }

    #verifCamera {
        text-align: center;
        p {
            text-align: left;
        }
    }

    #iconeCamera {
        content: url('../images/svg/ic_camera.svg');
    }

    .lqInputVerifierBillet.iconeCameraBouton {
        background: url('../images/svg/ic_camera.svg') no-repeat 5%;
        background-size: 20px;
        padding-left: 40px;
    }

    #iconeManuelle {
        content: url('../images/svg/ic_manuelle.svg');
    }

    //Doit être utilisé en même temps que is-mobile dans lq.vbel.js, sinon on a un flash en desktop des tabs
    #vbelTab, #verifCamera {
        @include media-breakpoint-up(lg) {
            visibility: hidden;        
        }
    }

    #contenuModalCamera {
        .goBack3 {
            display: none;
        }
        overflow-x: hidden;
        overflow-y: auto;
    }

    .close {
        opacity: 1;
        z-index: 10000;
    }

    #qr-shaded-region + div {
        display: none !important; //Contournement pour enlever une partie de l'UI pas configurable du scan par caméra.
    }

    /*** iPhone and iOS Form Input Zoom Fixes ***/
    /* Fix Input Zoom on devices older than iPhone 5: */
    
    @media screen and (device-aspect-ratio: 2/3) {
        input[type="text"] {
            font-size: 16px;
        }
    }
    /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
    
    @media screen and (device-aspect-ratio: 40/71) {
        input[type="text"] {
            font-size: 16px;
        }
    }
    /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
    
    @media screen and (device-aspect-ratio: 375/667) {
        input[type="text"] {
            font-size: 16px;
        }
    }
    /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
    
    @media screen and (device-aspect-ratio: 9/16) {
        input[type="text"] {
            font-size: 16px;
        }
    }
    /**** FIN : Styles utilisés par masque-saisie.js *****/
    /*    
    .conteneur-arrondis {
        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }
   
*/
            @charset "utf-8";
            .contenantLots-progressifs {
                padding: (10rem / 16) 0;
                @include useFont(gordita);
                a,
                a:hover {
                    text-decoration: none;
                    color: #181818;
                    width: 100%;
                    align-items: flex-start;
                    .media {
                        display: flex;
                        @include media-breakpoint-only(xxs) {
                            display: none;
                        }
                    }
                    .media-xxs {
                        /*display: flex;*/
                        padding-left: 25px;
                        padding-right: 25px;
                        padding-top: 0;
                        padding-bottom: 10px;
                        @include media-breakpoint-up(xs) {
                            display: none;
                        }
                        .align-self-start {
                            width: 40%;
                        }
                    }
                    .lots-progressifs {
                        height: (255rem / 16);
                        @include media-breakpoint-only(xs) {
                            height: (180rem / 16);
                        }
                        @include media-breakpoint-only(xxs) {
                            flex-wrap: wrap;
                            height: (208rem / 16);
                        }
                        .zoneBouton {
                            @include media-breakpoint-only(xxs) {
                                width: auto;
                                flex: 1;
                                align-content: flex-start;
                            }
                        }
                        img.imgproduit {
                            width: 100%;
                            @include media-breakpoint-only(xxs) {
                                float: left;
                            }
                            @include media-breakpoint-only(xl) {
                                flex: 1;
                                width: 90%;
                            }
                        }
                        .infoProgressif,
                        .infoProgressif2 {
                            flex: 1;
                            @include media-breakpoint-only(xxs) {
                                margin-top: 0;
                            }
                            .titreProgressif {
                                @include useFont(gordita, 800);
                                font-size: (16rem / 16);
                                color: #000000;
                                @include media-breakpoint-only(xxs) {
                                    @include useFont(gordita, 700);
                                    margin: 0;
                                    padding-top: 30px;
                                    text-align: left;
                                    font-size: (15rem / 16);
                                }
                                @include media-breakpoint-up(sm) {
                                    font-size: (22.5rem / 16);
                                }
                                @include media-breakpoint-only(xs) {
                                    padding-bottom: 10px; //RR
                                }
                                @include media-breakpoint-up(md) {
                                    font-size: (30rem / 16);
                                    margin-top: (25rem /16);
                                }
                                @include media-breakpoint-up(lg) {
                                    color: #1f1f1f;
                                    margin-bottom: (23rem / 16);
                                }
                            }
                            .dateProgressif {
                                text-transform: uppercase;
                                margin-bottom: 0;
                                @include useFont(gordita, 500);
                                font-size: (10rem / 16);
                                @include media-breakpoint-up(sm) {
                                    font-size: (14rem / 16);
                                }
                                @include media-breakpoint-only(xxs) {
                                    margin: 0 !important;
                                    font-size: (9rem / 16);
                                }
                                p {
                                    padding-left: (23rem / 16);
                                    padding-right: (10rem / 16);
                                    margin-bottom: (5rem / 16);
                                    margin-top: (-10rem / 16);
                                    text-align: left;
                                    background-image: url('../images/svg/ic_temps.svg');
                                    background-repeat: no-repeat;
                                    margin-left: (2rem / 16);
                                    @include media-breakpoint-up(xxs) {
                                        background-size: 18px 18px; //Il faut préciser pour que ca fonctionne avec ie
                                    }
                                    @include media-breakpoint-up(md) {
                                        background-size: 20px 20px; //Il faut préciser pour que ca fonctionne avec ie
                                    }
                                    &.margeDate {
                                        @include media-breakpoint-up(md) {
                                            margin-top: (-10rem / 16);
                                        }
                                        @include media-breakpoint-only(xxs) {
                                            position: relative;
                                            left: 0px;
                                        }
                                    }
                                    background-position-y: center;
                                    line-height: 1.8;
                                }
                            }
                            p.margeMsgLong {
                                margin-top: 0;
                                background-position-y: 0.1rem;
                                @include media-breakpoint-up(md) {
                                    margin-bottom: (33rem / 16);
                                }
                            }
                            p.margeMsg {
                                background-position-y: 0.1rem;
                                @include media-breakpoint-only(md) {
                                    margin-top: (33rem / 16);
                                    margin-bottom: (30rem / 16);
                                }
                                @include media-breakpoint-only(lg) {
                                    margin-top: 0;
                                    margin-bottom: 0;
                                    height: 85px;
                                }
                                @include media-breakpoint-up(xl) {
                                    margin-top: (33rem / 16);
                                    margin-bottom: (28rem / 16);
                                }
                                @include media-breakpoint-only(xs) {
                                    margin-bottom: 30px;
                                    padding-top: 0.1rem;
                                }
                                @include media-breakpoint-only(sm) {
                                    margin-top: 20px;
                                    margin-bottom: 40px;
                                }
                                @include media-breakpoint-only(xxs) {
                                    text-transform: uppercase;
                                    width: 100%;
                                    font-size: (14rem / 16);
                                    clear: both;
                                }
                            }
                            .montantProgressif {
                                @include useFont(gordita,
                                800);
                                font-size: (24rem / 16);
                                color: #5c1015;
                                margin-bottom: (15rem / 16);
                                @include media-breakpoint-only(xxs) {
                                    width: 100%;
                                    margin-bottom: 0;
                                }
                                @include media-breakpoint-only(sm) {
                                    font-size: (36.5rem / 16);
                                }
                                @include media-breakpoint-up(md) {
                                    font-size: (39.5rem / 16);
                                    margin-bottom: (5rem / 16);
                                }
                            }
                            .progressif-approx {
                                display: inline-block;
                                align-self: flex-end;
                                margin: (10rem / 16) (15rem / 16) (10rem / 16) 0;
                                vertical-align: bottom;
                                @include useFont(gordita);
                                font-size: (11rem / 16);
                                @include media-breakpoint-only(xxs) {
                                    margin: 0 0 10px 0;
                                }
                                @include media-breakpoint-only(xs) {
                                    font-size: (10rem / 16);
                                }
                                @include media-breakpoint-up(md) {
                                    font-size: (14rem / 16);
                                    margin-right: (100rem / 16);
                                }
                                @include media-breakpoint-only(lg) {
                                    margin-right: (15rem / 16);
                                }
                                p {
                                    margin-bottom: 0;
                                    text-align: right;
                                    width: 100%;
                                    @include media-breakpoint-up(lg) {
                                        color: #303030;
                                    }
                                }
                            }
                        }
                        .infoProgressif {
                            @include media-breakpoint-only(xxs) {
                                padding-top: 0px;
                            }
                        }
                        .infoProgressif2 {
                            clear: both;
                        }
                    }
                }
                .margeTopBouton {
                    @include media-breakpoint-down(xs) {
                        margin: (3rem / 16) (5rem / 16) (10rem / 16);
                        font-size: (11.5rem / 16);
                        padding-left: (36rem / 16);
                        padding-right: (35rem / 16);
                        padding-top: (12rem / 16);
                        padding-bottom: (11rem / 16);
                    }
                    @include media-breakpoint-up(md) {
                        margin-top: (10rem / 16);
                    }
                    @include media-breakpoint-up(lg) {
                        margin-top: (24rem / 16);
                    }
                    @include media-breakpoint-up(xl) {
                        margin-top: (28rem / 16);
                    }
                }
            }
html.root-resultats-recents {
   @include fluid-type($xxs, $xxl, 12px, 16px);
}
.resultats-recents {

    p.avertissement {
        background-color: yellow;
        padding: 1rem;
    }

    &.resultat-sommaires .contenantResultat {
        min-height: 0;
    }

    .produit-placeholder {
        background-color: white;
        overflow: hidden;
        border-radius: 25px;
        margin-bottom: 2rem;
        padding-bottom: 0;
        //max-height: 428px;
        box-shadow: 0px 5px 25px #00000029;
    }



    .lqConteneurEnteteProduit {
        margin-bottom: 0;
    }

    .collapsible-zone {
        p {

            /*font-weight: bold;*/
            /*margin-top: 4%;*/
            margin-bottom: 0;
            text-align: center;
        }
    }

    .boutons-container.vide {
        height: 1px;
    }

    .bouton-affichage-container .texte {
        cursor: pointer;
        font-weight: bold;
    }
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes {
    .numeros, .lqZoneMessageAVenir, a.lienResultatsComplets {
       // font-size: 3px;
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral {


    .lqZoneMessageAVenir, a.lienResultatsComplets {
        padding: 0;
        text-align: center;
        font-size: 20px;

        .num {
            font-size: 20px;
            margin-left: 0;
        }
    }

    > .numeros {
        margin-bottom: 6.25rem;
        margin-top: 7rem;
    }
}

.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral > .numeros {
    margin: 4.34375rem auto;
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes .contenantResultat .lqConteneurGeneral .numeros,
.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes .contenantResultat .lqConteneurGeneral .lqZoneMessageAVenir,
.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes .contenantResultat .lqConteneurGeneral a.lienResultatsComplets {
    @include fluid-type($xxs, $xxl, 1rem, 1.5rem);
}


.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral a.lienResultatsComplets {
    margin: 7.03125rem 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .remorque {


    .lqZoneMessageAVenir {
        text-align: left;
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit.remorque {

    justify-content: space-around;

    &,
    &.enBlackout {
        .logo img.noir {
            width: 7.925rem;
            max-width: none;
        }
    }
}

.resultats-recents .contenantResultat.hasRemorque {

    .lqConteneurGeneral .lqConteneurEnteteProduit {
        margin-bottom: 0;
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .plus-options {
    margin-bottom: 9%;
}

.resultats-recents.resultat-sommaires .contenantResultat.plusOptionsVide .plus-options {
    visibility: visible;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.banco .lqZoneProduit.remorque .lqZoneMessageAVenir {
    margin: 0;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneMessageAVenir,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral a.lienResultatsComplets .num {
    margin: 7.03125rem 0;
}


.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit.remorque {
    margin-top: 0;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneProduit.remorque .numeros {
    text-align: right;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .lqZoneMessageAVenir,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral a.lienResultatsComplets {
    line-height: 1rem;
}

.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral .lqZoneMessageAVenir,
.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral a.lienResultatsComplets {
    margin: 4.75rem 0;
}

.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral .remorque .lqZoneMessageAVenir,
.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral .remorque a.lienResultatsComplets {
    margin: 1.5rem auto !important;
    @include media-breakpoint-down(xs) {
        padding-left: 10px;
    }
}
.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral .remorque .numeros{
    margin: 0 !important;
}

.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral .remorque .lqZoneMessageAVenir {
    font-weight: 500;
}

.resultats-recents.resultat-sommaires .contenantResultat.hasRemorque .lqConteneurGeneral .remorque .lqZoneMessageAVenir {
    margin-bottom: 0 !important;
}

.resultats-recents.resultat-sommaires {
    .contenantResultat .lqConteneurGeneral {


        .lqZoneMessageAVenir, a.lienResultatsComplets {
            font-size: 1.25rem;
        }

        .lqZoneMessageAVenir, a.lienResultatsComplets .num {
            font-size: 1.25rem;
        }

        .remorque .lqZoneMessageAVenir {
            @include fluid-type(1257px, 1920px, 11px, 20px)
        }
    }
}

.resultats-recents.resultat-sommaires .conteneur-flex-1-colonnes,
.resultats-recents.resultat-sommaires .conteneur-flex-2-colonnes {
    .contenantResultat .lqConteneurGeneral {


        .lqZoneMessageAVenir, a.lienResultatsComplets {
            font-size: 1.25rem;
        }

        .lqZoneMessageAVenir, a.lienResultatsComplets .num {
            font-size: 1.25rem;
        }

        .remorque .lqZoneMessageAVenir {
            font-size: 1rem;
			margin: 0 !important;
        }
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .numeros .num,
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral .numeros .num-sep {
    font-size: 1.5rem;
}
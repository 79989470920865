.media-video {
    .content-inner {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56%;

        .col-md-6 & {
            @include media-breakpoint-up(lg) {
                transform: translateY(+17%);
            }
            @include media-breakpoint-up(xl) {
                transform: translateY(+0%);
            }
        }
        




        &.embed-responsive-9by16 {
            width: 43%;
            padding-bottom: 80%;
            left: 30%;
            @include media-breakpoint-down(sm) {
                left: 0;
            }
            .conteneur-galerie & {
                width: 54%;
                padding-bottom: 100%;
                left: 25%;
            }

        }
        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }
}
@charset "utf-8";

.resultat-sommaires{
	padding: (20rem / 16);
	background-image: linear-gradient(to right bottom, #f4f4f4, #f5f5f5, #f7f7f7, #f8f8f8, #faf9f9);
	border-radius: (40rem / 16);
	
	@include media-breakpoint-up(lg){
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		&.gauche {
			border-bottom-right-radius: 0;                
		}
	}
}
                                @charset "utf-8";
                                .conteneur-tableau {
                                    margin-bottom: 24px;
                                    @include media-breakpoint-up(md) {
                                        margin-bottom: 40px;
                                    }
                                    @include media-breakpoint-up(lg) {
                                        padding-left: 0vw;
                                        padding-right: 0vw;
                                    }
                                    p a {
                                        &:link,
                                        &:visited,
                                        &:hover,
                                        &:active {
                                            text-decoration: underline;
                                        }
                                    }
                                    table.composante-tableau {
                                        padding-top: 16px;
                                    }
                                    table th:first-child {
                                        border-radius: 0 0 0 0;
                                    }
                                    table.composante-tableau,
                                    .composante-tableau-onglet-mobile table {
                                        width: 100%;
                                        border-collapse: collapse;
                                        tbody {
                                            background: #ffffff;
                                            tr.fonce {
                                                &:nth-child(odd) {
                                                    background: #f3f3f6;
                                                    border-bottom: none;
                                                    .magnolia-editor & {
                                                        background: #ffffff;
                                                    }
                                                }
                                                &:nth-child(even) {
                                                    background: #ffffff;
                                                    border-bottom: none;
                                                }
                                            }
                                            tr.pale:nth-child(even) {
                                                background: #f3f3f6;
                                                border-bottom: none;
                                                .magnolia-editor & {
                                                    background: #ffffff;
                                                }
                                            }
                                            tr.pale:nth-child(odd) {
                                                background: #ffffff;
                                                border-bottom: none;
                                            }
                                            //tr:last-child {
                                            //    border-bottom: none;
                                            //}
                                            tr.total {
                                                background: #dcdde4;
                                                border-bottom: none;
                                                font-size: 10px;
                                                font-weight: bold;
                                                @include media-breakpoint-up(md) {
                                                    font-size: 14px;
                                                }
                                            }
                                            tr.sousTitre {
                                                background: #f0f2ff;
                                                border-bottom: none;
                                                font-family: 'Roboto';
                                                font-weight: bold;
                                                font-size: 10px;
                                                @include media-breakpoint-up(md) {
                                                    font-size: 13px;
                                                }
                                            }
                                            tr {
                                                background: #ffffff;
                                                //border-bottom: solid #cfcfcf 1px;
                                                font-family: 'Roboto', sans-serif;
                                                font-size: 10px;
                                                @include media-breakpoint-up(md) {
                                                    font-size: 13px;
                                                }
                                                &.border-bottom {
                                                    border-bottom: solid #cfcfcf 1px;
                                                }
                                                &.themedBorderBottom {
                                                    border-bottom: solid #000000 4px;
                                                    th {
                                                        background: linear-gradient(to right, #141b4d, #000B78);
                                                        color: #ffffff;
                                                        border-radius: 10px 10px 0 0;
                                                        p>* {
                                                            color: #ffffff;
                                                        }
                                                    }
                                                }
                                                &.entete {
                                                    border-bottom: solid #000000 4px;
                                                    th {
                                                        color: #373A3C;
                                                        background: #ffffff;
                                                        font-size: 10px;
                                                        font-family: 'Roboto';
                                                        font-weight: bold;
                                                        @include media-breakpoint-up(md) {
                                                            font-size: 13px;
                                                        }
                                                    }
                                                }
                                                th.tableau-titre {
                                                    color: #ffffff;
                                                    background-image: linear-gradient(to right, #141b4d, #000B78);
                                                    border-radius: (20rem / 20) (20rem / 16) 0 0;
                                                    font-family: 'Gordita Medium', sans-serif;
                                                    @include media-breakpoint-up(xxs) {
                                                        font-size: 13px;
                                                    }
                                                    @include media-breakpoint-up(md) {
                                                        font-size: 14px;
                                                    }
                                                }
                                                td,
                                                th {
                                                    padding: 8px 4px;
                                                    &.backgroundCellule {
                                                        background-color: #e1e1e1;
                                                    }
                                                    @include media-breakpoint-up(md) {
                                                        padding: 16px 8px;
                                                    }
                                                    p {
                                                        margin-bottom: 0px;
                                                    }
                                                }
                                                .vertical-align-haut {
                                                    vertical-align: top;
                                                }
                                                .vertical-align-centre {
                                                    vertical-align: middle;
                                                }
                                                .vertical-align-bas {
                                                    vertical-align: bottom;
                                                }
                                                .horizontal-align-gauche {
                                                    text-align: left;
                                                }
                                                .horizontal-align-centre {
                                                    text-align: center;
                                                }
                                                .horizontal-align-droite {
                                                    text-align: right;
                                                }
                                            }
                                        }
                                    }
                                    table.composante-tableau.mobile-cache {
                                        display: none;
                                        @include media-breakpoint-up(md) {
                                            display: block;
                                        }
                                    }
                                    .composante-tableau-onglet-mobile {
                                        display: block;
                                        padding-top: 16px;
                                        padding-bottom: 16px;
                                        .nav-tabs {
                                            margin: 0;
                                            a {
                                                margin: 0;
                                                border: solid #000000 1px;
                                                p {
                                                    margin-bottom: 0px;
                                                }
                                            }
                                        }
                                        .tab-content {
                                            padding-top: 0px;
                                        }
                                        @include media-breakpoint-up(md) {
                                            display: none;
                                        }
                                    }
                                    .tableau-legende {
                                        font-size: (font-size -1);
                                        margin-top: 12px;
                                        @include media-breakpoint-up(md) {
                                            margin-top: 16px;
                                        }
                                        p {
                                            margin-bottom: 8px;
                                            @include media-breakpoint-up(md) {
                                                margin-bottom: 12px;
                                            }
                                        }
                                    }
                                }
@charset "utf-8";
.notification-container {
    text-align: left;
    display: none;
    font-size: (10rem/16);
    @include media-breakpoint-up(xs) {
        font-size: (13rem/16);
    }
    @include media-breakpoint-up(lg) {
        font-size: (17rem/16);
    }
    &.active,
    &.enModeEdition {
        display: block;
    }
    p {
        word-wrap: break-word;
    }
    .close {
        background-image: url('../images/svg/bloc-alert/fermer.svg');
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: 50% 50%;
        padding: 1rem;
        opacity: 0.8;
    }
    .alert {
        margin-bottom: (8rem/16);
        @include media-breakpoint-down(md) {
            padding-left: 25px;
            padding-right: 25px;
        }
        .lqChampRiche {
            padding-right: 5px;
            a {
                &:link,
                &:visited,
                &:hover,
                &:active {
                    color: inherit;
                }
            }
        }
    }
    .alert-warning {
        border: 1px solid #707070;
        background-color: #D04845;
    }
    .alert-primary {
        border: 1px solid #707070;
        background-color: #a8dad8;
    }
    .bg-cover {
        background-size: cover;
    }
}
.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.celebration-2023 .lqConteneurEnteteProduit .lqEnteteZoneProduit a.logo img {
    height: 30%;
    left: 8%;
    top: 17%;
    @include media-breakpoint-up(xs) {
        height: 30%;
    }
    @include media-breakpoint-up(sm) {
        height: 55%;
    }
    @include media-breakpoint-up(lg) {
        height: 30%;
    }
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.celebration-2023>.lqZoneDescriptionLots {
    font-size: 1rem;
    line-height: 1rem;
    margin: 0 auto;
    margin-top: 6.28125rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.celebration-2023 .numeros {
    margin-bottom: 5.03125rem;
    margin-top: 0;
    padding: 0;
    width: auto;
    display: flex;
    .num {
        margin-left: 0;
        line-height: 1.5rem;
    }
}

.resultat-sommaires .contenantResultat .lqConteneurGeneral.celebration-2023 .numeros .num,
.resultat-sommaires .contenantResultat .lqConteneurGeneral.celebration-2023 .numeros .num-sep {
    line-height: 1.5rem;
    display: block;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.celebration-2023 .numeros {
    line-height: 1.5rem;
}

.resultats-recents.resultat-sommaires .contenantResultat .lqConteneurGeneral.celebration-2023 .numeros {
    margin-top: 1.25rem;
}
@charset "utf-8";
.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.grande-vie {
                .text_gn {
                    height: (45rem / 16);
                }
                .numero_gn {
                    margin-top: (10rem / 16);
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    .num_gn {
                        //Pour enlever le cercle original
                        background-image: none !important;
                        width: (38rem / 16);
                        height: (38rem / 16);
                        border-radius: 50%;
                        background-color: #313a45;
                        border: (2rem / 16) solid #fff;
                        box-shadow: 0 0 0 (2rem / 16) #313a45;
                        margin-left: (5rem / 16);
                        font-size: (23rem / 16);
                        @include media-breakpoint-between(all, xs) {
                            width: (34rem / 16);
                            height: (34rem / 16);
                            font-size: (21rem / 16);
                        }
                        @include media-breakpoint-only(md) {
                            width: (40rem / 16);
                            height: (40rem / 16);
                            font-size: (28rem / 16);
                        }
                        @include media-breakpoint-up(lg) {
                            width: (42rem / 16);
                            height: (42rem / 16);
                            font-size: (30rem / 16);
                            padding-bottom: (3rem / 16);
                        }
                        font-weight: bold;
                        color: #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .lienResultatsComplets {
                    @include media-breakpoint-up(md) {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}
@charset "utf-8";

/*********************************************************************************************
* Surcharge des styles natifs de Bootstrap
* https://getbootstrap.com/docs/4.3/components/card/
*
**********************************************************************************************/

.titreOngletPromo {
    margin-bottom: 1rem;
}

.promotions {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(md) {
        padding-left: 5vw;
        padding-right: 5vw;
    }
    @include media-breakpoint-up(lg) {
        padding-left: 4vw;
        padding-right: 4vw;
    }
    .card {
        margin-bottom: 1rem;
        @include media-breakpoint-up(lg) {
            margin-bottom: 2rem;
        }
    }
    .conteneur-flex-1-colonnes {
        display: block;
        @include media-breakpoint-up(lg) {
            display: none;
        }
        .column {
            width: 100%;
            margin: 0;
        }
    }
    .conteneur-flex-2-colonnes {
        display: none;
        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }
    .column {
        display: flex;
        flex-direction: column;
        margin: 0 0.5vw;
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: 50%;
        }
    }
    #btn-pluspromo {
         :hover {
            cursor: pointer;
        }
    }
}

.gagnants {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    @include media-breakpoint-up(md) {
        padding-right: 2vw;
        padding-left: 1vw;
    }
    @include media-breakpoint-up(lg) {
        padding-right: 3vw;
        padding-left: 2vw;
    }
    .conteneur-card-promo {
        opacity: 1;
        padding-top: (12rem / 16);
        @include media-breakpoint-up(md) {
            margin-left: 0;
            margin-bottom: 0;
            padding-top: 0;
        }
        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }
        .card {
            //height: 320px;
            min-height: 320px;
            overflow: hidden;
            @include media-breakpoint-up(md) {
                //height: 350px;
                min-height: 350px;
            }
            @include media-breakpoint-up(lg) {
                //height: 545px;
                min-height: 545px;
            }
            margin-bottom: (14rem /16);
            .card-body {
                height: 200px;
                @include media-breakpoint-up(xxs) {
                    height: 120px;
                }
                @include media-breakpoint-up(xs) {
                    height: 160px;
                }
                @include media-breakpoint-up(sm) {
                    height: 135px;
                }
                @include media-breakpoint-up(md) {
                    height: 180px;
                }
                @include media-breakpoint-up(lg) {
                    height: 370px;
                }
                @include media-breakpoint-up(xl) {
                    height: 310px;
                }
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                padding-bottom: 3rem;
                .rich-text,
                .rich-text p,
                .lqChampRiche {
                    font-family: Gordita;
                    font-size: 10px;
                    @include media-breakpoint-up(md) {
                        font-size: 12px;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 17px;
                    }
                }
                #btn-gagnants {
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    transform: translate(-50%);
                    min-width: 85%;
                    border: none;
                    background-color: transparent;
                    font-style: normal;
                    font-variant: normal;
                    font-weight: bold;
                    font-size: 11px;
                    line-height: 15px;
                    font-family: Gordita;
                    @include media-breakpoint-up(md) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                    #fleche {
                        position: relative;
                        top: 0px;
                    }
                }
            }
        }
    }
    .column {
        display: flex;
        flex-direction: column;
        margin: 0 0;
        width: 100%;
        @include media-breakpoint-up(md) {
            width: 50%;
            margin: 0 1vw;
        }
        @include media-breakpoint-up(lg) {
            width: 33%;
        }
    }
    .conteneur-flex-1-colonnes {
        display: flex;
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
    .conteneur-flex-2-colonnes {
        display: none;
        @include media-breakpoint-up(md) {
            display: flex;
        }
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    .conteneur-flex-3-colonnes {
        display: none;
        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }
    #btn-plusgagnants {
         :hover {
            cursor: pointer;
        }
    }
}

.promoVide {
    min-height: 13vw;
    padding: 5vw 0 0 5vw;
    p {
        text-align: center;
        font-family: 'gordita';
        font-size: (12rem /16);
        color: #1f1f1f;
        @include media-breakpoint-up(md) {
            font-size: (20rem /16);
        }
        @include media-breakpoint-up(lg) {
            font-size: (30rem /16);
        }
    }
}

.card-columns {
    column-count: 1;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    width: 100%;
    @include media-breakpoint-up(lg) {
        column-count: 2;
        -webkit-column-count: 2;
        -moz-column-count: 2;
    }
    .card {
        margin-bottom: 1rem;
        @include media-breakpoint-up(lg) {
            margin-bottom: 2rem;
        }
    }
}

//Fonctionnel commun au cards promos
// surcharge de _08-cards
.conteneur-card-promo {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    //            border-radius: 95px;
    opacity: 1;
    padding-top: (12rem / 16);
    @include media-breakpoint-up(md) {
        padding-top: (14rem / 16);
    }
    @include media-breakpoint-up(lg) {
        margin-left: 2vw;
        padding-top: (32rem / 16);
    }
    .card {
        border: none;
        box-shadow: 0px 0px 25px #00000029;
        .card-body {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 3rem;
        }
    }
    /*Surcharge de média bouton */
    .media-bouton.btn-text {
        .btn.btn-outline-ternary.btn-ombrage {
            box-shadow: none;
            &:hover,
            &:focus {
                box-shadow: none;
            }
        }
    }
    .card-body>.btnContainer>.btn[aria-expanded="true"] {
        display: none;
    }
    .card {
        border-radius: 15px;
        @include media-breakpoint-up(md) {
            border-radius: 45px;
        }
        @include media-breakpoint-up(lg) {
            border-radius: 70px;
        }
        img {
            width: 100%;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            @include media-breakpoint-up(md) {
                border-top-left-radius: 45px;
                border-top-right-radius: 45px;
            }
            @include media-breakpoint-up(lg) {
                border-top-left-radius: 70px;
                border-top-right-radius: 70px;
            }
        }
        .etiquette {
            position: absolute;
            left: 3vw;
            background-color: #ffffff;
            border-radius: (15rem / 16);
            padding: 3px 30px 3px 30px;
            font-family: Gordita;
            font-weight: bold;
            font-size: (12rem / 16);
            height: (24rem / 16);
            top: (-12rem / 16);
            box-shadow: 0px 0px 20px #00000029;
            @include media-breakpoint-up(md) {
                font-size: (16rem / 16);
                height: (32rem / 16);
                top: (-16rem / 16);
                padding: 3px 35px 3px 35px;
            }
            @include media-breakpoint-up(lg) {
                font-size: (20rem / 16);
                height: (36rem / 16);
                top: (-18rem / 16);
                padding: 3px 45px 3px 45px;
            }
        }
    }
    .card-img-top {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    .card-title {
        font-family: 'Gordita';
        font-weight: bold;
        font-size: 20px;
        color: #000000;
        @include media-breakpoint-up(md) {
            font-weight: bold;
            font-size: 19px;
        }
        @include media-breakpoint-up(lg) {
            font-weight: bold;
            font-size: 26px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 30px;
        }
    }
    .card-subtitle {
        font-family: 'Gordita';
        font-weight: bold;
        font-size: (15rem / 16);
        @include media-breakpoint-up(md) {
            font-size: (25rem / 16);
        }
        @include media-breakpoint-up(lg) {
            font-size: (25rem / 16);
        }
    }
    .card-texteDate {
        font-family: 'Gordita';
        font-weight: bold;
        font-size: (11rem / 16);
        @include media-breakpoint-up(md) {
            font-size: (20rem / 16);
        }
        @include media-breakpoint-up(lg) {
            font-size: (20rem / 16);
        }
    }
    .rich-text,
    .rich-text p {
        font-family: 'Roboto';
        font-weight: normal;
        font-size: (13rem / 16);
        max-width: 80vw;
        @include media-breakpoint-up(md) {
            font-size: (20rem / 16);
            max-width: 45vw;
        }
        @include media-breakpoint-up(lg) {
            font-size: (20rem / 16);
        }
    }
    h4,
    .h4 {
        text-transform: none;
    }
    div.card {
        /*color: #000; */
        text-decoration: none;
    }
    p {
        font-family: Roboto;
        font-size: (13rem /16);
        color: #1f1f1f;
        @include media-breakpoint-up(md) {
            font-size: (14rem /16);
        }
        @include media-breakpoint-up(lg) {
            font-size: (18rem /16);
        }
    }
}
@charset "utf-8";

#mainWrapper {
	.dropdown-item {
		display: inline-block;
		color: #000000;
		font-size: (16rem / 16);
		font-stretch: normal;
		letter-spacing: (0.6rem / 16);
		text-align: left;
		color: #000000;
		@include useFont(Roboto, $weight: 600, $style: normal);
		
		&:active, &.active {
			background-color:#c9cfd5;
		}
		
	}
	
	.dropdown {
		position: relative;
	}
	.show {
		.dropdown-toggle {
			&:after {
				transform: rotate(225deg);
				top: 40%;
			}
		}
	}
	
	.dropdown-menu {
		border-radius: (10rem / 16);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border: solid (3rem / 16) #000000;
		border-top: 0;
		height: auto;
		margin-top: (-8rem / 16);
		min-height: (640rem / 16);
	    width: 100%;
	    position: absolute;
	    z-index: 3;
	    cursor: pointer;
	}
	
	.dropdown-divider {
	    margin-right: (8rem / 16);
	    margin-top: 0;
	}
	
	.dropdown-toggle {	
		&:before {
			content: attr(data-caption);
		}
		
		&:after {
			content: "";
			position: absolute;
			display: block;
			right: (16rem / 16);
			top: 25%;
			width: (16rem / 16);
			height: (16rem / 16);
			border-bottom: (3rem / 16) solid #000000;
			border-right: (3rem / 16) solid #000000;
			border-top: none;
			border-left: none;
			transform: rotate(45deg);
	  		margin-left: 0;
			-webkit-transform: rotate(45deg);
		    -o-transform: rotate(45deg);
		    -ms-transform: rotate(45deg);
		    -moz-transform: rotate(45deg);
		    transform: rotate(45deg);
		    -webkit-transition: -webkit-transform 0.3s, top 0.3s;
		    -moz-transition: -moz-transform 0.3s, top 0.3s;
		    -ms-transition: -ms-transform 0.3s, top 0.3s;
		    -o-transition: -o-transform 0.3s, top 0.3s;
		    transition: transform 0.3s, top 0.3s;
		}
	
		&:focus{
			outline: none;
		}
	}
}
@charset "utf-8";
.resultat-sommaires {
    .contenantResultat {
        .lqConteneurGeneral {
            &.ultime {
                justify-content: start;
                a.lienResultatsComplets {
                    margin-bottom: auto;
                    margin-top: auto;
                }
            }
        }
    }
}